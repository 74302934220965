import React from 'react';
import { Grid } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import css from './View.module.css';
import { Button } from 'app/components/Button';
import { ButtonDelay } from 'app/components/ButtonDelay';
import { useTranslation } from 'react-i18next';
const PartialSaveContainer = React.lazy(() => import('app/containers/PartialSaveContainer'));

export const View = (props) => {
  const {
    agreement,
    children,
    ctaSubmitButton,
    fixedSubmitButton,
    centerSubmitButton,
    forwardEnabled,
    handleBackButton,
    hideBackButton,
    hideForwardButton,
    hidePartialButton,
    invalid,
    sectionId,
    shouldSubmit,
    submitAction,
    submitText,
    submitting,
    values,
  } = props;
  const { t }                     = useTranslation();
  const nextButtonText            = t('global:button.next', { defaultValue: 'Next' });
  const sectionSubmitButtonText   = submitText && t(`section.${sectionId}.button.submit`, { defaultValue: submitText });
  const submitButtonText          = shouldSubmit && submitText ? sectionSubmitButtonText : nextButtonText;
  const showBackButton            = !hideBackButton;
  const showForwardButton         = !hideForwardButton;
  const centerSubmitButtonWrapper = centerSubmitButton ? css.centerButton : '';
  const forwardClasses = classNames(css.forwardButton, {
    disabled: submitting || (forwardEnabled !== undefined && !forwardEnabled),
  });

  const defaultSubmitButtonProps = {
    className: forwardClasses,
    size     : 'small',
  };

  const ctaSubmitButtonProps = {
    classes: { root: 'roundedButton', contained: 'primary' },
    size   : 'large',
  };

  const fixedSubmitButtonProps = {
    classes: { root: 'mobileFixed', contained: 'primary' },
    size   : 'large',
  };

  const submitButtonProps = () => {
    if (fixedSubmitButton) {
      return fixedSubmitButtonProps;
    } else if (ctaSubmitButton) {
      return ctaSubmitButtonProps;
    } else {
      return defaultSubmitButtonProps;
    }
  };

  return (
    <div>
      {children}
      {agreement}
      {(showBackButton || showForwardButton) && (
        <div className={css.navWrapper}>
          <div className={css.pageNav}>
            <Grid container className={css.navContainer}>
              {!hidePartialButton && (
                <div className={`${css.navButton} ${css.saveLink}`}>
                  <PartialSaveContainer values={values} syncErrors={props.syncErrors} />
                </div>
              )}
              {showBackButton && (
                <div className={css.navButton}>
                  <Button
                    disableRipple
                    size      = "small"
                    className = {css.backButton}
                    onClick   = {handleBackButton}
                  >
                    <ChevronLeft /> {t('global:button.back', { defaultValue: 'Back' })}
                  </Button>
                </div>
              )}
              {showForwardButton && submitAction ? (
                <div className={`${css.navButton} ${centerSubmitButtonWrapper}`}>
                  <ButtonDelay
                    loading     = {submitting}
                    buttonProps = {{
                      color    : 'primary',
                      disabled : submitting || invalid,
                      type     : 'submit',
                      variant  : 'contained',
                      ...submitButtonProps(),
                    }}
                  >
                    {submitButtonText}
                  </ButtonDelay>
                </div>
              ) : (
                <div className={css.navButton}>
                  <Button
                    variant   = "contained"
                    size      = "small"
                    color     = "primary"
                    className = {forwardClasses}
                    type      = "submit"
                    disabled  = {submitting || invalid}
                  >
                    {nextButtonText}
                  </Button>
                </div>
              )}
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};
