import { servicingPurchaseQuestionsByName } from 'app/models/questions/servicing-purchase';
import { Confirmation } from 'app/components/Confirmation';
import { LastStep } from 'app/components/LastStep';
import * as FormActions from 'app/actions/form/actions';
import { Section } from 'app/models/types';
import { FormParagraphs } from 'app/models/paragraphs';
import { FieldNames } from 'app/models/fields/names';
import { isPurchasing, isPrequal, hasCoBorrower, schedulerEnabled } from 'app/models/fields/conditionals';
import { getLongFormDeclarationQuestions } from 'app/models/questions/declarations';
import { BorrowerType } from 'app/models/options/enums';

export const getServicingPurchaseSectionById = (id: number): Section => {
  return SERVICING_PURCHASE_SECTIONS.find((section) => section.id === id) as Section;
};

const SERVICING_PURCHASE_SECTIONS: Section[] = [
  {
    id       : 101,
    questions: servicingPurchaseQuestionsByName([
      FieldNames.purpose,
    ]),
    hideBackButton: true,
  },
  {
    id       : 102,
    title    : 'Tell us about your new purchase',
    questions: servicingPurchaseQuestionsByName([
      FieldNames.loanAmount,
      FieldNames.propertyType,
      FieldNames.propertyUsage,
      FieldNames.propertyZip,
      FieldNames.expectedClosingDate,
    ]),
    showIf: [isPurchasing],
  },
  {
    id       : 103,
    title    : 'Tell us about the home you\'re looking for',
    questions: servicingPurchaseQuestionsByName([
      FieldNames.loanAmount,
      FieldNames.propertyType,
      FieldNames.propertyUsage,
      FieldNames.propertyZip,
    ]),
    showIf: [isPrequal],
  },
  {
    id       : 104,
    questions: servicingPurchaseQuestionsByName([
      FieldNames.homeSoldYN,
      FieldNames.creditScore,
      FieldNames.escrowUsage,
    ]),
    submitAction: FormActions.ajaxGetServiceBookRates,
  },
  // Servicing Summary Component is shown to the user here
  {
    id       : 105,
    title    : 'Account Information',
    paragraph: FormParagraphs.servicingVerifyAccount,
    questions: servicingPurchaseQuestionsByName([
      FieldNames.accountSummary,
    ]),
  },
  {
    id             : 106,
    title          : 'Tell us about your assets',
    paragraph      : FormParagraphs.servicingAssets,
    paragraphBorder: true,
    subtitle       : 'Asset accounts on record',
    questions: servicingPurchaseQuestionsByName([
      FieldNames.assets,
    ]),
  },
  {
    id           : 107,
    title        : 'Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.PRIMARY),
    submitAction : FormActions.handleServiceBookSubmit,
    submitIf     : [(values) => !hasCoBorrower(values)],
    submitText   : 'Submit',
  },
  {
    id           : 108,
    title        : 'Co-Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.COBORROWER),
    submitAction : FormActions.handleServiceBookSubmit,
    showIf       : [hasCoBorrower],
    submitText   : 'Submit',
  },
  {
    id             : 109,
    ifNoQuestions  : LastStep,
    clearWrapper   : true,
    hideProgressBar: true,
    // Conditional is redundant since the component is skipping the section itself,
    // but it is being used here for clarity.
    showIf         : [schedulerEnabled],
  },
  {
    id             : 110,
    ifNoQuestions  : Confirmation,
    hideProgressBar: true,
  },
];
