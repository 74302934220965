import { LiabilityAccount } from 'app/api/credit-report';
import { Endpoints } from 'app/api/helpers';
import { mapBorrowers, mapSubjectProperty } from 'app/api/loan-application/mappings';
import { AutoValuationModel, ExpressFormRequest, HomeEquityProperties, UniversalPrefillResponse } from 'app/api/loan-application/types';
import { FormName } from 'app/models/options/enums';
import { ClosingCostType } from 'app/api/loan-estimate';
import { LoanOfficerDetail } from 'app/api/loan-officer';
import { client } from 'app/api/_client';
import { isCollectOtherHomeLiabilties, isOwningOtherHomes, isPrimaryHome } from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';
import { EscrowUsage, LiabilityUsage, PropertyUsage } from 'app/models/options/enums';
import { getSlug } from 'app/util/headers';
import { logger } from 'app/util/logger';
import { toNumber } from 'app/util/parsers';

/**
 * This method maps data on the form to the shape of the request for the
 * express endpoint to accept.
 *
 * @param formData
 * @param {LoanOfficerDetail} loanOfficer
 * @param {string} borrowerCustomerId,
 * @param {string} coBorrowerCustomerId
 * @param {number} avmReportId
 * @returns {ExpressFormRequest}
 */
export const mapExpressFormRequest = (
  formData,
  loanOfficer: LoanOfficerDetail,
  borrowerCustomerId: string,
  coBorrowerCustomerId: string,
  avmReportId: number): ExpressFormRequest => {

  const properties: HomeEquityProperties[] = [];
  const realEstateLiabilities: LiabilityAccount[] = formData[FieldNames.realEstateLiabilities] || [];

  // add primary property because we will always have one
  properties.push({
    isCollateral    : isPrimaryHome(formData),
    isPrimary       : true,
    propertyUsage   : PropertyUsage.PRIMARY,
    propertyType    : isPrimaryHome(formData) ? formData[FieldNames.propertyType]  : formData[FieldNames.livingPropertyType],
    annualTaxes     : isPrimaryHome(formData) ? toNumber(formData[FieldNames.yearTaxes])     : toNumber(formData[FieldNames.livingYearTaxes]),
    annualInsurance : isPrimaryHome(formData) ? toNumber(formData[FieldNames.yearInsure])    : toNumber(formData[FieldNames.livingYearInsure]),
    annualHOA       : isPrimaryHome(formData) ? toNumber(formData[FieldNames.yearAssociate]) : toNumber(formData[FieldNames.livingYearAssociate]),
    mortgages       : realEstateLiabilities
      .filter((liability: LiabilityAccount) => liability.liabilityUsage === LiabilityUsage.PRIMARY_HOME),
  });

  // if main property isn't primary we need to add secondary house as collateral
  if (!isPrimaryHome(formData)) {
    properties.push({
      isCollateral    : true,
      isPrimary       : false,
      propertyUsage   : formData[FieldNames.propertyUsage],
      propertyType    : formData[FieldNames.propertyType],
      annualTaxes     : toNumber(formData[FieldNames.yearTaxes]),
      annualInsurance : toNumber(formData[FieldNames.yearInsure]),
      annualHOA       : toNumber(formData[FieldNames.yearAssociate]),
      mortgages       : realEstateLiabilities
        .filter((liability: LiabilityAccount) => liability.liabilityUsage === LiabilityUsage.COLLATERAL_HOME),
    });
  }

  // add other homes on liabilities
  realEstateLiabilities
    .filter((liability: LiabilityAccount) => liability.liabilityUsage === LiabilityUsage.OTHER_HOME)
    .forEach((liability: LiabilityAccount) => {
      properties.push({
        isCollateral    : false,
        isPrimary       : false,
        annualTaxes     : toNumber(formData[FieldNames.liabilityCombinedYearTaxes]),
        annualInsurance : toNumber(formData[FieldNames.liabilityCombinedYearInsure]),
        annualHOA       : toNumber(formData[FieldNames.liabilityCombinedYearAssociate]),
        mortgages       : [{
          liabilityName: liability.liabilityName,
          liabilityAmount: liability.liabilityAmount,
          liabilityBalance: liability.liabilityBalance,
          liabilityUsage: LiabilityUsage.OTHER_HOME,
        }],
      });
    });

  const totalNumberOfProperties = (isPrimaryHome(formData) ? 1 : 2) + toNumber(formData[FieldNames.liabilityCombinedNumberOtherHomes]);

  // if they answered that they own other homes, add one final property
  if ( (isOwningOtherHomes(formData) || isCollectOtherHomeLiabilties(formData)) && (totalNumberOfProperties > properties.length) ) {
    properties.push({
      isCollateral    : false,
      isPrimary       : false,
      annualTaxes     : toNumber(formData[FieldNames.liabilityCombinedYearTaxes]),
      annualInsurance : toNumber(formData[FieldNames.liabilityCombinedYearInsure]),
      annualHOA       : toNumber(formData[FieldNames.liabilityCombinedYearAssociate]),
      mortgages       : [],
    });
  }

  return {
    avm                        : avmReportId ? { ...mapAutoValuationModel(formData), avmReportId } : undefined,
    borrowerCustomerId,
    borrowers                  : mapBorrowers(formData),
    closingCostType            : ClosingCostType.EXPRESS,
    coBorrowerCustomerId,
    coBorrowerSsn              : formData[FieldNames.coBorrowerSsn],
    creditReportId             : formData[FieldNames.creditReportId],
    formName                   : FormName.HOME_EQUITY_EXPRESS,
    hasEscrow                  : formData[FieldNames.livingEscrowUsage] === EscrowUsage.YES,
    homeEquityLoanPurpose      : formData[FieldNames.homeEquityLoanPurpose],
    homeEquityLoanPurposeOther : formData[FieldNames.homeEquityLoanPurposeOther],
    loanAmount                 : formData[FieldNames.loanAmount],
    loanOfficerId              : loanOfficer.id,
    loanTerm                   : formData[FieldNames.loanTerm],
    loanType                   : formData[FieldNames.loanType],
    monthlyPayment             : formData[FieldNames.monthlyPayment],
    pointFee                   : formData[FieldNames.fee],
    properties,
    rate                       : formData[FieldNames.rate],
    realEstateLiabilities,
    ssn                        : formData[FieldNames.ssn],
    subjectProperty            : mapSubjectProperty(formData),
    totalNumberOfProperties,
  };
};

const mapAutoValuationModel = (formData): AutoValuationModel | undefined => ({
  avmHomeValue: formData[FieldNames.avmHomeValue],
  avmHomeValueHighLimit: formData[FieldNames.avmHomeValueHighLimit],
  avmHomeValueLowLimit: formData[FieldNames.avmHomeValueLowLimit],
});

/**
 * This API call emails the loan estimate and underwriting PDFs to the loan officer
 *
 * @param {ExpressFormRequest} request
 * @returns {any}
 */
export const postHomeEquityExpress = (request: ExpressFormRequest): Promise<any> => {
  const slug = getSlug();
  return client
    .post<any>(Endpoints.EXPRESS_FORM, { ...request, slug })
    .catch((error) => {
      logger.error('ERROR: Failed to send express PDFs');
      throw error;
    });
};

/**
 * This API call gets the prefilled data for a home equity turbo application
 *
 * @param {string} hash
 * @returns {Promise<any>}
 */
export const getHomeEquityTurboPrefill = (hash: string): Promise<UniversalPrefillResponse> => {
  const slug = getSlug();
  const endpoint = `${Endpoints.LOANS}/${hash}`;
  return client
    .get<UniversalPrefillResponse>(endpoint, { params: { slug } })
    .then((response) => response.data)
    .catch((error) => {
      logger.error('ERROR: Failed to get home equity turbo prefill data');
      throw error;
    });
};
