import React from 'react';
import { Grid } from '@material-ui/core';
import { ToggleEditableFields } from 'app/components/ToggleEditableFields';
import { FieldNames } from 'app/models/fields/names';
import { servicingRefinanceQuestionsByName } from 'app/models/questions/servicing-refinance';

export const VerifyAddress = ({ ...props }) => {
  return (
    <Grid container classes={{ container: 'verifyContainer' }}>
      <Grid item xs={12}>
        <ToggleEditableFields
          id         = {101}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {servicingRefinanceQuestionsByName([
            FieldNames.propertyStreet,
            FieldNames.propertyStreet2,
            FieldNames.propertyCity,
            FieldNames.propertyState,
            FieldNames.propertyZip,
            FieldNames.propertyUsage,
            FieldNames.propertyType,
          ])}
        />
      </Grid>
    </Grid>
  );
};
