import React from 'react';
import {
  Button,
  colors,
  createStyles,
  Grid,
  Hidden,
  Paper,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Email from '@material-ui/icons/Email';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Phone from '@material-ui/icons/Phone';
import { connect } from 'react-redux';
import { LoanOfficerDetail } from 'app/api/loan-officer';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { getLoanOfficer, getLoanOfficerPhone } from 'app/reducers/loan-officer';
import { compose } from 'redux';

const styles = (theme) => createStyles({
  mobileContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
      lineHeight: '55px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
  itemButton: {
    minHeight: 0,
    minWidth: 0,
    justifyContent: 'unset',
    position: 'relative',
    color: colors.grey[800],
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  mobileItem: {
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
    },
  },
  noBreak: {
    display: 'inline-block',
  },
  officerTitle: {
    [theme.breakpoints.down('sm')]: {
      lineHeight: '25px',
      padding: '1rem 24px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },
  },
});

interface Props extends WithStyles<typeof styles>, WithTranslation {
  loanOfficer      : LoanOfficerDetail;
  loanOfficerPhone : string;
  removePadding    : boolean;
}

const LoanOfficerDetailsComponent: any = ({ classes, loanOfficer, loanOfficerPhone, removePadding, t }: Props) => {
  const { firstName = '', middleName = '', lastName = '' } = loanOfficer;
  /* If removePadding is set to true, remove the space between this component
    and the Section component on mobile */
  const removeSpacing = removePadding ? 'removeSpacing' : '';
  return (
    <Paper classes={{ root: `formWrapper sectionFooter ${removeSpacing}` }}>
      <Typography
        variant   = "h6"
        classes   = {{ h6: 'sectionFooterTitle' }}
        className = {`${classes.mobileContainer} ${classes.officerTitle}`}
      >
        <Trans
          i18nKey = "loanOfficerDetails.title"
          values  = {{
            firstName,
            middleName,
            lastName,
          }}
        >
          {'Your loan officer is '}
          <span className={classes.noBreak}>
            {'{{ firstName }} {{ middleName }} {{ lastName }}'}
          </span>
        </Trans>
      </Typography>
      <Grid container classes={{ container: 'detailContainer' }}>
        <Grid item xs={12} md={3} classes={{ item: 'sectionFooterItem' }}>
          <Button
            disableRipple
            fullWidth
            classes   = {{ fullWidth: 'mobileOnly' }}
            className = {`${classes.mobileContainer} ${classes.itemButton}`}
            href      = {`tel:${loanOfficer.phone}`}
          >
            <Hidden smDown>
              <Phone color="secondary" classes={{ root: 'start' }} />
            </Hidden>
            {t('loanOfficerDetails.phone', { defaultValue: '{{ loanOfficerPhone, phone }}', loanOfficerPhone })}
            <Hidden mdUp>
              <KeyboardArrowRight classes={{ root: 'mobileFullWidthArrow' }} />
            </Hidden>
          </Button>
        </Grid>
        <Grid item xs={12} md={6} classes={{ item: 'sectionFooterItem' }}>
          <Button
            disableRipple
            fullWidth
            classes   = {{ fullWidth: 'mobileOnly' }}
            className = {`${classes.mobileContainer} ${classes.itemButton}`}
            href      = {`mailto:${loanOfficer.email}`}
          >
            <Hidden smDown>
              <Email color="secondary" classes={{ root: 'start' }} />
            </Hidden>
            {t('loanOfficerDetails.email', {
              defaultValue: '{{ email }}',
              email: loanOfficer.email,
            })}
            <Hidden mdUp>
              <KeyboardArrowRight classes={{ root: 'mobileFullWidthArrow' }} />
            </Hidden>
          </Button>
        </Grid>
        <Grid item xs={12} md={3} classes={{ item: 'sectionFooterItem' }}>
          <Typography
            variant   = "body2"
            className = {`${classes.mobileContainer} ${classes.mobileItem}`}
          >
            {t('loanOfficerDetails.nmls', {
              defaultValue: 'NMLS # {{ nmls }}',
              nmls        : loanOfficer.nmls,
            })}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state: RootState) => ({
  loanOfficer      : getLoanOfficer(state),
  loanOfficerPhone : getLoanOfficerPhone(state),
});

export const LoanOfficerDetails = compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(LoanOfficerDetailsComponent);
