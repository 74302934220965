import React from 'react';
import { Fragment as F } from 'react';

// Paragraph default values used in sections and questions
// ------------------

export const FormParagraphs = {
  empty:
    <F></F>,
  additionalProperties:
    <F>If you have a co-borrower, please include additional properties owned by them as well.</F>,
  autoPrequalRenewVerifyAccount:
    <F>We've made it easy for you to update your information below in order to obtain a new pre-qualification letter. Simply verify that everything is still correct or make the appropriate edits.</F>,
  autoPrequalRenewAuthorizeCredit:
    <F>By clicking 'Next' below, you will be authorizing another soft credit pull for your pre-qualification. However, this will <strong>NOT</strong> affect your credit score in any way. It will only be used to verify your current score and identify any changes to your liabilities.</F>,
  autoPrequalRenewFinancialHistory:
    <F>We've saved your answers from your first application. Review everything below to make sure nothing has changed.</F>,
  autoPrequalRenewRealEstateLiabilities:
    <F>We've identified these liabilities associated with properties that you and/or your co-borrower may own. Please indicate whether you plan to sell or keep these homes.</F>,
  availableFunds:
    <F>List the amounts that you have in your various accounts that you will use for your down payment and closing costs.</F>,
  estimatedAmounts:
    <F>If you don't know the expected amounts for these items, we will estimate them for you.</F>,
  homeEquityBorrow:
    <F>Answer these short questions and we'll let you know how much you might borrow using a home equity loan.</F>,
  realEstateLiabilities:
    <F>We've identified these liabilities associated with properties that you and/or your co-borrower may own. Please indicate whether you plan to sell or keep these homes.</F>,
  scheduleAppointment:
    <F>If you would like a loan officer to contact you, please provide a date and time that works best for you.</F>,
  servicingVerifyAccount:
    <F>Easily verify or edit your account information to complete the application process.</F>,
  servicingAssets:
    <F>Easily update the balances of your current assets, add new accounts, or delete old ones.</F>,
  autoPurchasePlan:
    <F>Answer a few short questions and we’ll provide you with options for an auto loan.</F>,
  automaticVoie:
    <F>This process will require you to identify your accounts at financial institutions and provide credentials to access the details.</F>,
  creditCardVerbiage:
    <F>To qualify for a Visa credit card, you may need to qualify for certain criteria including a minimum age of 18, steady income, US mailing address, minimum credit score and other credit requirements.</F>,
  reverseMortgageVerbiage:
    <F>Answer these short questions and we will provide you with an estimate of your Reverse Mortgage benefits.</F>,
  commercialAvailableFunds:
    <F>List the amounts in various accounts that will be used for down payment and closing costs</F>,
  acrisureVerbiage:
    <F>Quotes are provided through QuickInsured, an independent insurance agency serving all 50 states and representing many of the nation's top carriers.</F>,
  unitasVerbiage:
    <F>Quotes are provided through Unitas.</F>,
};
