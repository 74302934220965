import { VALIDATORS } from 'app/util/validators';
import { I18NFormat } from 'app/i18n/format';
import { ActionCreator } from 'redux';
import {
  LoanOfficerConditions,
  TransferToFormConditions,
} from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';
import { FormOption } from 'app/models/options/options';
import { SUMMARY_SECTION } from 'app/models/sections/constants';
import { ReferralLimit } from 'app/reducers/app-config';

/**
 * Sections are used to display each 'page' on the forms.
 * Sections contain questions, conditions, and additional
 * logic to be applied on each page.
 *
 * @export
 * @interface Section
 */
export interface Section {
  /**
   * The id for the section, this is currently used to determine order as well
   */
  id: number;
  /**
   * Title to be displayed on the section. If undefined, no title will be displayed
   */
  title?: string;
  /**
   * An array of questions to be used for this section
   */
  questions?: Question[];
  /**
   * Set of field names that relate to questions that should be excluded
   */
  excludedQuestions?: string[];
  /**
   * Set of field names that relate to questions that should be excluded because they were answered an another form
   */
  answeredQuestions?: string[];
  /**
   * Set of option values to be excluded as potential answers in radio buttons and drop downs
   */
  excludedOptions?: string[];
  /**
   * Toggles the if the back button is hidden on the View component inside the section
   */
  hideBackButton?: boolean | ((values) => boolean);
  /**
   * Toggles the if the forward button is hidden on the View component inside the section
   */
  hideForwardButton?: boolean;
  /**
   * Component to be rendered if all of the questions in this section fail their conditionals.
   *
   * *Example* `ifNoQuestions: HelocMinLoan`
   */
  ifNoQuestions?:
  | ((props) => JSX.Element)
  | (React.ComponentClass<any> & {
    WrappedComponent: React.ComponentType<any>;
  })
  | React.ComponentType<any>;

  /**
   * These are client specific referral limits for each form.  Things like FICO score limits, DTI ratios, etc.
   */
  referralLimits?: ReferralLimit[];
  /**
   * An array of conditions to test whether or not this section should be displayed. If any one of the conditions
   * pass, then the section will be displayed. If none of the conditionals pass, the router will navigate to the
   * next section
   */
  showIf?: ConditionalTest[];
  /**
   * Text to be displayed on the submit button for this section. (Must have a submitAction present)
   * If no text is provided, the default of 'Next' will be used.
   */
  submitText?: string;
  /**
   * Action to be fired when the user presses submit on this section. If this property
   * isn't specified, the default "getNextSection" action is fired
   *
   * @example
   * FormActions.handleLongFormSubmit
   */
  submitAction?: ActionCreator<any>;
  /**
   * An array of conditions to determine which submitAction should be used. If any of the conditions
   * pass, the submitAction will be used. If none of the conditions pass, the default "getNextSection" action is fired
   */
  submitIf?: ((formValues) => boolean)[];
  /**
   * Centers the submit button
   */
  centerSubmitButton?: boolean;
  /**
   * Styles the submit button to use unique call to action styling
   */
  ctaSubmitButton?: boolean;
  /**
   * Styles the submit button to be fixed on mobile
   */
  fixedSubmitButton?: boolean;
  /**
   * Hides the progress bar at the top of the section
   */
  hideProgressBar?: boolean;
  /**
   * A paragraph to be rendered below the section title. This component
   * does not take in any props from the Section component.
   *
   * *Example* `paragraph: FormParagraphs.accountInformation`
   */
  paragraph?: JSX.Element;
  /**
   * A thin border line to be rendered below the paragraph as a visual divider
   */
  paragraphBorder?: boolean;
  /**
   * Smaller 'subtitle' text rendered below the paragraph on the section.
   */
  subtitle?: string;
  /**
   * When set to true, a class of 'clearWrapper' is added to the section wrapper,
   * removing the paper component and the white background from the section.
   * This is currently in the last section of some of the forms
   */
  clearWrapper?: boolean;
  /**
   * Passes an agreement component into the `<View />` component.
   *
   * *Example* `<AuthorizeCreditReport />`
   */
  agreement?: JSX.Element;
  /**
   * Holder for the conditions that refer to loan officer or transfer to a new form
   *
   */
  conditionalIf? : {
    /**
     * An array of conditionals to test. If any pass, the user will be redirected
     * to the loan officer landing stopgate. This check fires just before getting the
     * **next** section.
     *
     * *Example* `referToLoIf: [LoanOfficerCondition.hasBankrupt]`
     */
    referToLoIf?: LoanOfficerConditions[keyof LoanOfficerConditions][];
    /**
     * An array of conditionals to test. If any pass, the user will be redirected
     * to the new form. This check fires just before getting the
     * **next** section.
     *
     * *Example* `transferFormIf : [TransferToFormCondition.borrowerNotCitizenExpress],`
     */
    transferFormIf?: TransferToFormConditions[keyof TransferToFormConditions][]
  }

  /**
   * Toggles the rendering of the `<LoanOfficerDetails />` component
   * on the `<Section />`
   */
  showLoanOfficer?: boolean;
  /**
   * Toggles a component to show above the Section component
   */
  useTopSection?: JSX.Element;
  /**
   * Hides the "Save for Later" button for partial apps if set to true
   */
  hidePartialButton?: boolean;
  /**
   * Removes padding from section formWrapper
   */
  removePadding?: boolean;
}

/**
 * A question specifies a form field and the behavior we want and are grouped together by
 * Sections {@link {Section}}
 *
 * @export
 * @interface Question
 */
export interface Question {
  /**
   * The id number of the question, this should be a unique value
   */
  id: number;
  /**
   * The component to be rendered for this question. This component will be rendered
   * in the `<Question />` component with the `<Question />` components props passed
   * in as arguments
   *
   */
  component: React.ComponentType<any> | ((props) => JSX.Element);
  /**
   * The name of the question is used for identifying which property in the redux store
   * to update via ReduxForm.
   *
   * See: https://redux-form.com/8.1.0/docs/api/field.md/#-code-name-string-code-required-
   */
  name: FieldNames | string;
  /**
   * Sets the form field label. This is also used as the key in some HTML emails
   *
   * *Example* `label: 'First Name'`
   */
  label?: string;
  /**
   * Hides label on the UI for some components
   */
  hideLabel?: boolean;
  /**
   * Displays the unit of measurement passed in at the end of the field
   */
  endAdornment?: string;
  /**
   * Set to true this field will display the '?' popover on the question input.
   */
  fieldPopover?: boolean;
  /**
   * Text to be shown when a user clicks on the fieldPopover. When overriding
   * a shared question, if you do not want the popover to show this needs
   * to be set to null
   */
  popover?: string | null;
  /**
   * Allows the question to be optional, this will replace the 'required' validator
   * if it exists on the `component` with a 'noop' validator allowing the question
   * to be skipped
   */
  isOptional?: boolean;
  /**
   * Set of options to be listed with this question. Generally used in Radio Field and
   * Select type questions
   */
  options?   : FormOption<any>[];
  /**
   * Set of option values to be excluded as potential answers in radio buttons and drop downs
   */
  excludedOptions?: string[];
  /**
   * Title to be displayed on the question. If left undefined, no title will be displayed
   */
  title?: string;
  /**
   * When set to true, hides the title on the question. This is generally
   * reserved for when a question has a title in the first instance, then
   * the question is used in a second instance but without a title.
   */
  hideTitle?: boolean;
  /**
   * Disclaimer string of copy that is rendered below the question's title.
   * If left undefined, no question disclaimer will be displayed
   */
  disclaimer?: string;
  /**
   * A paragraph to be rendered below the question. This component
   * does not take in any props from the Question component.
   *
   * *Example* `paragraph: FormParagraphs.scheduleAppointment`
   */
  paragraph?: JSX.Element;
  /**
   * An array of conditions to test whether or not this question should be displayed.
   * * 1 or more conditions pass, question is shown
   * * 0 conditionals pass, question is hidden.
   *
   * *Note*: If all questions in a section are hidden, the component set as the `ifNoQuestions`
   * property on the {@link {Section}} component will be rendered in the `<Secton />` component.
   */
  showIf?: ConditionalTest[];
  /**
   * A custom validator object placed on the question. This applies a custom validator
   * to the question from the `VALIDATORS` object. This is generally used when we want to validate
   * using a value found in another question.
   *
   * @example
   * ```validator: { type: 'isGreaterThanField', param: FieldNames.loanAmount }```
   */
  validator?: {
    type : keyof typeof VALIDATORS;
    param: FieldNames | [FieldNames, FieldNames] | [number, number];
  };
  /**
   * A localization format to be used for displaying the value of the question.
   *
   * @example
   * ```displayFormat: 'currency.0'```
   * Will apply a 'currency.0' format to the question when in display mode
   *
   */
  displayFormat?: I18NFormat;
  /**
   * Utilized on the questions imported into the summary page, this modifies the appearance
   * of the question on summary page.
   * @TODO: Find out exactly what this is doing and see if it's still needed
   */
  isSummaryPage?: boolean;
  /**
   * Passes autocomplete value to input field. This is currently only needed to be passed in
   * on the Password component. All others use the `mapAutoCompleteName` helper
   */
  autoComplete?: string;
}

export interface DeclarationQuestion extends Question {
  /**
   * Set to true for toggle questions when a conditional is present. This value adds a class
   * to the toggleable questions
   */
  hasConditions?: boolean;
  /**
   * A set of conditions to show the question specific to the primary borrower
   */
  primaryShowIf?: ConditionalTest[];
  /**
   * A set of conditions to show the question specific to the co-borrower
   */
  coBorrowerShowIf?: ConditionalTest[];
  /**
   * A list of field names used in the change handler to update fields located
   * inside this question component
   */
  fieldNames?: FieldNames[];
  /**
   * When borderTitle is true, a class is removed from the question title
   * and classes are added to the declaration checkboxes.
   */
  borderTitle?: boolean;
}

export type ConditionalTest = ((formValues) => boolean);

/**
 * User defined type-guard for detecting if section is undefined or section.
 * See: https://www.typescriptlang.org/docs/handbook/advanced-types.html
 *
 * @param {(Section | undefined)} section
 * @returns {section is Section}
 */
export const isSection = (section: Section | undefined): section is Section => {
  return typeof section !== 'undefined';
};

/**
 * Steps are used to control the progress header on the top of the page
 *
 * @export
 * @interface Step
 */
export interface Step {
  /**
   * The index (order) of this step
   */
  index      : number;
  /**
   * A display name for the step.
   *
   * *Example* `name: "Display name goes here"`
   *
   * *would create* `Step 1 of 5: Display name goes here`
   */
  name?      : string;
  /**
   * An array containing the section numbers this section covers
   */
  sections   : (typeof SUMMARY_SECTION | number)[];
}

export interface MenuItems {
  title  : string;
  url    : string;
  content: string;
}

export type ProductType   = 'ARM' | 'FIXED';
export type ProductFamily = 'FHA' | 'CONVENTIONAL' | 'Home Equity' | 'HELOC' | 'PERSONAL_LOAN';
export interface RateInfo {
  loanType                   : string;
  productName                : string;
  productFamily              : ProductFamily;
  productType                : ProductType;
  rate                       : number;
  apr                        : number;
  term                       : number;
  /**
   * Monthly payment for Private Mortgage insurance.
   * This amount is already added to the principalAndInterestPayment,
   * to calculate principalAndInterestPayment without PMI, subtract
   * PMI from prinicpalAndInterestPayment
   *
   * @type {number}
   * @memberof RateInfo
   */
  pmiMonthlyPayment          : number;
  principalAndInterestPayment: number;
  quoteFees                  : number;
  applyUrl                   : string;
  downPayment?               : number;
  loanAmount?                : number;
}

export interface PrequalRateInfo extends RateInfo {
  prequalAmount    : number;
  prequalIssued    : Date;
  prequalExpiration: Date;
  downPayment      : number;
}
