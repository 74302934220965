import React from 'react';
import { Grid } from '@material-ui/core';
import { ToggleEditableFields } from 'app/components/ToggleEditableFields';
import { filterQuestions } from 'app/util/question-helpers';
import {
  COBORROWER_QUESTIONS,
  CONTACT_QUESTIONS,
  MARITAL_QUESTIONS,
  CURRENT_HOME_QUESTIONS,
  CURRENT_EMPLOYMENT_QUESTIONS,
  CURRENT_INCOME_QUESTIONS,
} from 'app/models/questions/shared-servicing';

export const ServicingAccountSummary = ({ ...props }) => {
  const filteredCoBorrowerQuestions = filterQuestions(COBORROWER_QUESTIONS, props.values);
  return (
    <Grid container classes={{ container: 'verifyContainer' }}>
      <Grid item xs={12}>
        <ToggleEditableFields
          id         = {101}
          subtitle1 = {'Contact Information'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {CONTACT_QUESTIONS}
        />
        <ToggleEditableFields
          id         = {102}
          subtitle1 = {'Marital & Dependent Status'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {MARITAL_QUESTIONS}
        />
        <ToggleEditableFields
          id         = {103}
          subtitle1 = {'Co-Borrower Information'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {filteredCoBorrowerQuestions}
        />
        <ToggleEditableFields
          id         = {104}
          subtitle1 = {'Current Home\'s Annual Costs'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {CURRENT_HOME_QUESTIONS}
        />
        <ToggleEditableFields
          id         = {105}
          subtitle1 = {'Current Employment'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {CURRENT_EMPLOYMENT_QUESTIONS}
        />
        <ToggleEditableFields
          id         = {106}
          subtitle1 = {'Current Income'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {CURRENT_INCOME_QUESTIONS}
        />
      </Grid>
    </Grid>
  );
};
