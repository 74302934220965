import {
  AutoLoan,
  CommercialLoan,
  CreditBoosterLoan,
  CreditCard,
  FinancialStatement,
  LiabilityStatus,
  LiabilityType,
  OtherVehicleLoan,
  PersonalLoan,
  ReverseMortgage,
  SmallBusiness,
} from '@lenderful/domain';
import { ClosingCostType } from 'app/api/loan-estimate';
import { FieldNames } from 'app/models/fields/names';
import {
  PropertyUsage,
  PropertyType,
  CreditScore,
  TitleHeld,
  AccountType,
  PurchaseTimeline,
  DeclarationType,
  LivingSituation,
  MilitaryExperienceType,
  EmploymentOwnershipShare,
  BorrowerType,
  FormName,
} from 'app/models/options/enums';
import { ProductFamily } from 'app/models/types';
import { LiabilityAccount } from '../credit-report';
import { ApplicationSource, EmploymentType, IncomeSource, HomeEquityLoanPurpose } from '@lenderful/domain';
import { ReverseMortgagePropertyTitleHeld, UniversalLoanSource, SubjectProperty } from '@lenderful/domain';
import { AuditMetadata } from '../../util/actions';

export type Binary = 1 | 0;

export interface LongFormRequest {
  appointment?    : Appointment;
  auditMetadata?  : object;
  client          : string;
  client_meta     : { [key: string]: any };
  creditReportId? : number;
  formName        : FormName;
  /**
   * @depreciated Question no longer asked
   * */
  how_did_you_hear?     : string;
  LOAN_APPLICATION      : LoanApplication;
  short_application_id? : number;
  slug                  : string;
  source                : ApplicationSource;
}

export interface LongFormResponse {
  loanAppId: number;
}

export interface LoanApplication {
  BORROWER                     : Partial<Borrower>[];
  branch_employee?             : string;
  branch_number?               : string;
  buying_stage?                : string;
  CONSTRUCTION                 : Partial<Construction>
  coborrower_on_loan_flag      : Binary;
  coborrower_on_title_flag     : Binary;
  down_payment_amount          : number;
  expected_closing_date?       : Date;
  fee                          : any;
  loan_purpose                 : any;
  loan_term                    : any;
  loan_type                    : any;
  monthly_payment              : number;
  plan_sell_flag               : Binary;
  product_type                 : any;
  purchase_timeline?           : PurchaseTimeline;
  rate                         : any;
  refi_different_address_flag? : Binary;
  refi_purpose?                : any;
  refi_purpose_other?          : string;
  requested_loan_amount        : number;
  SUBJECT_PROPERTY             : Partial<SubjectProperty>;
  title_first_name             : string;
  title_last_name              : string;
  title_middle_name            : string;
  title_prefix                 : string | null;
  title_suffix                 : string | null;
  transfer_reason_code         : string | null;
}

export interface Borrower {
  age?                                : number;
  birth_date?                         : any;
  borrowerType?                       : BorrowerType;
  credit_score                        : CreditScore;
  credit_score_high?                  : any;
  credit_score_low?                   : any;
  CURRENT_ASSET                       : Partial<Asset>[];
  CURRENT_INCOME                      : Partial<Income>[];
  CURRENT_LIABILITY                   : Partial<Liability>[];
  REO_PROPERTY                        : Partial<ReoProperty>[];
  dec_alimony_childsupport_flag       : Binary;
  dec_bankruptcy_flag                 : Binary;
  dec_borrowed_downpayment_flag       : Binary;
  dec_citizenship_flag                : Binary;
  dec_comaker_endorser_flag           : Binary;
  dec_commercial_bankrupt_flag        : Binary;
  dec_commercial_credit_flag          : Binary;
  dec_commercial_debt_flag            : Binary;
  dec_commercial_legal_action_flag    : Binary;
  dec_commercial_tax_due_flag         : Binary;
  dec_conveyed_title_flag             : Binary;
  dec_family_relationship_flag        : Binary;
  dec_home_owner_occupy_flag          : Binary;
  dec_home_owner_three_years_flag     : Binary;
  dec_intent_to_occupy_flag           : Binary;
  dec_loan_obligation_flag            : Binary;
  dec_mortgage_in_progress_flag       : Binary;
  dec_other_lien_flag                 : Binary;
  dec_outstanding_judgements_flag     : Binary;
  dec_own_other_properties_flag       : Binary;
  dec_party_to_lawsuit_flag           : Binary;
  dec_presently_delinquent_flag       : Binary;
  dec_prior_property_title_type       : TitleHeld;
  dec_prior_property_usage_type       : PropertyType;
  dec_property_foreclosed_flag        : Binary;
  dec_resident_alien_flag             : Binary;
  dec_short_sale_flag                 : Binary;
  DECLARATION_EXPLANATION             : DeclarationExplanation[];
  dependent_count                     : number;
  dependents_age                      : string;
  email                               : string;
  EMPLOYMENT                          : Partial<Employment>[];
  ethnicity_hispanic_cuban_flag       : Binary;
  ethnicity_hispanic_flag             : Binary;
  ethnicity_hispanic_mexican_flag     : Binary;
  ethnicity_hispanic_other            : null;
  ethnicity_hispanic_other_flag       : Binary;
  ethnicity_hispanic_puerto_rican_flag: Binary;
  ethnicity_not_hispanic_flag         : Binary;
  ethnicity_not_provided_flag         : Binary;
  first_name                          : string;
  income_on_decline_flag              : Binary;
  last_name                           : string;
  marital_status                      : any;
  middle_name?                        : string;
  military_experience                 : MilitaryExperienceType;
  name_suffix?                        : string;
  phone                               : string;
  primary_borrower_flag               : Binary;
  race_asian_chinese_flag             : Binary;
  race_asian_filipino_flag            : Binary;
  race_asian_flag                     : Binary;
  race_asian_indian_flag              : Binary;
  race_asian_japanese_flag            : Binary;
  race_asian_korean_flag              : Binary;
  race_asian_other?                   : string;
  race_asian_other_flag               : Binary;
  race_asian_vietnamese_flag          : Binary;
  race_black_flag                     : Binary;
  race_islander_flag                  : Binary;
  race_islander_guamanian_flag        : Binary;
  race_islander_native_hawaiian_flag  : Binary;
  race_islander_other?                : string;
  race_islander_other_flag            : Binary;
  race_islander_samoan_flag           : Binary;
  race_native_flag                    : Binary;
  race_not_provided_flag              : Binary;
  race_white_flag                     : Binary;
  RESIDENCES                          : Residence[];
  sex_female_flag                     : Binary;
  sex_male_flag                       : Binary;
  sex_not_provided_flag               : Binary;
  ssn?                                : string;
  years_education?                    : number;
  /**
   * Vendor order ID for credit report
   *
   * @type {(number | string)}
   * @memberof LongBorrower
   */
  credit_report_reference?            : number | string;
}

export interface Construction {
  loan_type                           : string;
  land_cost?                          : number;
}

export interface DeclarationExplanation {
  declaration_type: DeclarationType;
  explanation?    : string;
}

export interface Employment {
  city                  : string;
  country               : string;
  current_employeer_flag: Binary;
  employer_name         : string;
  employment_type       : EmploymentType;
  job_end_date?         : any;
  job_start_date        : any;
  job_title?            : string;
  line_of_work_months?  : number;
  line_of_work_years?   : number;
  ownership_share?      : EmploymentOwnershipShare;
  party_to_transaction? : Binary;
  phone?                : string;
  postal_code           : number;
  state                 : any;
  street_address_1      : string;
  street_address_2      : string;
}

export interface Residence {
  association_annual_amount?  : number;
  annual_insurance_amount?    : number;
  annual_tax_amount?          : number;
  city                        : string;
  country?                    : string;
  /**
   * @depreciated value is replaced with residence_detail_type
   **/
  current_residence_flag      : Binary;
  duration_months?            : number;
  escro_flag?                 : Binary;
  monthly_amount?             : number;
  postal_code                 : number;
  property_type?              : PropertyType;
  residence_detail_type       : ResidenceDetailType;
  ownership_type              : LivingSituation;
  state                       : any;
  street_address_1            : string;
  street_address_2?           : string;
}

export interface ReoProperty {
  city?                       : string;
  property_type               : PropertyType;
  property_use                : PropertyUsage;
  present_value?              : number;
  monthly_gross_rental_income?: number;
  monthly_expense_amount?     : number;
  postal_code?                : number;
  state?                      : any;
  street_address?             : string;
  street_address_2?           : string;
}

export interface Asset {
  asset_amount          : number;
  asset_institution_name: string;
  asset_type            : AccountType;
}

export interface Income {
  income_type       : IncomeSource;
  monthly_amount    : number;
}

export interface Liability {
  liability_description : any;
  liability_status      : LiabilityStatus;
  liability_type        : LiabilityType;
  monthly_amount        : number;
}

export type BorrowerFieldName = FieldNames.decBorrower | FieldNames.decCoBorrower;

export type Appointment = {
  date?: string;
  time?: string;
}

export interface CreateUniversalLoanRequest {
  creditReportId       : number;
  source               : UniversalLoanSource;
  loanOfficerId        : number;
  loanApplication      : UniversalLoanApplication;
  auditMetaData        : string;
}

export interface UniversalLoanApplication {
  assets                     : Asset[];
  borrower                   : Partial<Borrower>[];
  birthDate                  : any;
  coborrowerOnLoanFlag       : Binary;
  employment                 : Employment[];
  fee?                       : any;
  livingMonthlyRentAmount?   : number;
  loanType?                  : any;
  monthlyPayment?            : number;
  propertyZip                : string;
  realEstateLiabilities      : LiabilityAccount[];
  subjectProperty?           : SubjectProperty;
  titleFirstName             : string;
  titleLastName              : string;
  titleMiddleName            : string;
  titleSuffix?               : string;
  // Reverse Mortgage
  reverseMortgage?           : ReverseMortgage;
  // Auto Loan
  autoLoan?                  : AutoLoan;
  // Other Vehicle Loan
  otherVehicleLoan?          : OtherVehicleLoan;
  // Credit Card
  creditCard?                : CreditCard;
  // Credit Booster Loan
  creditBoosterLoan?         : CreditBoosterLoan;
  // Personal Loan
  personalLoan?              : PersonalLoan;
  // Commercial Loan
  commercialLoan?            : CommercialLoan;
  // Small Business Loan
  smallBusiness?             : SmallBusiness;
  // Financial Statement
  financialStatement?        : FinancialStatement;
}

export interface ExpressFormRequest extends AuditMetadata {
  avm?                        : AutoValuationModel;
  borrowers                   : Partial<Borrower>[];
  borrowerCustomerId          : string; // Finicity customer Id
  closingCostType             : ClosingCostType;
  coBorrowerCustomerId?       : string; // Finicity customer Id
  coBorrowerSsn               : string;
  creditReportId              : number;
  formName                    : string;
  hasEscrow                   : boolean;
  homeEquityLoanPurpose?      : HomeEquityLoanPurpose;
  homeEquityLoanPurposeOther? : string;
  hasUniqueHomeEquityProgram? : string;
  loanAmount                  : number;
  loanOfficerId               : number;
  loanTerm                    : number;
  loanType                    : ProductFamily;
  monthlyPayment              : number;
  pointFee                    : number;
  properties?                 : HomeEquityProperties[];
  rate                        : number;
  realEstateLiabilities       : LiabilityAccount[];
  ssn                         : string;
  subjectProperty             : SubjectProperty;
  totalNumberOfProperties     : number;
}

export interface HomeEquityProperties {
  annualHOA?               : number;
  annualInsurance?         : number;
  annualTaxes?             : number;
  isCollateral             : boolean;
  isPrimary                : boolean;
  propertyType?            : PropertyType;
  propertyUsage?           : PropertyUsage;
  mortgages                : LiabilityAccount[];
}

export interface AutoValuationModel {
  avmHomeValue?          : number;
  avmHomeValueHighLimit? : number;
  avmHomeValueLowLimit?  : number;
  avmReportId?           : number;
}

export interface UniversalResidence extends Residence {
  property_title_held?  : ReverseMortgagePropertyTitleHeld;
  property_type?        : PropertyType;
  property_use?         : PropertyUsage;
}

export enum ResidenceDetailType {
  CURRENT  = 'CURRENT',
  MAILING  = 'MAILING',
  PREVIOUS = 'PREVIOUS',
}

export type UniversalPrefillResponse = {
  application: UniversalLoanApplication;
  safeMonthlyPayment: number;
};
