import React from 'react';
import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  EXPRESS_AUTOMATIC_VOIE,
  EXPRESS_BORROWER_DECLARATION,
  EXPRESS_COBORROWER,
  EXPRESS_COBORROWER_ADDRESS,
  EXPRESS_COBORROWER_DECLARATION,
  EXPRESS_COLLECT_SSN,
  EXPRESS_CREDIT_REPORT_ONE,
  EXPRESS_PERSONAL_INFO,
  EXPRESS_PRIMARY_HOME,
  EXPRESS_PRIMARY_HOME_ESCROW,
  EXPRESS_PRIMARY_HOME_TAX_INS_FEE,
  EXPRESS_REAL_ESTATE_LIABILITIES,
  EXPRESS_REFINANCE_DETIALS,
  EXPRESS_UPLOAD_DOCUMENTS,
  EXPRESS_VOIE_REPORTS,
  EXPRESS_VOIE_START_CONNECT,
} from 'app/models/sections/constants';
import { ExpressQuestionsByName } from 'app/models/questions/express';
import {
  coBorrowerHasDifferentAddress,
  hasCoBorrower,
  hasHomeValueSetTooHigh,
  hasHomeValueSetTooLow,
  LoanOfficerCondition,
} from 'app/models/fields/conditionals';
import {
  ajaxValidateCoBorrowerResidence,
  ajaxValidateBorrowerResidence,
  updateRefinanceExpressAddress,
} from 'app/actions/form/address-validation/actions';
import { AuthorizeCreditReport } from 'app/components/FormAgreements/AuthorizeCreditReport';
import { ajaxPostCreditReportForRegularTurbo } from 'app/actions/form/credit-report/actions';
import { BorrowerType } from 'app/models/options/enums';
import { getExpressDeclarationQuestions } from 'app/models/questions/declarations';
import { handleExpressFormSubmit, handleExpressFetchRates } from 'app/actions/form/actions';
import {
  handleGetBorrowerResidenceInfo,
} from 'app/actions/form/property/actions';
import { FormParagraphs } from 'app/models/paragraphs';
import { UploadDocumentsStep } from 'app/components/Express/UploadDocumentsStep';
import { ajaxGetFinancialVerificationUrl } from 'app/actions/form/verification/actions';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';

export const getExpressSectionById = (id: number): Section => {
  return EXPRESS_SECTIONS.find((section) => section.id === id) as Section;
};

const EXPRESS_SECTIONS: Section[] = [
  {
    id        : EXPRESS_PERSONAL_INFO,
    title     : 'Enter your personal information',
    questions : ExpressQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
      FieldNames.citizenshipType,
      FieldNames.maritalStatus,
    ]),
    hideBackButton: true,
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerUnderage,
        LoanOfficerCondition.isBorrowerNotCitizen,
      ],
    },
  },
  {
    id        : EXPRESS_PRIMARY_HOME,
    title     : 'Tell us about your primary home',
    questions : ExpressQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id        : EXPRESS_PRIMARY_HOME_ESCROW,
    questions : ExpressQuestionsByName([
      FieldNames.livingEscrowUsage,
      FieldNames.escrowUsage,
      FieldNames.propertyUsage,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.hasCurrentEscrow,
        LoanOfficerCondition.wantsFutureEscrow,
        LoanOfficerCondition.isRefinancingOtherHome,
      ],
    },
    submitAction: handleGetBorrowerResidenceInfo,
  },
  {
    id        : EXPRESS_PRIMARY_HOME_TAX_INS_FEE,
    questions : ExpressQuestionsByName([
      FieldNames.yearTaxes,
      FieldNames.yearInsure,
      FieldNames.yearAssociate,
    ]),
    submitAction: updateRefinanceExpressAddress,
  },
  {
    id        : EXPRESS_COBORROWER,
    questions : ExpressQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerDOB,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerPhone,
      FieldNames.coBorrowerCitizenshipType,
      FieldNames.coBorrowerOnTitle,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isCoBorrowerUnderage,
        LoanOfficerCondition.isCoBorrowerNotCitizen,
      ],
    },
  },
  {
    id        : EXPRESS_COBORROWER_ADDRESS,
    questions : ExpressQuestionsByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
      FieldNames.coBorrowerLivingRentOrOwn,
      FieldNames.coBorrowerMaritalStatus,
    ]),
    showIf       : [hasCoBorrower],
    submitAction : (formValues) =>
      coBorrowerHasDifferentAddress(formValues) ? ajaxValidateCoBorrowerResidence(formValues) : ajaxValidateBorrowerResidence(formValues),
  },
  {
    id           : EXPRESS_CREDIT_REPORT_ONE,
    questions    : ExpressQuestionsByName([ FieldNames.loanOfficer ]),
    agreement    : <AuthorizeCreditReport />,
    submitAction : ajaxPostCreditReportForRegularTurbo,
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.hasMoreThanOneHomeOrLien],
    },
  },

  {
    id              : EXPRESS_REAL_ESTATE_LIABILITIES,
    title           : 'Real Estate Liabilities',
    questions       : ExpressQuestionsByName([
      FieldNames.realEstateLiabilities,
      FieldNames.isOwningHomeOutright,
      FieldNames.hasMadeMostRecentMortgagePayment,
      FieldNames.hasOtherTaxBillsDue,
    ]),
    showLoanOfficer : true,
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.hasMoreThanOneHomeOrLien],
    },
  },
  {
    id: EXPRESS_REFINANCE_DETIALS,
    questions : ExpressQuestionsByName([
      FieldNames.loanPurpose,
      FieldNames.cashOut,
      FieldNames.propertyType,
      FieldNames.homeValue,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.hasHomeValueSetTooLow,
        LoanOfficerCondition.hasHomeValueSetTooHigh,
      ],
    },
    submitIf        : [(values) => !hasHomeValueSetTooLow(values) && !hasHomeValueSetTooHigh(values)],
    showLoanOfficer : true,
    submitText      : 'Submit',
    submitAction    : handleExpressFetchRates,
  },
  {
    id        : EXPRESS_AUTOMATIC_VOIE,
    paragraph : FormParagraphs.automaticVoie,
    questions : ExpressQuestionsByName([FieldNames.canVerifyOfIncomeEmployment]),
    title     : 'Would you like to automatically receive verification of your income and employment?',
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.verifyOfIncomeAndEmploymentNotAvailable],
    },
  },
  {
    id           : EXPRESS_COLLECT_SSN,
    questions    : ExpressQuestionsByName([FieldNames.ssnQuestionRequirement]),
    submitAction : ajaxGetFinancialVerificationUrl,
    agreement    : <AuthorizeSsnFullPull />,
  },
  {
    id        : EXPRESS_VOIE_START_CONNECT,
    questions : ExpressQuestionsByName([FieldNames.isFinancialVerificationComplete]),
  },
  {
    id                : EXPRESS_VOIE_REPORTS,
    questions         : ExpressQuestionsByName([FieldNames.isFinancialVerificationComplete]),
    hideBackButton    : true,
  },
  {
    id           : EXPRESS_BORROWER_DECLARATION,
    title        : 'Borrower Questions',
    questions    : getExpressDeclarationQuestions(BorrowerType.PRIMARY),
    submitAction : handleExpressFormSubmit,
    submitIf     : [(values) => !hasCoBorrower(values)],
  },
  {
    id           : EXPRESS_COBORROWER_DECLARATION,
    title        : 'Co-Borrower Questions',
    questions    : getExpressDeclarationQuestions(BorrowerType.COBORROWER),
    showIf       : [hasCoBorrower],
    submitAction : handleExpressFormSubmit,
  },
  {
    id              : EXPRESS_UPLOAD_DOCUMENTS,
    ifNoQuestions   : UploadDocumentsStep,
    hideProgressBar : true,
  },
];
