import { ShortForm } from '@lenderful/domain';
import { FieldNames } from 'app/models/fields/names';
import { getLoanAmount, Endpoints, getSalesforceParams } from 'app/api/helpers';
import { LoanPurpose } from 'app/api/rates/types';
import { client } from 'app/api/_client';
import { RateInfo } from 'app/models/types';
import { logger } from 'app/util/logger';
import { LoanOfficerDetail } from 'app/api/loan-officer';
import { isTakingCashOut, isYes } from 'app/models/fields/conditionals';
import { RatesRequest, ShortFormRequestBody } from 'app/api/short-form/types';
import moment from 'moment';
import { HomeEquityRatesResponse } from 'app/api/home-equity/types';
import { FormName } from '../../models/options/enums';

/**
 * Takes in the rates from the forms and maps them over to the payload
 * expected by the shortform lambda
 *
 * @param {RateInfo[]} [rates=[]]
 * @returns {RatesRequest[]}
 */
export const mapRatesToPayload = (rates: RateInfo[] = []): RatesRequest[] => {
  return rates.map((rate) => {
    return {
      type   : rate.loanType,
      payment: rate.principalAndInterestPayment,
      rate   : rate.rate,
      apr    : rate.apr,
      fees   : rate.quoteFees,
      link   : rate.applyUrl,
    };
  });
};

/**
 * Takes in the home equity rates from the forms and maps them over to the payload
 * expected by the shortform lambda
 *
 * @param {RateInfo[]} [rates=[]]
 * @returns {RatesRequest[]}
 */
export const mapHomeEquityRatesToPayload = (homeEquityResponse: HomeEquityRatesResponse): RatesRequest[] => {
  const rates = [...homeEquityResponse.fixedRatePrograms];
  if (homeEquityResponse.lineOfCredit) {
    rates.push(homeEquityResponse.lineOfCredit.program);
  }
  return rates.map((rate) => {
    return {
      type   : rate.productFamily === 'HELOC' ? 'Line of Credit' : rate.loanType,
      payment: rate.principalAndInterestPayment,
      rate   : rate.rate,
      apr    : rate.apr,
      fees   : 0,
      link   : '',
    };
  });
};

/**
 * Maps over form values and reassigns them a key recognized by the
 * ShortForm lambda.
 *
 * @param {any} form values from form
 * @param {LoanOfficerDetail} loanOfficer
 * @param {string} avmReport
 * @returns {ShortForm.RefinanceApplication | ShortForm.PurchaseApplication | ShortForm.HomeEquityTurboApplication} Payload accepted by the API
 *
 * @deprecated move all this into mapShortFormApplication()
 */
export const mapUserDataToPayload = (form, loanOfficer: LoanOfficerDetail, avmReportId?: number): ShortForm.PurchaseApplication | ShortForm.RefinanceApplication | ShortForm.HomeEquityTurboApplication => {
  const clientMeta = {
    sf            : { ...getSalesforceParams() },
    loanOfficerId : loanOfficer.id,
  };
  const payload: ShortForm.PurchaseApplication | ShortForm.RefinanceApplication | ShortForm.HomeEquityTurboApplication = {
    avm                        : { avmReportId },
    client_meta                : clientMeta,
    branch_employee            : form[FieldNames.branchEmployee],
    branch_number              : form[FieldNames.branchNumber],
    buying_stage               : form[FieldNames.buyingProcess],
    credit_score               : form[FieldNames.creditScore],
    down_payment               : form[FieldNames.downDollar],
    email                      : form[FieldNames.email],
    estimated_home_value       : form[FieldNames.homeValue],
    firstName                  : form[FieldNames.firstName],
    flag_cash_out              : isTakingCashOut(form) ? 'Yes' : 'No',
    flag_escro_taxes           : form[FieldNames.escrowUsage],
    hasUniqueHomeEquityProgram : form[FieldNames.hasUniqueHomeEquityProgram],
    lastName                   : form[FieldNames.lastName],
    loan_amount                : getLoanAmount(form),
    phone                      : form[FieldNames.phone],
    propertyCity               : form[FieldNames.propertyCity],
    propertyState              : form[FieldNames.propertyState],
    propertyStreet             : form[FieldNames.propertyStreet],
    property_type              : form[FieldNames.propertyType],
    property_use               : form[FieldNames.propertyUsage],
    purchase_price             : form[FieldNames.homeValue],
    purpose_of_refinance       : form[FieldNames.homeEquityLoanPurpose] ? form[FieldNames.homeEquityLoanPurpose] : form[FieldNames.loanPurpose],
    type                       : LoanPurpose[form[FieldNames.loanPurpose]],
    unpaid_balance_amount      : form[FieldNames.mortgageBalance],
    zip_code                   : form[FieldNames.propertyZip],
  };

  return payload;
};

/**
 * Maps over the form values and generates the JSON for a short form application.
 * To start this is only reverse mortgage.  Other forms will follow.
 *
 * @param form
 * @param {number} loanOfficerId
 * @returns {ShortForm.ReverseMortgageApplication}
 */
export const mapShortFormApplication = (form): ShortForm.ReverseMortgageApplication => ({
  age                        : form[FieldNames.age],
  coBorrowerAge              : isYes(form[FieldNames.coBorrowerYN]) ? form[FieldNames.coBorrowerAge] : undefined,
  coBorrowerDateOfBirth      : (isYes(form[FieldNames.coBorrowerYN]) &&  form[FieldNames.coBorrowerDOB]) ? moment(form[FieldNames.coBorrowerDOB]).format('MM-DD-YYYY') : undefined,
  coBorrowerEmail            : isYes(form[FieldNames.coBorrowerYN]) ? form[FieldNames.coBorrowerEmail] : undefined,
  coBorrowerFirstName        : isYes(form[FieldNames.coBorrowerYN]) ? form[FieldNames.coBorrowerFirstName] : undefined,
  coBorrowerLastName         : isYes(form[FieldNames.coBorrowerYN]) ? form[FieldNames.coBorrowerLastName] : undefined,
  coBorrowerPhone            : isYes(form[FieldNames.coBorrowerYN]) ? form[FieldNames.coBorrowerPhone] : undefined,
  dateOfBirth                : form[FieldNames.dateOfBirth] ? moment(form[FieldNames.dateOfBirth]).format('MM-DD-YYYY') : undefined,
  downPayment                : form[FieldNames.downDollar],
  email                      : form[FieldNames.email],
  firstName                  : form[FieldNames.firstName],
  hasSelectedNewHome         : form[FieldNames.hasSelectedNewHome],
  lastName                   : form[FieldNames.lastName],
  livingPropertyStreet       : form[FieldNames.livingPropertyStreet],
  livingPropertyStreet2      : form[FieldNames.livingPropertyStreet2],
  livingPropertyCity         : form[FieldNames.livingPropertyCity],
  livingPropertyState        : form[FieldNames.livingPropertyState],
  livingPropertyZip          : form[FieldNames.livingPropertyZip],
  maxLumpSum                 : form[FieldNames.maxLumpSum],
  middleName                 : form[FieldNames.middleName],
  mortgageBalance            : form[FieldNames.mortgageBalance],
  netPrincipalLimit          : form[FieldNames.netPrincipalLimit],
  phone                      : form[FieldNames.phone],
  propertyAppraisedValue     : form[FieldNames.homeValue],
  propertyType               : form[FieldNames.propertyType],
  propertyStreet             : form[FieldNames.propertyStreet],
  propertyStreet2            : form[FieldNames.propertyStreet2],
  propertyCity               : form[FieldNames.propertyCity],
  propertyState              : form[FieldNames.propertyState],
  propertyZip                : form[FieldNames.propertyZip],
  purchasePrice              : form[FieldNames.purchasePrice],
  reverseMortgageLoanPurpose : form[FieldNames.reverseMortgageLoanPurpose],
  suffixName                 : form[FieldNames.suffixName],
  totalUpfrontCosts          : form[FieldNames.totalUpfrontCosts],
  type                       : LoanPurpose.REVERSE,
});

const getShortFormTransactionType = (formName: FormName): ShortForm.TransactionType => {
  switch (formName) {
    case FormName.SHORT_FORM_PURCHASE:
      return ShortForm.TransactionType.PURCHASE;
    case FormName.SHORT_FORM_REFINANCE:
      return ShortForm.TransactionType.REFINANCE;
    case FormName.REVERSE:
      return ShortForm.TransactionType.REVERSE;
    case FormName.HOMEEQUITY:
      return ShortForm.TransactionType.HOME_EQUITY;
    case FormName.HOME_EQUITY_EXPRESS:
      return ShortForm.TransactionType.HOME_EQUITY_TURBO;
    default:
      return undefined;
  }
};
/**
 * This method posts to the backend endpoint for short forms
 * (purchase, refi, home-equity).
 *
 * @param {ShortFormRequestBody} body
 * @returns {Promise<void>}
 */
export const postShortForm = async (body: ShortFormRequestBody): Promise<void> => {
  const transaction = getShortFormTransactionType(body.formName);
  return client
    .post(`${Endpoints.SHORTFORM}/${transaction}`, body)
    .then((response) => {
      if (response.data?.errorMessage) {
        logger.error('Error in Posting short form: ', response);
        throw response;
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      logger.error('Error in Posting short form: ', error);
      throw error;
    });
};
