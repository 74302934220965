import { FieldNames } from 'app/models/fields/names';
import { REFINANCE_REASON_OPTIONS } from 'app/models/options/options';
import { Currency } from 'app/components/FormFields/Currency';
import { Select } from 'app/components/FormFields/Select';
import { RadioField } from 'app/components/FormFields/RadioField';
import { SHARED } from 'app/models/questions/shared';
import { Question } from 'app/models/types';
import { CreditScoresSelect } from 'app/components/FormFields/CreditScoresSelect';

/**
 * Takes in a field name and returns the corresponding refinance
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const refiQuestionByName = (name: string): Question => {
  return REFINANCE_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in a question id and returns the corresponding refinance
 * question
 *
 * @param {number} id Question id
 * @returns Question with matching id
 */
export const refiQuestionById = (id: number): Question => {
  return REFINANCE_QUESTIONS.find((question) => {
    return question.id === id;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const refiQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(refiQuestionByName(name));
  }
  return questions;
};

export const REFINANCE_QUESTIONS: Question[] = [
  {
    id        : 1001,
    component : RadioField,
    label     : 'Purpose of Refinance',
    hideLabel : true,
    name      : FieldNames.loanPurpose,
    options   : REFINANCE_REASON_OPTIONS,
    title     : 'What is the purpose of your loan refinance?',
  },
  {
    id: 1002,
    ...SHARED[FieldNames.propertyUsage],
    hideLabel: true,
    title    : 'What is this property used for?',
  },
  {
    id: 1003,
    ...SHARED[FieldNames.propertyType],
    hideLabel : true,
    title     : 'What type of property are you refinancing?',
  },
  {
    id: 1004,
    ...SHARED[FieldNames.creditScore],
    title: 'What is your estimated credit score?',
  },
  {
    id: 1005,
    ...SHARED[FieldNames.escrowUsage],
    title: 'Will you have an escrow for taxes and insurance?',
  },
  {
    id       : 1006,
    component: Currency,
    label    : 'Home Value',
    name     : FieldNames.homeValue,
    title    : 'What is the estimated home value?',
    validator: {
      type : 'isGreaterThanField',
      param: FieldNames.loanAmount,
    },
  },
  {
    id       : 1007,
    component: Currency,
    label    : 'Loan Amount',
    name     : FieldNames.loanAmount,
    title    : 'What is your loan amount?',
    validator: {
      type : 'loanAmountIsValid',
      param: FieldNames.homeValue,
    },
  },
  {
    id: 1008,
    ...SHARED[FieldNames.propertyZip],
    title: 'In which zip code is your home located?',
  },
  {
    id: 1009,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1010,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1011,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1012,
    ...SHARED[FieldNames.phone],
  },
];

/* Applied to the recalculate questions */
const withSelect = {
  component    : Select,
  hideTitle    : true,
  isSummaryPage: true,
};

const withCreditScoresSelect = {
  component     : CreditScoresSelect,
  hideTitle     : true,
  isSummaryPage : true,
};

/**
 * List of questions used to recalulate rates
 * in the order they should be displayed
 */
export const refinanceRecalculateQuestions: Question[] = [
  // Home Value
  refiQuestionById(1006),
  // Loan Amount
  refiQuestionById(1007),
  // Zip Code
  refiQuestionById(1008),
  // Property Use
  Object.assign({}, refiQuestionById(1002), withSelect),
  // Property Type
  Object.assign({}, refiQuestionById(1003), withSelect),
  // Credit Score
  Object.assign({}, refiQuestionById(1004), withCreditScoresSelect),
  // Escrows and Insurance
  Object.assign({}, refiQuestionById(1005), withSelect),
  // Purpose of Refinance
  Object.assign({}, refiQuestionById(1001), withSelect),
];
