import React from 'react';
import {
  createStyles,
  Grid,
  Paper,
  Typography,
  withStyles,
  WithStyles,
  colors,
} from '@material-ui/core';
import { ProgressBar } from 'app/components/ProgressBar';
import { Question } from 'app/components/Question';
import { RateHeader } from 'app/components/RateHeader';
import { View } from 'app/components/View';
import css from './Section.module.css';
import { FieldNames } from 'app/models/fields/names';
import { LoanOfficerDetails } from 'app/components/LoanOfficerDetails';
import { ReferToLoanOfficer } from 'app/components/StopGates/ReferToLoanOfficer';
import { localizeQuestions } from 'app/i18n/helpers';
import { useTranslation, Trans } from 'react-i18next';
import { Section as SectionType } from 'app/models/types';
import { filterQuestions } from 'app/util/question-helpers';
import { logger } from 'app/util/logger';

const styles = (theme) =>
  createStyles({
    displayRate: {
      paddingBottom: '28px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '50px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '40px',
      },
    },
    paragraphBorder: {
      display     : 'block',
      marginBottom: '20px',
      borderBottom: `1px solid ${colors.grey[300]}`,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  });

interface Styled extends WithStyles<typeof styles> {
  [key: string]: any;
}

type Props = Styled & SectionType;
const SectionComponent = ({ ...props }: Props) => {
  const {
    ageLimit,
    autoHighLimitPercent,
    autoLoanMinLtvAction,
    borrowerJobDurationAlgorithm,
    borrowerJobDurationMonths,
    classes,
    excludedQuestions,
    excludedOptions,
    ssnQuestionRequirement,
    handleChange,
    handleSubmit,
    hideRateHeader,
    isServicing,
    section,
    steps,
    submitting,
    values = {},
    getNextSection,
    referToLo,
    showEditLetterAmount,
    schedulerEnabled,
    handleSettingConditional,
    lowFicoLimit,
    lowFicoSecondaryHomeLimit,
    hasEnabledCreditReportOnTurbo,
    ...otherProps
  } = props;
  const { selectedRate, answeredQuestions } = props.values ? props.values : '';
  const { t }                               = useTranslation();
  const { isHideGovtMonitoringHelocArm }    = props?.config?.featureToggles || { isHideGovtMonitoringHelocArm  : true };
  const { isDefaultedHMDAAnswer }           = props?.config?.featureToggles || { isDefaultedHMDAAnswer         : true };
  const { isHideDependentsAges }            = props?.config?.featureToggles || { isHideDependentsAges          : false };
  const { isCollectingMailingAddress }      = props?.config?.featureToggles || { isCollectingMailingAddress    : false };
  const { hasLoanTypeQuestion }             = props?.config?.featureToggles || { hasLoanTypeQuestion           : false };
  const { hasLineOfWorkQuestion }           = props?.config?.featureToggles || { hasLineOfWorkQuestion         : false };
  const { hasReverseAgeQuestion }           = props?.config?.featureToggles || { hasReverseAgeQuestion         : false };
  const { hasAutoLoanSpecialCalculation }   = props?.config?.featureToggles || { hasAutoLoanSpecialCalculation : false };
  const { isHidePurchasePropertyAddress }   = props?.config?.featureToggles || { isHidePurchasePropertyAddress : false };
  
  // Ensure data from state of other reducers is copied to form for comparisons and conditionals
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.excludedQuestions, excludedQuestions);
  }, [handleSettingConditional, props.form, excludedQuestions]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.autoHighLimitPercent, autoHighLimitPercent);
  }, [handleSettingConditional, props.form, autoHighLimitPercent]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.autoLoanMinLtvAction, autoLoanMinLtvAction);
  }, [handleSettingConditional, props.form, autoLoanMinLtvAction]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.ssnQuestionRequirement, ssnQuestionRequirement);
  }, [handleSettingConditional, props.form, ssnQuestionRequirement]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.schedulerEnabled, schedulerEnabled);
  }, [handleSettingConditional, props.form, schedulerEnabled]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.isHideGovtMonitoringHelocArm, isHideGovtMonitoringHelocArm);
  }, [handleSettingConditional, props.form, isHideGovtMonitoringHelocArm]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.lowFicoLimit, lowFicoLimit);
  }, [handleSettingConditional, props.form, lowFicoLimit]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.lowFicoSecondaryHomeLimit, lowFicoSecondaryHomeLimit);
  }, [handleSettingConditional, props.form, lowFicoSecondaryHomeLimit]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.borrowerJobDurationAlgorithm, borrowerJobDurationAlgorithm);
  }, [handleSettingConditional, props.form, borrowerJobDurationAlgorithm]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.borrowerJobDurationMonths, borrowerJobDurationMonths);
  }, [handleSettingConditional, props.form, borrowerJobDurationMonths]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.isDefaultedHMDAAnswer, isDefaultedHMDAAnswer);
  }, [handleSettingConditional, props.form, isDefaultedHMDAAnswer]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.isHideDependentsAges, isHideDependentsAges);
  }, [handleSettingConditional, props.form, isHideDependentsAges]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.isCollectingMailingAddress, isCollectingMailingAddress);
  }, [handleSettingConditional, props.form, isCollectingMailingAddress]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.hasLoanTypeQuestion, hasLoanTypeQuestion);
  }, [handleSettingConditional, props.form, hasLoanTypeQuestion]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.hasLineOfWorkQuestion, hasLineOfWorkQuestion);
  }, [handleSettingConditional, props.form, hasLineOfWorkQuestion]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.hasReverseAgeQuestion, hasReverseAgeQuestion);
  }, [handleSettingConditional, props.form, hasReverseAgeQuestion]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.hasAutoLoanSpecialCalculation, hasAutoLoanSpecialCalculation);
  }, [handleSettingConditional, props.form, hasAutoLoanSpecialCalculation]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.ageLimit, ageLimit);
  }, [handleSettingConditional, props.form, ageLimit]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.isHidePurchasePropertyAddress, isHidePurchasePropertyAddress);
  }, [handleSettingConditional, props.form, isHidePurchasePropertyAddress]);
  React.useEffect(() => {
    handleSettingConditional(props.form, FieldNames.hasEnabledCreditReportOnTurbo, hasEnabledCreditReportOnTurbo);
  }, [handleSettingConditional, props.form, hasEnabledCreditReportOnTurbo]);

  /* Handles the firing of submit actions on pages that could be conditionally shown */
  const handleSubmitConditionals = (formValues): any => {
    const hasNoConditionals = typeof section.submitIf === 'undefined';
    if (hasNoConditionals) {
      return true;
    }
    const passesConditionals = section.submitIf.some((condition) => {
      return condition(formValues);
    });
    if (!passesConditionals) {
      logger.debug(`Skipping submit action ${section.submitAction.name}, conditionals not met`);
    }
    return passesConditionals;
  };
  const shouldSubmit = handleSubmitConditionals(values);
  /* If a submit action is defined in the section object, use it as the submitHandler */
  const handleForwardButton =
    shouldSubmit && section.submitAction
    /* Set submit action to the submitAction specified in the section */
      ? (payload) => props.dispatch(section.submitAction(payload, section.conditionalIf))
    /* Set submit action to the default of 'getNextSection' */
      : (formValues) => getNextSection(formValues, section.conditionalIf);
  const canHideBackButton =
      (typeof section.hideBackButton === 'boolean' ||
        !section.hideBackButton) ? section.hideBackButton : section.hideBackButton(values);
  /* List of filtered questions */
  const allExcludedQuestions = [...(excludedQuestions||[]), ...(answeredQuestions||[])];
  const questions            = localizeQuestions(filterQuestions(section.questions, values, allExcludedQuestions), t);
  const NoQuestionsComponent = section.ifNoQuestions;
  /* Set to true when the referToLoanOfficer action is fired */
  const isReferredToLo       = referToLo && referToLo.isReferToLo;
  /* Hide the progress bar any time it's specified in the section or a user is referred to the LO */
  const hideProgressBar      = section.hideProgressBar || isReferredToLo;

  const renderSectionWithQuestions = () => {
    return (
      <View
        agreement          = {section.agreement}
        centerSubmitButton = {section.centerSubmitButton}
        ctaSubmitButton    = {section.ctaSubmitButton}
        fixedSubmitButton  = {section.fixedSubmitButton}
        handleBackButton   = {props.getPreviousSection}
        hideBackButton     = {canHideBackButton}
        hideForwardButton  = {section.hideForwardButton}
        hidePartialButton  = {section.hidePartialButton}
        hideProgressBar    = {hideProgressBar}
        invalid            = {props.invalid}
        removePadding      = {section.removePadding}
        sectionId          = {section.id}
        shouldSubmit       = {shouldSubmit}
        submitAction       = {section.submitAction}
        submitText         = {section.submitText}
        submitting         = {submitting}
        syncErrors         = {props.syncErrors}
        values             = {values}
      >
        {section.title && (
          <Typography variant="h6">{t(`section.${section.id}.title`, { defaultValue: section.title })}</Typography>
        )}
        {section.paragraph && (
          <Typography variant="body2" gutterBottom={true}>
            <Trans i18nKey={`section.${section.id}.paragraph`}>
              {section.paragraph}
            </Trans>
          </Typography>
        )}
        {section.paragraphBorder && (
          <div className={classes.paragraphBorder} />
        )}
        {section.subtitle && (
          <Typography
            variant = "subtitle1"
            classes = {{
              root      : 'specialFont subtitle',
              subtitle1: 'large',
            }}
          >
            {t(`section.${section.id}.subtitle`, { defaultValue: section.subtitle })}
          </Typography>
        )}
        {questions.length
          ? questions.map((question, index) => {
            return (
              <Question
                key = {`${question.name}-${index}`}
                {...question}
                excludedOptions = {props.excludedOptions}
                blur            = {props.blur}
                change          = {props.change}
                dispatch        = {props.dispatch}
                getNextSection  = {props.getNextSection}
                goToSection     = {props.goToSection}
                isOptional      = {question.isOptional}
                values          = {props.values}
                host            = {props.host}
                valid           = {props.valid}
                syncErrors      = {props.syncErrors}
                submitting      = {submitting}
              />
            );
          })
          : null}
      </View>
    );
  };

  const renderStopGate = () => {
    return (
      <NoQuestionsComponent
        getNextSection         = {props.getNextSection}
        values                 = {props.values}
        submitting             = {props.submitting}
        {...otherProps}
      />
    );
  };

  const wrapperClass = () => {
    if ((!questions.length && section.ifNoQuestions) || isReferredToLo) {
      if (section.clearWrapper) {
        return 'clearWrapper';
      }
      return 'stopGate';
    } else {
      return;
    }
  };

  const shouldRemovePadding = section.removePadding ? 'noPadding' : '';
  const noStepperClass      = hideProgressBar && !section.useTopSection ? 'noStepQuestion' : '';

  return (
    <Grid container classes={{ container: 'canvas' }}>
      <Grid item xs={12}>
        {!hideProgressBar && (
          <Grid container classes={{ container: 'formWrapper stepper' }}>
            <ProgressBar steps={steps} section={section} />
          </Grid>
        )}
        <Grid container={false}>
          <Grid item classes={{ item: `${noStepperClass}` }}>
            {section.useTopSection && section.useTopSection}
            <Paper
              elevation = {2}
              classes   = {{
                root      : 'formWrapper',
                elevation2: `${wrapperClass()}`,
              }}
              className = {`${css.formWrapper} ${wrapperClass()} ${shouldRemovePadding}`}
            >
              {!hideProgressBar && !hideRateHeader &&
                selectedRate && (
                <div className = {classes.displayRate}>
                  <RateHeader
                    goToSection  = {props.goToSection}
                    isServicing  = {isServicing}
                    selectedRate = {selectedRate}
                  />
                </div>
              )
              }
              <form
                name     = {props.form}
                onChange = {handleChange}
                onSubmit = {handleSubmit(handleForwardButton)}
              >
                {isReferredToLo ? <ReferToLoanOfficer {...props} /> : null}
                {!isReferredToLo && questions.length ? renderSectionWithQuestions() : null}
                {!questions.length && section.ifNoQuestions
                  ? renderStopGate()
                  : null}
              </form>
            </Paper>
            {section.showLoanOfficer && <LoanOfficerDetails removePadding={section.removePadding} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Section: any = withStyles(styles)(SectionComponent);
