import React from 'react';
import { FieldArray } from 'redux-form';
import { Currency } from 'app/components/FormFields/Currency';
import { Select } from 'app/components/FormFields/Select';
import { FieldNames } from 'app/models/fields/names';
import { Button } from 'app/components/Button';
import { requiredArray } from 'app/util/validators';
import { verifyAssetInitialValue } from 'app/util/initial-values';
import { ACCOUNT_TYPE_PURCHASE_OPTIONS } from 'app/models/options/options';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeOptions, localizeLabel } from 'app/i18n/helpers';
import { TextInput } from '../FormFields/TextInput';
import { arrayContains } from 'app/models/fields/conditionals';

const renderBankAccounts = ({ ...props }) => {
  const { fields, name, t, excludedQuestions } = props;
  const accountTypeField                 = `${name}.${FieldNames.accountType}`;
  const accountFinancialInstitutionField = `${name}.${FieldNames.accountFinancialInstitution}`;
  const accountBalanceField              = `${name}.${FieldNames.accountBalance}`;
  const showFinancialInstitutionField    = arrayContains(excludedQuestions, FieldNames.hasAssetFinancialInstitution);
  const renderFields        = () =>
    fields.map((field, index) => {
      return (
        <div key={`${field}-${index}`} style={{ paddingBottom: 20 }}>
          <Select
            label   = {localizeLabel(accountTypeField, t, 'Account Type')}
            name    = {`${field}.${FieldNames.accountType}`}
            options = {localizeOptions(accountTypeField, t, ACCOUNT_TYPE_PURCHASE_OPTIONS)}
          />
          {showFinancialInstitutionField && (
            <TextInput
              label      = {localizeLabel(accountFinancialInstitutionField, t, 'Name of Financial Institution')}
              name       = {`${field}.${FieldNames.hasAssetFinancialInstitution}`}
              isOptional = {true}
            />
          )}
          <Currency
            label = {localizeLabel(accountBalanceField, t, 'Account Balance')}
            name  = {`${field}.${FieldNames.accountBalance}`}
          />
          {fields.length > 1 && (
            <Button
              disableRipple
              color   = "primary"
              classes = {{
                root: 'link',
                text: 'remove',
              }}
              onClick = {() => fields.remove(index)}
            >
              {t(`question.${name}.button.remove`, { defaultValue: 'Remove' })}
            </Button>
          )}
        </div>
      );
    });

  return (
    <div>
      {renderFields()}
      <Button
        color    = "primary"
        disabled = {props.meta.invalid}
        variant  = "outlined"
        classes  = {{ root: 'add' }}
        onClick  = {() => fields.push(verifyAssetInitialValue)}
      >
        {t(`question.${name}.button.add`, { defaultValue: '+ Add Account' })}
      </Button>
    </div>
  );
};

export const VerifyBankAccounts = ({ ...props }) => {
  const { name } = props;
  const { excludedQuestions } = props.values;
  const { t }    = useTranslation();
  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderBankAccounts}
        name      = {name}
        props     = {{ name, t, excludedQuestions }}
        validate  = {[
          requiredArray([FieldNames.accountType, FieldNames.accountBalance]),
        ]}
      />
    </FormControl>
  );
};
