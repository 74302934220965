import { ConnectCancelEvent, ConnectDoneEvent, ConnectErrorEvent, ConnectRouteEvent } from '@finicity/connect-web-sdk';
import { logger } from 'app/util/logger';

/**
 * This method delegates to Finicitys code to launch "Connect" in an iFrame.  It uses
 * connectUrl and attaches event handlers for delegation.
 *
 * @param selector DOM element on the page to attach iframe to
 * @param connectUrl connection URL for "Connect" to Finicity
 * @param onSuccessEventHandler handler called on "success"
 * @param onCancelEventHandler handler called on "cancel"
 */
export const openFinicity = (
  selector              : string,
  connectUrl            : string,
  onSuccessEventHandler : () => {},
  onCancelEventHandler  : () => {},
) => {

  const finicityConnect = window['finicityConnect'];

  finicityConnect.launch(connectUrl, {
    selector,
    overlay: 'rgba(240,240,240,0)',
    success: (event: ConnectDoneEvent) => {
      logger.info('Finicity: user completed Connect', event);
      onSuccessEventHandler();
    },
    cancel: (event: ConnectCancelEvent) => {
      logger.info('Finicity: user cancelled the iframe', event);
      onCancelEventHandler();
    },
    error: (error: ConnectErrorEvent) => {
      logger.error('Finicity: Some runtime error was generated during insideConnect', error);
    },
    loaded: () => {
      logger.info('Finicity: iframe has finished loading');
    },
    route: (event: ConnectRouteEvent) => {
      logger.info('Finicity: user navigated event', event);
    },
    user: (event: any) => {
      logger.info('Finicity: user interacted with Connect', event);
    },
  });
};

/**
 * This method delgates to finicity connect to clean-up the iFrame
 */
export const closeFinicity = () => {
  const finicityConnect = window['finicityConnect'];
  finicityConnect.destroy();
};
