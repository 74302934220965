import { SHARED } from 'app/models/questions/shared';
import { Currency } from 'app/components/FormFields/Currency';
import { PurchaseDownPayment } from 'app/components/FormFields/PurchaseDownPayment';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Select } from 'app/components/FormFields/Select';
import { SingleFutureDate } from 'app/components/FormFields/SingleFutureDate';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { PURPOSE_OPTIONS, YES_OR_NO_OPTIONS } from 'app/models/options/options';
import { VerifyBankAccounts } from 'app/components/FormFieldArrays/VerifyBankAccounts';
import { ServicingAccountSummary } from 'app/components/VerifiableSections/ServicingAccountSummary';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { noChosenHome } from 'app/models/fields/conditionals';

/**
 * Takes in a field name and returns the corresponding purchase
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const servicingPurchaseQuestionByName = (name: string): Question => {
  return SERVICING_PURCHASE_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in a question id and returns the corresponding purchase
 * question
 *
 * @param {number} id Question id
 * @returns Question with matching id
 */
export const servicingPurchaseQuestionById = (id: number): Question => {
  return SERVICING_PURCHASE_QUESTIONS.find((question) => {
    return question.id === id;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const servicingPurchaseQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(servicingPurchaseQuestionByName(name));
  }
  return questions;
};

export const SERVICING_PURCHASE_QUESTIONS: Question[] = [
  {
    id       : 1001,
    component: RadioField,
    name     : FieldNames.purpose,
    options  : PURPOSE_OPTIONS,
    title    : 'What can we help you with?',
  },
  {
    id         : 1002,
    component  : PurchaseDownPayment,
    hideTitle  : true,
    label      : 'Down Payment Total',
    name       : FieldNames.loanAmount,
  },
  {
    id: 1003,
    ...SHARED[FieldNames.propertyType],
    component: Select,
  },
  {
    id: 1004,
    ...SHARED[FieldNames.propertyUsage],
    component: Select,
  },
  {
    id        : 1005,
    component : SingleFutureDate,
    label     : 'Expected Closing Date',
    name      : FieldNames.expectedClosingDate,
    isOptional: true,
  },
  {
    id       : 1006,
    component: RadioField,
    name     : FieldNames.homeSoldYN,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Do you plan to sell your current home before buying a new one?',
  },
  {
    id: 1007,
    ...SHARED[FieldNames.creditScore],
    title: 'What is your estimated credit score?',
  },
  {
    id: 1008,
    ...SHARED[FieldNames.escrowUsage],
    title: 'Will you have an escrow?',
  },
  {
    id       : 1009,
    component: RadioField,
    name     : FieldNames.prequalAmountYN,
    options  : YES_OR_NO_OPTIONS,
    showIf   : [noChosenHome],
    title    : 'Do you know the amount you\'d like to pre-qualify for?',
  },
  {
    id       : 1011,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.propertyZip,
  },
  {
    id       : 1012,
    component: ServicingAccountSummary,
    name     : FieldNames.accountSummary,
  },
  {
    id       : 1013,
    component: VerifyBankAccounts,
    name     : FieldNames.assets,
  },
  {
    /* Used only in recalculate */
    id       : 1088,
    component: Currency,
    label    : 'Home Value',
    name     : FieldNames.homeValue,
    validator: {
      type : 'isGreaterThanField',
      param: FieldNames.downDollar,
    },
  },
];

/* Applied to the recalculate questions */
const withSelect = {
  component    : Select,
  hideTitle    : true,
  isSummaryPage: true,
};

/* Since purchase price and down payment are a
  single component */
const servicingDownPaymentOptions = {
  hideTitle    : true,
  hideHomeValue: true,
};

/**
 * List of purchase questions used to recalulate rates
 * in the order they should be displayed
 */
export const servicingPurchaseRecalculateQuestions: Question[] = [
  // Purchase Price
  servicingPurchaseQuestionById(1088),
  // Down Payment
  Object.assign({}, servicingPurchaseQuestionById(1002), servicingDownPaymentOptions),
  // Zip Code
  servicingPurchaseQuestionById(1011),
  // Property Use
  Object.assign({}, servicingPurchaseQuestionById(1004), withSelect),
  // Property Type
  Object.assign({}, servicingPurchaseQuestionById(1003), withSelect),
  // Credit Score
  Object.assign({}, servicingPurchaseQuestionById(1007), withSelect),
  // Escrows and Insurance
  Object.assign({}, servicingPurchaseQuestionById(1008), withSelect),
];
