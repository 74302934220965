import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { matchPath } from 'react-router';
import { routes } from 'app/routes/route-list';
import { BranchPage } from 'app/components/Pages/Branch/BranchPage';
import { reduxForm } from 'redux-form';
import { createStyles, withStyles, Theme } from '@material-ui/core';
import * as FormActions from 'app/actions/form/actions';
import { isLocalhost } from 'app/api/helpers';

class BranchContainer extends React.Component<any, any> {

  componentDidUpdate() {
    if (!isLocalhost) { // remove this to re-enable branch landing page feature
      this.props.pushRouteDisabledNotification();
    }
  }

  componentDidMount() {
    i18next.loadLanguages(['en-US', 'es-US']);
  }

  render() {
    if (!isLocalhost) {
      return null;
    }

    const {
      branchLandingImage,
      branchMortgageImage,
      branchHomeEquityImage,
      classes,
      clientName,
      setLocale,
    } = this.props;

    const pageProps = {
      /* Select language */
      [routes.branch]: {
        heroImageTitle: (
          <span>
            Welcome to the{' '}
            <span className={classes.mobileLineBreak}>{clientName} Lending Center</span>
          </span>
        ),
        heroImageSrc  : branchLandingImage,
        paragraph     : 'Please select your language',
        buttons       : [
          { label: 'English', url: routes.branchLendingOption, handleClick: () => setLocale('en-US') },
          { label: 'Español', url: routes.branchLendingOption, handleClick: () => setLocale('es-US') },
          { label: 'French (unsupported)', url: routes.branchLendingOption, handleClick: () => setLocale('fr-FR') },
        ],
        showFullScreenImage: true,
      },
      /* Choose lending option */
      [routes.branchLendingOption]: {
        heroImageTitle: (
          <span>
            Welcome to the{' '}
            <span className={classes.mobileLineBreak}>{clientName} Lending Center</span>
          </span>
        ),
        heroImageSrc  : branchLandingImage,
        paragraph     : 'Choose which lending option is right for you',
        buttons       : [
          { label: 'Mortgage Loan',         url: routes.branchMortgage      },
          { label: 'Home Equity Solutions', url: routes.branchHomeEquity    },
          { label: 'Consumer Loans',        url: routes.branchConsumer      },
          { label: 'Credit Card',           url: routes.creditCard          },
          { label: 'Reverse Mortgage',      url: routes.reverse             },
          { label: 'Commercial Loan',       url: routes.branchCommercial    },
          { label: 'Small Business',        url: routes.branchSmallBusiness },
        ],
        showFullScreenImage: true,
      },
      /* Mortgage Options */
      [routes.branchMortgage]: {
        heroImageTitle: 'Mortgage Options',
        heroImageSrc  : branchMortgageImage,
        paragraph:
          'Your online resource to check current rates, get pre-qualified, or start your application',
        buttons: [
          {
            label: [
              'Get Purchase Rates',
              <span key={0} className={classes.buttonLineBreak}>
                  (~1 minute)
              </span>,
            ],
            url           : routes.purchase,
          },
          {
            label: [
              'Get Refinance Rates',
              <span key={1} className={classes.buttonLineBreak}>
                  (~1 minute)
              </span>,
            ],
            url           : routes.refinance,
          },
          {
            label: [
              'Get Pre-Qualified',
              <span key={2} className={classes.buttonLineBreak}>
                  (~5 minutes)
              </span>,
            ],
            url           : routes.autoPrequal,
          },
          {
            label: [
              'Apply Now',
              <span key={3} className={classes.buttonLineBreak}>
                  (~8 minutes)
              </span>,
            ],
            url           : routes.application,
          },
          {
            label: ['Construction Loan'],
            url: routes.applicationConstruction,
          },
          {
            label: ['Land Loan'],
            url: routes.applicationLand,
          },
        ],
        showContactLO   : false,
        showAssistedForm: false,
      },
      /* Home Equity Solutions */
      [routes.branchHomeEquity]: {
        heroImageTitle: 'Home Equity Solutions',
        heroImageSrc  : branchHomeEquityImage,
        paragraph:
          'Your online resource to get a borrow amount, check payments, or apply for a home equity loan or HELOC',
        buttons: [
          { label: 'How Much Can I Borrow?', url: routes.homeEquity },
          { label: 'Get Home Equity Rates', url: routes.homeEquity },
          { label: 'Apply Now', url: routes.applicationHomeEquity },
          { label: 'Home Equity Turbo', url: routes.homeEquityTurbo },
        ],
        showContactLO   : true,
        showAssistedForm: true,
      },
      /* Consumer Loans */
      [routes.branchConsumer]: {
        heroImageTitle: 'Consumer Loan Solutions',
        heroImageSrc  : branchLandingImage,
        paragraph:
          'Choose from one of these Consumer Loan options',
        buttons: [
          { label: 'Auto Loans', url: routes.autoLoan },
          { label: 'Other Vehicle Loans', url: routes.otherVehicle },
          { label: 'Personal Loan', url: routes.personalLoan },
          { label: 'Credit Booster Loan', url: routes.creditBooster },
          { label: 'Overdraft Protection', url: routes.overdraftProtection },
        ],
        showContactLO   : false,
        showAssistedForm: false,
      },
      /* Commercial Loans */
      [routes.branchCommercial]: {
        heroImageTitle: 'Commercial Loan Solutions',
        heroImageSrc  : branchLandingImage,
        paragraph:
          'Choose from one of these Commercial Loan options',
        buttons: [
          { label: 'Bridge Loan',         url: routes.commercialBridgeLoan },
          { label: 'Equipment',           url: routes.commercialEquipment  },
          { label: 'Purchase',            url: routes.commercialPurchase   },
          { label: 'Refinance',           url: routes.commercialRefinance  },
          { label: 'Vehicle',             url: routes.commercialVehicle    },
          { label: 'Renew',               url: routes.commercialRenew      },
          { label: 'Financial Statement', url: routes.financialStatement   },
        ],
        showContactLO   : false,
        showAssistedForm: false,
      },
      [routes.branchSmallBusiness]: {
        heroImageTitle: 'Small Business Loan Solutions',
        heroImageSrc  : branchLandingImage,
        paragraph:
          'Choose from one of these Small Business Loan options',
        buttons: [
          { label: 'Line Of Credit', url: routes.sbLineOfCredit },
          { label: 'Term',           url: routes.sbTerm         },
        ],
        showContactLO   : false,
        showAssistedForm: false,
      },
    };

    /**
     * Iterate over the key names in pageProps (route paths) and match
     * for the route we're on
     */
    const { pathname } = this.props.location;
    let branchPageProps;
    for (const path of Object.keys(pageProps)) {
      const isMatch = matchPath(pathname, { path: path, exact: true });
      if (isMatch) {
        branchPageProps = pageProps[isMatch.path];
        break;
      }
    }
    return branchPageProps ? <BranchPage {...branchPageProps} /> : null;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    lineBreak: {
      display: 'block',
    },
    mobileLineBreak: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    buttonLineBreak: {
      display   : 'block',
      fontSize  : '16px',
      fontWeight: 300,
    },
  });

const mapStateToProps = (state: any) => ({
  branchHomeEquityImage: state.config.branchHomeEquityImage,
  branchLandingImage   : state.config.branchLandingImage,
  branchMortgageImage  : state.config.branchMortgageImage,
  clientName           : state.config.clientDisplayName,
  host                 : state.config.host,
  router               : state.router,
});

const mapDispatchToProps = FormActions;

export default compose(
  reduxForm({
    form             : 'assisted',
    destroyOnUnmount : true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(styles)(BranchContainer));
