import { servicingRefinanceQuestionsByName } from 'app/models/questions/servicing-refinance';
import { Confirmation } from 'app/components/Confirmation';
import { LastStep } from 'app/components/LastStep';
import * as FormActions from 'app/actions/form/actions';
import { Section } from 'app/models/types';
import { FormParagraphs } from 'app/models/paragraphs';
import { FieldNames } from 'app/models/fields/names';
import { getLongFormDeclarationQuestions } from 'app/models/questions/declarations';
import { BorrowerType } from 'app/models/options/enums';
import { hasCoBorrower, schedulerEnabled } from 'app/models/fields/conditionals';

export const getServicingRefinanceSectionById = (id: number): Section => {
  return SERVICING_REFINANCE_SECTIONS.find((section) => section.id === id) as Section;
};

const SERVICING_REFINANCE_SECTIONS = [
  {
    id       : 101,
    questions: servicingRefinanceQuestionsByName([
      FieldNames.loanPurpose,
    ]),
    hideBackButton: true,
  },
  {
    // @TODO: Add verify address field once component is made
    id       : 102,
    title    : 'Is this the address of the property being refinanced?',
    questions: servicingRefinanceQuestionsByName([
      FieldNames.verifyAddress,
    ]),
  },
  {
    id       : 103,
    questions: servicingRefinanceQuestionsByName([
      FieldNames.homeValue,
      FieldNames.loanAmount,
    ]),
  },
  {
    id       : 104,
    questions: servicingRefinanceQuestionsByName([
      FieldNames.creditScore,
      FieldNames.escrowUsage,
    ]),
    submitAction: FormActions.ajaxGetServiceBookRates,
  },
  {
    id       : 105,
    title    : 'Account Information',
    paragraph: FormParagraphs.servicingVerifyAccount,
    questions: servicingRefinanceQuestionsByName([
      FieldNames.accountSummary,
    ]),
  },
  {
    id       : 106,
    title    : 'Tell us about your assets',
    paragraph: FormParagraphs.servicingAssets,
    subtitle : 'Asset accounts on record',
    questions: servicingRefinanceQuestionsByName([
      FieldNames.assets,
    ]),
  },
  {
    id           : 107,
    title        : 'Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.PRIMARY),
    submitAction : FormActions.handleServiceBookSubmit,
    submitIf     : [(values) => !hasCoBorrower(values)],
    submitText   : 'Submit',
  },
  {
    id           : 108,
    title        : 'Co-Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.COBORROWER),
    submitAction : FormActions.handleServiceBookSubmit,
    showIf       : [hasCoBorrower],
    submitText   : 'Submit',
  },
  {
    id             : 109,
    ifNoQuestions  : LastStep,
    clearWrapper   : true,
    hideProgressBar: true,
    // Conditional is redundant since the component is skipping the section itself,
    // but it is being used here for clarity.
    showIf         : [schedulerEnabled],
  },
  {
    id             : 110,
    ifNoQuestions  : Confirmation,
    hideProgressBar: true,
  },
];
