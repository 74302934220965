import { FormName } from 'app/models/options/enums';
import { ConfigProps } from 'redux-form';

type ReduxFormWizardProps = {
  formName       : FormName,
  initialValues? : any,
}

/**
 * This builds a redux form with the props that are typically used for a "wizard" form
 * where it spans many pages.  `destroyOnUnmount` is false because we keep the form
 * around from page to page of the wizard.  `forceUnregisterOnUnmount` is true for
 * the same reason.
 *
 * Adding `shouldError` to get around an issue with syncError not updating when we dynamically
 * add/remove fields.  Such as when toggling Yes/No to the "Is there a co-borrrower?" question
 * and a few others.
 *
 * https://github.com/redux-form/redux-form/issues/2971
 *
 * @param {ReduxFormWizardProps} props
 * @returns {ConfigProps}
 */
export const buildWizardConfigProps = ({ ...props }: ReduxFormWizardProps): ConfigProps => ({
  form                     : props.formName,
  initialValues            : props.initialValues,
  destroyOnUnmount         : false,
  forceUnregisterOnUnmount : true,
  shouldError              : () => true,
});
