import { FieldNames } from 'app/models/fields/names';
import { BUYING_PROCESS_OPTIONS } from 'app/models/options/options';
import { PurchaseDownPayment } from 'app/components/FormFields/PurchaseDownPayment';
import { Select } from 'app/components/FormFields/Select';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Currency } from 'app/components/FormFields/Currency';
import { SHARED } from 'app/models/questions/shared';
import { Question } from 'app/models/types';
import { CreditScoresSelect } from 'app/components/FormFields/CreditScoresSelect';

/**
 * Takes in a field name and returns the corresponding purchase
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const purchaseQuestionByName = (name: string): Question => {
  return PURCHASE_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in a question id and returns the corresponding purchase
 * question
 *
 * @param {number} id Question id
 * @returns Question with matching id
 */
export const purchaseQuestionById = (id: number): Question => {
  return PURCHASE_QUESTIONS.find((question) => {
    return question.id === id;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const purchaseQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(purchaseQuestionByName(name));
  }
  return questions;
};

export const PURCHASE_QUESTIONS: Question[] = [
  {
    id       : 1001,
    component: RadioField,
    name     : FieldNames.buyingProcess,
    options  : BUYING_PROCESS_OPTIONS,
    title    : 'Where are you in the buying process?',
  },
  {
    id: 1002,
    ...SHARED[FieldNames.propertyUsage],
    hideLabel: true,
    title    : 'How will this property be used?',
  },
  {
    id: 1003,
    ...SHARED[FieldNames.propertyType],
    hideLabel : true,
    title     : 'What type of property are you purchasing?',
  },
  {
    id: 1004,
    ...SHARED[FieldNames.creditScore],
    title: 'What is your estimated credit score?',
  },
  {
    id: 1005,
    ...SHARED[FieldNames.escrowUsage],
    title: 'Will you have an escrow for taxes and insurance?',
  },
  {
    id       : 1006,
    component: PurchaseDownPayment,
    name     : FieldNames.loanAmount,
  },
  {
    id: 1007,
    ...SHARED[FieldNames.propertyZip],
    title: 'In which zip code will this property be located?',
  },
  {
    id: 1008,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1009,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1010,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1011,
    ...SHARED[FieldNames.phone],
  },
  {
    /* Used only in recalculate */
    id       : 1012,
    component: Currency,
    label    : 'Home Value',
    name     : FieldNames.homeValue,
    validator: {
      type : 'isGreaterThanField',
      param: FieldNames.downDollar,
    },
  },
];

/* Applied to the recalculate questions */
const withSelect = {
  component    : Select,
  hideTitle    : true,
  isSummaryPage: true,
};

const withCreditScoresSelect = {
  component     : CreditScoresSelect,
  hideTitle     : true,
  isSummaryPage : true,
};

/* Since purchase price and down payment are a
  single component */
const purchaseDownPaymentOptions = {
  hideTitle    : true,
  hideHomeValue: true,
};

/**
 * List of questions used to recalulate rates
 * in the order they should be displayed
 */
export const purchaseRecalculateQuestions: Question[] = [
  // Purchase Price
  purchaseQuestionById(1012),
  // Down Payment
  Object.assign({}, purchaseQuestionById(1006), purchaseDownPaymentOptions),
  // Zip Code
  purchaseQuestionById(1007),
  // Property Use
  Object.assign({}, purchaseQuestionById(1002), withSelect),
  // Property Type
  Object.assign({}, purchaseQuestionById(1003), withSelect),
  // Credit Score
  Object.assign({}, purchaseQuestionById(1004), withCreditScoresSelect),
  // Escrow Usage
  Object.assign({}, purchaseQuestionById(1005), withSelect),
];
