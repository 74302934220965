import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { SHARED } from 'app/models/questions/shared';
import { Address } from 'app/components/FormFields/Address';
import { State } from 'app/components/FormFields/State';
import { Currency } from 'app/components/FormFields/Currency';
import { LIVING_SITUATION_OPTIONS, PROPERTY_TYPE_OPTIONS, REAL_ESTATE_LIABILITY_USAGE, REFINANCE_REASON_OPTIONS, YES_OR_NO_OPTIONS } from 'app/models/options/options';
import { RadioField } from 'app/components/FormFields/RadioField';
import { PropertyUsage } from 'app/models/options/enums';
import { hasCoBorrower, coBorrowerHasDifferentAddress, hasRealEstateLiabilities, isTakingCashOut } from 'app/models/fields/conditionals';
import { Name } from 'app/components/FormFields/Name';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { LoanOfficerLanding } from 'app/components/LoanOfficerLanding';
import { RealEstateAccountsUsage } from 'app/components/FormFieldArrays/RealEstateAccountsUsage';
import { FinicityConnect } from 'app/components/Express/FinicityConnect';
import { CollectRequiredSsn } from 'app/components/FormFields/Ssn/CollectRequiredSsn';

/**
 * Takes in a field name and returns the corresponding auto loan
 * question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const ExpressQuestionByName = (name: string): Question => {
  return EXPRESS_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns {Question[]} Questions matching field names
 */
export const ExpressQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(ExpressQuestionByName(name));
  }
  return questions;
};

export const ExpressQuestionById = (id: number): Question => {
  return EXPRESS_QUESTIONS.find((question) => question.id === id) as Question;
};

export const EXPRESS_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1007,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1008,
    ...SHARED[FieldNames.citizenshipType],
  },
  {
    id: 1009,
    ...SHARED[FieldNames.maritalStatus],
  },
  {
    id        : 1010,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id         : 1011,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id        : 1012,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPropertyCity,
  },
  {
    id        : 1013,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPropertyState,
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id   : 1014,
    name : FieldNames.livingPropertyZip,
  },
  {
    id        : 1015,
    component : Currency,
    label     : 'Annual Taxes',
    name      : FieldNames.yearTaxes,
    title     : 'What are your current taxes and insurance?',
  },
  {
    id        : 1016,
    component : Currency,
    label     : 'Annual Insurance',
    name      : FieldNames.yearInsure,
  },
  {
    id         : 1017,
    component  : Currency,
    isOptional : true,
    label      : 'Annual Association Fees',
    name       : FieldNames.yearAssociate,
  },
  {
    ...SHARED[FieldNames.escrowUsage],
    id    : 1018,
    name  : FieldNames.livingEscrowUsage,
    title : 'Do you have an escrow for taxes and insurance?',
  },
  {
    ...SHARED[FieldNames.escrowUsage],
    id      : 1019,
    options : YES_OR_NO_OPTIONS,
    title   : 'Will you have an escrow for taxes and insurance?',
  },
  {
    ...SHARED[FieldNames.propertyUsage],
    id           : 1020,
    component    : RadioField,
    title        : 'Is this the home you will be refinancing?',
    label        : '',
    options      :  [
      { title: 'Yes', value: PropertyUsage.PRIMARY },
      { title: 'No', value: PropertyUsage.UNKNOWN },
    ],
  },
  {
    id        : 1021,
    component : RadioField,
    name      : FieldNames.coBorrowerYN,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Will you have a co-borrower?',
  },
  {
    ...SHARED[FieldNames.firstName],
    id     : 1022,
    title  : 'Tell us about the co-borrower',
    label  : 'Co-Borrower\'s First Name',
    name   : FieldNames.coBorrowerFirstName,
    showIf : [hasCoBorrower],
  },
  {
    id         : 1023,
    component  : Name,
    label      : 'Co-Borrower\'s Middle Name',
    name       : FieldNames.coBorrowerMiddleName,
    isOptional : true,
    showIf     : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.lastName],
    id     : 1024,
    label  : 'Co-Borrower\'s Last Name',
    name   : FieldNames.coBorrowerLastName,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.coBorrowerSuffixName],
    id     : 1025,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.dateOfBirth],
    id     : 1026,
    label  : 'Co-Borrower\'s Date of Birth',
    name   : FieldNames.coBorrowerDOB,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.email],
    id     : 1027,
    name   : FieldNames.coBorrowerEmail,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.phone],
    id     : 1028,
    name   : FieldNames.coBorrowerPhone,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.coBorrowerCitizenshipType],
    id     : 1029,
    showIf : [hasCoBorrower],
  },
  {
    id        : 1030,
    component : RadioField,
    name      : FieldNames.coBorrowerOnTitle,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Will they be on the title?',
    showIf    : [hasCoBorrower],
  },
  {
    id        : 1031,
    component : RadioField,
    name      : FieldNames.coBorrowerSameAddress,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Same address as borrower?',
    showIf    : [hasCoBorrower],
  },
  {
    id        : 1032,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerLivingPropertyStreet,
    title     : `Co-borrower's current address?`,
    showIf    : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet,
    },
  },
  {
    id         : 1033,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerLivingPropertyStreet2,
    showIf     : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet2,
    },
  },
  {
    id        : 1034,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerLivingPropertyCity,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1035,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerLivingPropertyState,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1036,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.coBorrowerLivingPropertyZip,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1037,
    component : RadioField,
    name      : FieldNames.coBorrowerLivingRentOrOwn,
    options   : LIVING_SITUATION_OPTIONS,
    title     : `Co-borrower's current housing situation?`,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    ...SHARED[FieldNames.maritalStatus],
    id     : 1038,
    name   : FieldNames.coBorrowerMaritalStatus,
    title  : `Co-borrower's marital status?`,
    showIf : [hasCoBorrower],
  },
  {
    id        : 1039,
    component : LoanOfficerLanding,
    name      : FieldNames.loanOfficer,
  },
  {
    id        : 1041,
    component : RealEstateAccountsUsage,
    name      : FieldNames.realEstateLiabilities,
    options   : REAL_ESTATE_LIABILITY_USAGE,
  },
  {
    id        : 1042,
    component : RadioField,
    name      : FieldNames.isOwningHomeOutright,
    title     : 'Do you own any homes outright (no mortgage)?',
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id        : 1043,
    component : RadioField,
    name      : FieldNames.hasMadeMostRecentMortgagePayment,
    title     : 'Have you made your most recent mortgage payment?',
    options   : YES_OR_NO_OPTIONS,
    showIf    : [hasRealEstateLiabilities],
  },
  {
    id        : 1044,
    component : RadioField,
    name      : FieldNames.hasOtherTaxBillsDue,
    title     : 'Do you have any tax bills due on your primary residence?',
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id        : 1045,
    component : RadioField,
    name      : FieldNames.loanPurpose,
    options   : REFINANCE_REASON_OPTIONS,
    title     : 'What is the purpose of your refinance?',
  },
  {
    id        : 1046,
    component : Currency,
    name      : FieldNames.cashOut,
    label     : 'Amount',
    title     : 'How much cash out would you like?',
    showIf    : [isTakingCashOut],
  },
  {
    ...SHARED[FieldNames.propertyType],
    id           : 1047,
    component    : RadioField,
    options      : PROPERTY_TYPE_OPTIONS,
    title        : 'What type of property are you refinancing?',
    hideLabel    : true,
  },
  {
    id        : 1048,
    component : Currency,
    label     : 'Home Value',
    name      : FieldNames.homeValue,
    title     : 'What is the estimated home value?',
  },
  {
    id        : 1049,
    name      : FieldNames.canVerifyOfIncomeEmployment,
    component : RadioField,
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id        : 1050,
    component : FinicityConnect,
    name      : FieldNames.isFinancialVerificationComplete,
  },
  {
    id        : 1051,
    component : CollectRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
];
