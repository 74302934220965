import { Step } from 'app/models/types';
import {
  LAND_GET_PERSONAL_INFORMATION,
  LAND_GET_GENERAL_INFO,
  LAND_GET_VALUE,
  LAND_GET_LOCATION,
  LAND_GET_AVAILABLE_FUNDS,
  LAND_DOWNPAYMENT_INFO,
  LAND_GET_FICO_MARRIAGE_AND_DEPENDENTS,
  LAND_GET_CITIZEN_AND_MILITARY,
  LAND_GET_COBORROWER_PERSONAL_INFORMATION,
  LAND_GET_COBORROWER_HOUSING_INFORMATION,
  LAND_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION,
  LAND_GET_CURRENT_HOUSING_SITUATION,
  LAND_GET_SELL_CURRENT_HOUSE,
  LAND_GET_HOUSING_OWNER_INFORMATION,
  LAND_GET_HOUSING_RENTER_INFORMATION,
  LAND_GET_HOUSING_FREE_INFORMATION,
  LAND_GET_ADDITIONAL_PROPERTIES,
  LAND_GET_BORROWER_INCOME_SOURCE,
  LAND_GET_BORROWER_INCOME_EMPLOYED_OR_SELF,
  LAND_GET_BORROWER_INCOME_RETIRED_OR_OTHER,
  LAND_GET_COBORROWER_INCOME_SOURCE,
  LAND_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF,
  LAND_GET_COBORROWER_INCOME_RETIRED_OR_OTHER,
  LAND_GET_BORROWER_QUESTIONS,
  LAND_GET_COBORROWER_QUESTIONS,
  LAND_GET_BORROWER_SSN,
  LAND_LAST_STEP,
  LAND_CONFIRMATION,
} from '../sections/constants';

export const APPLICATION_LAND_STEPS: Step[] = [
  {
    index   : 0,
    name    : 'New Purchase',
    sections: [
      LAND_GET_PERSONAL_INFORMATION,
      LAND_GET_GENERAL_INFO,
      LAND_GET_VALUE,
      LAND_GET_LOCATION,
      LAND_GET_AVAILABLE_FUNDS,
      LAND_DOWNPAYMENT_INFO,

    ],
  },
  {
    index   : 1,
    name    : 'Personal Information',
    sections: [
      LAND_GET_FICO_MARRIAGE_AND_DEPENDENTS,
      LAND_GET_CITIZEN_AND_MILITARY,
      LAND_GET_COBORROWER_PERSONAL_INFORMATION,
      LAND_GET_COBORROWER_HOUSING_INFORMATION,
      LAND_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION,
    ],
  },
  {
    index   : 2,
    name    : 'Current Home',
    sections: [
      LAND_GET_CURRENT_HOUSING_SITUATION,
      LAND_GET_SELL_CURRENT_HOUSE,
      LAND_GET_HOUSING_OWNER_INFORMATION,
      LAND_GET_HOUSING_RENTER_INFORMATION,
      LAND_GET_HOUSING_FREE_INFORMATION,
      LAND_GET_ADDITIONAL_PROPERTIES,
    ],
  },
  {
    index   : 3,
    name    : 'Income and Employment',
    sections: [
      LAND_GET_BORROWER_INCOME_SOURCE,
      LAND_GET_BORROWER_INCOME_EMPLOYED_OR_SELF,
      LAND_GET_BORROWER_INCOME_RETIRED_OR_OTHER,
      LAND_GET_COBORROWER_INCOME_SOURCE,
      LAND_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF,
      LAND_GET_COBORROWER_INCOME_RETIRED_OR_OTHER,
    ],
  },
  {
    index   : 4,
    name    : 'Government Questions',
    sections: [
      LAND_GET_BORROWER_QUESTIONS,
      LAND_GET_COBORROWER_QUESTIONS,
      LAND_GET_BORROWER_SSN,
      LAND_LAST_STEP,
      LAND_CONFIRMATION,
    ],
  },
];
