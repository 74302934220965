import React from 'react';
import { Stepper, StepButton, Step, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const getActiveStep = (activeSectionId, steps) => {
  return steps.find((step) => {
    return step.sections.indexOf(activeSectionId) > -1;
  });
};

export const ProgressBar = ({ steps, section, ...props }) => {
  const activeStep  = getActiveStep(section.id, steps);
  const { t }       = useTranslation();

  /* Step 1 of 5 */
  const getStepNamePrefix = () => {
    return t('global:activeStepPrefix', {
      active      : activeStep.index + 1,
      total       : steps.length,
      defaultValue: 'Step {{ active }} of {{ total }}',
    });
  };
  /* Step 1 of 5: New Purchase OR Step 1 of 5 */
  const renderStepName = () => {
    return activeStep.name
      ? `${getStepNamePrefix()}: ${t(`step.${activeStep.index}.name`, {
        defaultValue: `${activeStep.name}`,
      })}`
      : getStepNamePrefix();
  };

  return (
    <div>
      <div>
        <Typography variant="body2">{renderStepName()}</Typography>
      </div>
      <Stepper nonLinear activeStep={activeStep.index}>
        {steps.length &&
          steps.map((step, index) => {
            return (
              <Step
                completed = {index < activeStep.index}
                key       = {`${step}-${index}`}
              >
                <StepButton
                  disabled = {index >= activeStep.index + 1}
                  icon     = {<div />}
                  disableRipple
                />
              </Step>
            );
          })}
      </Stepper>
    </div>
  );
};
