import React from 'react';
import {
  reduxForm,
  getFormValues,
  getFormInitialValues,
  getFormSyncErrors,
} from 'redux-form';
import { connect } from 'react-redux';
import * as FormActions from 'app/actions/form/actions';
import { Section } from 'app/components/Section';
import { FormName } from 'app/models/options/enums';
import { getSectionFromLocation } from 'app/routes';
import { updateHiddenFields } from 'app/util/change-handler';
import { landLFInitialValues } from 'app/util/initial-values';
import { APPLICATION_LAND_STEPS } from 'app/models/steps/application-land';
import {
  isRouteEnabled,
  excludedOptionsSelector,
  excludedQuestionsSelector,
  ssnQuestionRequirementSelector,
} from 'app/reducers/app-config';
import { parseQueryStringForApplyRates } from 'app/reducers/rates';
import { buildWizardConfigProps } from 'app/util/redux-form';
import { APPLICATION_LAND_QUESTIONS } from '../models/questions/application-land';
import { Questions } from '../models/questions/Questions';

let LFLandComponent: any = ({ ...props }) => {
  const section = getSectionFromLocation(props.router.location);
  if (!props.isRouteEnabled) {
    props.pushRouteDisabledNotification();
    return null;
  }
  if (section) {
    return (
      <Section
        {...props}
        section = {section}
        steps   = {APPLICATION_LAND_STEPS}
      />
    );
  } else {
    return (<h1>Section not found</h1>);
  }
};

const mapStateToProps = (state: any) => ({
  config                 : state.config,
  host                   : state.config.host,
  initialValues          : getFormInitialValues(FormName.LAND)(state),
  name                   : state.config.clientDisplayName,
  referToLo              : state.referToLo,
  router                 : state.router,
  syncErrors             : getFormSyncErrors(FormName.LAND)(state),
  values                 : getFormValues(FormName.LAND)(state),
  isRouteEnabled         : isRouteEnabled(state),
  excludedQuestions      : excludedQuestionsSelector(state),
  excludedOptions        : excludedOptionsSelector(state),
  ssnQuestionRequirement : ssnQuestionRequirementSelector(state),
});

const mapDispatchToProps = FormActions;

LFLandComponent = connect(mapStateToProps, mapDispatchToProps)(LFLandComponent);

export default reduxForm({
  ...buildWizardConfigProps({
    formName      : FormName.LAND,
    initialValues : {
      ...landLFInitialValues,
      ...parseQueryStringForApplyRates(),
    },
  }),
  onChange : updateHiddenFields((value)=> new Questions(APPLICATION_LAND_QUESTIONS).getByName([value]), FormName.LAND),
})(LFLandComponent);
