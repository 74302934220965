import { YES_OR_NO_OPTIONS } from 'app/models/options/options';
import { SHARED } from 'app/models/questions/shared';
import { Currency } from 'app/components/FormFields/Currency';
import { IntegerAllowZero } from 'app/components/FormFields/IntegerAllowZero';
import { Select } from 'app/components/FormFields/Select';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { Address } from 'app/components/FormFields/Address';
import { TextInput } from 'app/components/FormFields/TextInput';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { State } from 'app/components/FormFields/State';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { hasCoBorrower } from 'app/models/fields/conditionals';

export const CONTACT_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.phone],
    displayFormat: 'phone',
  },
];

export const MARITAL_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.maritalStatus],
    component   : Select,
    label       : 'Martial Status',
    fieldPopover: true,
  },
  {
    id       : 1002,
    component: IntegerAllowZero,
    name     : FieldNames.numOfDependent,
    label    : 'Dependents',
  },
];

export const COBORROWER_QUESTIONS: Question[] = [
  {
    id       : 1001,
    component: Select,
    options  : YES_OR_NO_OPTIONS,
    name     : FieldNames.coBorrowerYN,
    label    : 'Co-Borrower on Loan',
  },
  {
    ...SHARED[FieldNames.firstName],
    id       : 1002,
    label    : 'Co-Borrower First Name',
    name     : FieldNames.coBorrowerFirstName,
    showIf   : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.middleName],
    id         : 1003,
    label      : 'Co-Borrower Middle Name',
    name       : FieldNames.coBorrowerMiddleName,
    isOptional : true,
    showIf     : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.lastName],
    id     : 1004,
    label  : 'Co-Borrower Last Name',
    name   : FieldNames.coBorrowerLastName,
    showIf : [hasCoBorrower],
  },
  {
    id           : 1005,
    component    : SinglePastDate,
    label        : 'Co-Borrower Date of Birth',
    name         : FieldNames.coBorrowerDOB,
    displayFormat: 'date',
    showIf       : [hasCoBorrower],
  },
  {
    id       : 1006,
    component: Select,
    name     : FieldNames.coBorrowerOnTitle,
    options  : YES_OR_NO_OPTIONS,
    label    : 'Co-Borrower on Title',
    showIf   : [hasCoBorrower],
  },
];

export const CURRENT_HOME_QUESTIONS: Question[] = [
  {
    id           : 1001,
    component    : Currency,
    label        : 'Property Tax',
    name         : FieldNames.livingYearTaxes,
    displayFormat: 'currency.0',
  },
  {
    id           : 1002,
    component    : Currency,
    label        : 'Insurance',
    name         : FieldNames.livingYearInsure,
    displayFormat: 'currency.0',
  },
  {
    id           : 1003,
    component    : Currency,
    label        : 'HOA Fees',
    name         : FieldNames.livingYearAssociate,
    isOptional   : true,
    displayFormat: 'currency.0',
  },
];

export const CURRENT_EMPLOYMENT_QUESTIONS: Question[] = [
  {
    id       : 1001,
    component: TextInput,
    label    : 'Company Name',
    name     : FieldNames.employCompanyName,
  },
  {
    id       : 1002,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employStreet,
  },
  {
    id        : 1003,
    component : Address,
    isOptional: true,
    label     : 'Suite #',
    name      : FieldNames.employStreet2,
  },
  {
    id       : 1004,
    component: Address,
    label    : 'City',
    name     : FieldNames.employCity,
  },
  {
    id       : 1005,
    component: State,
    label    : 'State',
    name     : FieldNames.employState,
  },
  {
    id       : 1006,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.employZip,
  },
  {
    id        : 1007,
    component : TextInput,
    label     : 'Job Title',
    isOptional: true,
    name      : FieldNames.employTitle,
  },
  {
    id           : 1008,
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.employStart,
    isOptional   : true,
    displayFormat: 'date',
  },
];

export const CURRENT_INCOME_QUESTIONS: Question[] = [
  {
    id           : 1001,
    component    : Currency,
    label        : 'Monthly Base Pay',
    name         : FieldNames.employBase,
    displayFormat: 'currency.0',
  },
  {
    id           : 1002,
    component    : Currency,
    label        : 'Monthly Commission',
    name         : FieldNames.employCommission,
    displayFormat: 'currency.0',
    isOptional   : true,
  },
  {
    id           : 1003,
    component    : Currency,
    label        : 'Monthly Overtime',
    name         : FieldNames.employOvertime,
    displayFormat: 'currency.0',
    isOptional   : true,
  },
  {
    id           : 1004,
    component    : Currency,
    label        : 'Monthly Bonus',
    name         : FieldNames.employBonus,
    isOptional   : true,
    displayFormat: 'currency.0',
  },
];
