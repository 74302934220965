import { Step } from 'app/models/types';
import {
  SB_LINE_OF_CREDIT_LOAN_INFO,
  SB_LINE_OF_CREDIT_BUSINESS_INFO,
  SB_LINE_OF_CREDIT_OWNER_INFO,
  SB_LINE_OF_CREDIT_FINANCIAL_INFO,
  SB_LINE_OF_CREDIT_SSN,
} from 'app/models/sections/constants';

export const SB_LINE_OF_CREDIT_STEPS: Step[] = [
  {
    index   : 0,
    name    : 'Loan Details',
    sections: [
      SB_LINE_OF_CREDIT_LOAN_INFO,
    ],
  },
  {
    index   : 1,
    name    : 'Business Information',
    sections: [
      SB_LINE_OF_CREDIT_BUSINESS_INFO,
      SB_LINE_OF_CREDIT_OWNER_INFO,
      SB_LINE_OF_CREDIT_FINANCIAL_INFO,
    ],
  },
  {
    index   : 2,
    name    : 'Declarations',
    sections: [
      SB_LINE_OF_CREDIT_SSN,
    ],
  },
];
