import { SHARED } from 'app/models/questions/shared';
import { FieldNames } from 'app/models/fields/names';
import { Currency } from 'app/components/FormFields/Currency';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Select } from 'app/components/FormFields/Select';
import { Address } from 'app/components/FormFields/Address';
import { State } from 'app/components/FormFields/State';
import { REFINANCE_REASON_OPTIONS, YES_OR_NO_OPTIONS } from 'app/models/options/options';
import { VerifyBankAccounts } from 'app/components/FormFieldArrays/VerifyBankAccounts';
import { ServicingAccountSummary } from 'app/components/VerifiableSections/ServicingAccountSummary';
import { Question } from 'app/models/types';
import { VerifyAddress } from 'app/components/VerifiableSections/VerifyAddress';

/**
 * Takes in a field name and returns the corresponding refinance
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const servicingRefinanceQuestionByName = (name: string): Question => {
  return SERVICING_REFINANCE_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in a question id and returns the corresponding refinance
 * question
 *
 * @param {number} id Question id
 * @returns Question with matching id
 */
export const servicingRefinanceQuestionById = (id: number): Question => {
  return SERVICING_REFINANCE_QUESTIONS.find((question) => {
    return question.id === id;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const servicingRefinanceQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(servicingRefinanceQuestionByName(name));
  }
  return questions;
};

export const SERVICING_REFINANCE_QUESTIONS: Question[] = [
  {
    id        : 1001,
    component : RadioField,
    label     : 'Purpose of Refinance',
    hideLabel : true,
    name      : FieldNames.loanPurpose,
    options   : REFINANCE_REASON_OPTIONS,
    title     : 'What can we help you with?',
  },
  {
    id       : 1002,
    component: Address,
    label    : 'Street',
    name     : FieldNames.propertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id: 1003,
    component : Address,
    label     : 'Street 2',
    name      : FieldNames.propertyStreet2,
    isOptional: true,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id: 1004,
    component: Address,
    label    : 'City',
    name     : FieldNames.propertyCity,
  },
  {
    id: 1005,
    component: State,
    label    : 'State',
    name     : FieldNames.propertyState,
  },
  {
    id: 1006,
    ...SHARED[FieldNames.propertyZip],
  },
  {
    id: 1007,
    ...SHARED[FieldNames.propertyUsage],
    component: Select,
  },
  {
    id: 1008,
    ...SHARED[FieldNames.propertyType],
    component: Select,
  },
  {
    id: 1009,
    ...SHARED[FieldNames.escrowUsage],
    options: YES_OR_NO_OPTIONS,
    title  : 'Will you have an escrow with your new loan?',
  },
  {
    id       : 1010,
    component: Currency,
    label    : 'Home Value',
    name     : FieldNames.homeValue,
    title    : 'Estimated property value',
    validator: {
      type : 'isGreaterThanField',
      param: FieldNames.loanAmount,
    },
  },
  {
    id       : 1011,
    component: Currency,
    label    : 'Loan Amount',
    name     : FieldNames.loanAmount,
    title    : 'Loan amount you would like to refinance',
    validator: {
      type : 'loanAmountIsValid',
      param: FieldNames.homeValue,
    },
  },
  {
    id: 1012,
    ...SHARED[FieldNames.creditScore],
    title: 'What is your estimated credit score?',
  },
  {
    id       : 1013,
    component: VerifyAddress,
    name     : FieldNames.verifyAddress,
  },
  {
    id       : 1014,
    component: ServicingAccountSummary,
    name     : FieldNames.accountSummary,
  },
  {
    id       : 1015,
    component: VerifyBankAccounts,
    name     : FieldNames.assets,
  },
];

/* Applied to the recalculate questions */
const withSelect = {
  component    : Select,
  hideTitle    : true,
  isSummaryPage: true,
};

/**
 * List of refinance questions used to recalulate rates
 * in the order they should be displayed
 */
export const servicingRefinanceRecalculateQuestions = [
  // Home Value
  servicingRefinanceQuestionById(1010),
  // Loan Amount
  servicingRefinanceQuestionById(1011),
  // Zip Code
  servicingRefinanceQuestionById(1006),
  // Property Use
  Object.assign({}, servicingRefinanceQuestionById(1007), withSelect),
  // Property Type
  Object.assign({}, servicingRefinanceQuestionById(1008), withSelect),
  // Credit Score
  Object.assign({}, servicingRefinanceQuestionById(1012), withSelect),
  // Escrows and Insurance
  Object.assign({}, servicingRefinanceQuestionById(1009), withSelect),
  // Purpose of Refinance
  Object.assign({}, servicingRefinanceQuestionById(1001), withSelect),
];
