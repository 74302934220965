/*
 * We're exporting the list of routes from this file instead of
 * the index due to issues with testing
 */

export const routes = {
  application            : '/application/',
  applicationConstruction : '/application/construction/',
  applicationHomeEquity  : '/application/home-equity/',
  applicationLand        : '/application/land/',
  applicationPurchase    : '/application/purchase/',
  applicationRefinance   : '/application/refinance/',
  autoLoan               : '/auto-loan/',
  autoPrequal            : '/auto-prequal/',
  autoPrequalEdit        : '/auto-prequal/edit',
  autoPrequalRenew       : '/auto-prequal/renew/',
  branch                 : '/branch/',
  branchCommercial       : '/branch/commercial/',
  branchConsumer         : '/branch/consumer/',
  branchHomeEquity       : '/branch/home-equity/',
  branchLendingOption    : '/branch/lending-option/',
  branchMortgage         : '/branch/mortgage/',
  branchSmallBusiness    : '/branch/small-business/',
  broker                 : '/broker/',
  closingCostsPurchase   : '/closing-costs/purchase/',
  closingCostsRefinance  : '/closing-costs/refinance/',
  commercial             : '/commercial/',
  commercialBridgeLoan   : '/commercial/bridge-loan/',
  commercialEquipment    : '/commercial/equipment/',
  commercialPurchase     : '/commercial/purchase/',
  commercialRefinance    : '/commercial/refinance/',
  commercialRenew        : '/commercial/renew/',
  commercialVehicle      : '/commercial/vehicle/',
  contact                : '/contact/',
  creditBooster          : '/credit-booster/',
  creditCard             : '/credit-card/',
  employeeReferral       : '/*/referral/:loanOfficerUsername?',
  turbo                  : '/turbo/',
  financialStatement     : '/financial-statement/',
  homeEquity             : '/home-equity/',
  homeEquityTurbo        : '/home-equity-turbo/',
  insuranceQuote         : '/insurance-quote',
  otherVehicle           : '/ov-loan/',
  overdraftProtection    : '/overdraft-protection/',
  personalLoan           : '/personal-loan/',
  purchase               : '/purchase/',
  refinance              : '/refinance/',
  reverse                : '/reverse/',
  resume                 : '/resume/:type?/:uuid?/:hash?',
  root                   : '/',
  sbLineOfCredit         : '/small-business/line-of-credit/',
  sbTerm                 : '/small-business/term/',
  scheduleAppointment    : '/schedule-appointment/',
  servicing              : '/servicing/',
  servicingPurchase      : '/servicing/purchase/',
  servicingRefinance     : '/servicing/refinance/',
};
