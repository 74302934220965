import * as React from 'react';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import * as FormActions from 'app/actions/form/actions';
import { Section } from 'app/components/Section';
import { FormName } from 'app/models/options/enums';
import { getSectionFromLocation } from 'app/routes';
import {
  excludedQuestionsSelector,
  excludedOptionsSelector,
  isRouteEnabled,
  ssnQuestionRequirementSelector,
} from 'app/reducers/app-config';
import { SB_LINE_OF_CREDIT_STEPS } from 'app/models/steps/sb-line-of-credit';
import { sbLineOfCreditInitialValues } from 'app/util/initial-values';
import { buildWizardConfigProps } from 'app/util/redux-form';

class SBLineOfCreditContainer extends React.Component<any, any> {

  componentDidUpdate() {
    if (!this.props.isRouteEnabled) {
      this.props.pushRouteDisabledNotification();
    }
  }

  render() {
    const section = getSectionFromLocation(this.props.router.location);
    if (!this.props.isRouteEnabled) {
      return null;
    }
    if (!section) {
      return (<h1>Section not found</h1>);
    }
    return <Section {...this.props} section={section} steps={SB_LINE_OF_CREDIT_STEPS} />;
  }
}

const mapStateToProps = (state) => ({
  excludedQuestions      : excludedQuestionsSelector(state),
  excludedOptions        : excludedOptionsSelector(state),
  referToLo              : state.referToLo,
  router                 : state.router,
  values                 : getFormValues(FormName.SBLINEOFCREDIT)(state),
  isRouteEnabled         : isRouteEnabled(state),
  ssnQuestionRequirement : ssnQuestionRequirementSelector(state),
});

const mapDispatchToProps = FormActions;

export default compose(
  reduxForm(
    buildWizardConfigProps({
      formName      : FormName.SBLINEOFCREDIT,
      initialValues : sbLineOfCreditInitialValues,
    }),
  ),
  connect(mapStateToProps, mapDispatchToProps),
)(SBLineOfCreditContainer);
