import moment from 'moment';
import { client } from 'app/api/_client';
import {
  createBaseUrlFromLocation,
  Endpoints,
  getLocale,
} from 'app/api/helpers';
import { FieldName, FieldNames } from 'app/models/fields/names';
import { getSlug, getProduct, PRODUCT } from 'app/util/headers';
import { getLoanOfficer } from 'app/reducers/loan-officer';

export interface PrequalLetterRequest {
  formValues      : {
    [key in FieldName]?: any;
  };
  loanOfficerId    : number;
  associateName?   : string;
  baseUrl          : string;
  formVersion      : string;
  slug             : string;
  product          : PRODUCT;
  tertiaryColor    : string;
  logoBase64       : string;
  locale           : string;
}

export interface PrequalLetterResponse {
  formValues       : any;
  formVersion      : string;
  prequalExpiration: Date;
  uuid             : string;
}

export const mapPrequalLetterRequest = (formData, state): PrequalLetterRequest => {
  const baseUrl          = createBaseUrlFromLocation('resume/prequal');
  const slug             = getSlug();
  const product          = getProduct();
  const tertiaryColor    = state.config.tertiaryColor;
  const logoBase64       = state.config.logoBase64;
  const loanOfficerId    = getLoanOfficer(state).id;
  const locale           = getLocale();

  return {
    formVersion: process.env.REACT_APP_VERSION as string,
    formValues : formData,
    baseUrl,
    slug,
    product,
    tertiaryColor,
    logoBase64,
    loanOfficerId,
    locale,
  };
};

export const postPrequalLetter = (body: PrequalLetterRequest) => {
  return client
    .post(Endpoints.GENERATE_PREQUAL_LETTER, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPrequalLetter = ({ uuid, hash }): Promise<PrequalLetterResponse> => {
  const slug = getSlug();
  return client
    .get(`${Endpoints.GET_PREQUAL_LETTER}?uuid=${uuid}&hash=${hash}&slug=${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Maps the prequal letter response to the values expected by the
 * auto prequal renew form
 *
 * @param {*} formValues
 */
export const mapPrequalLetterResponse = (formValues: any = {}) => {
  const keysToRemove = [
    FieldNames.apr,
    FieldNames.creditReportId,
    FieldNames.fee,
    FieldNames.creditScore,
    FieldNames.loanTerm,
    FieldNames.loanType,
    FieldNames.monthlyPayment,
    FieldNames.prequalAmount,
    FieldNames.prequalExpiration,
    FieldNames.prequalIssued,
    FieldNames.prequalLetterAmount,
    FieldNames.productType,
    FieldNames.rate,
    FieldNames.realEstateLiabilities,
    FieldNames.selectedRate,
    FieldNames.loanAmount,
  ];

  for (const key of keysToRemove) {
    delete formValues[key];
  }

  return formValues;
};

/**
 * Takes in a prequal expiration date and checks if the date
 * is before today
 *
 * @param {Date} prequalExpiration
 * @returns {boolean}
 */
export const isLetterExpired = (prequalExpiration: Date): boolean => {
  const today = moment();
  const expiration = moment(prequalExpiration).format();
  return moment(expiration).isBefore(today);
};
