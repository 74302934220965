import React from 'react';
import { Button } from 'app/components/Button';
import { ImageSection } from 'app/components/ImageSection';
import { Loader } from 'app/components/Loader';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes } from 'app/routes/route-list';
import { contactPhoneSelector, isSchedulerEnabled } from 'app/reducers/app-config';
import * as FormActions from 'app/actions/form/actions';
import { PartialSource } from 'app/models/options/enums';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { GeneralReferralCode } from '@lenderful/domain';

interface ReferToLoComponentProps extends WithTranslation {
  handleSavePartial: typeof FormActions.handleSavePartial;
  values           : object;
  code             : GeneralReferralCode;
  contactPhone     : string;
  stopGateImage    : string;
  stopGateVeteran  : string;
  isLoading        : boolean;
  schedulerEnabled : boolean;
}

const getStopGateImage = (code: GeneralReferralCode, stopGateImage: string, stopGateVeteran: string) => {
  if (code === GeneralReferralCode.VETERAN) {
    return stopGateVeteran;
  } else {
    /* Default stop gate image */
    return stopGateImage;
  }
};

class ReferToLOComponent extends React.Component<ReferToLoComponentProps, any> {

  componentDidMount() {
    const { handleSavePartial, values, code } = this.props;
    if (code !== GeneralReferralCode.NO_QUALIFIED_HELOC_RATES) { // no partial for heloc workflow
      handleSavePartial(values, PartialSource.REFERRED, code);
    }
  }

  render() {
    const {
      code,
      contactPhone,
      isLoading,
      schedulerEnabled,
      stopGateImage,
      stopGateVeteran,
      t,
    } = this.props;
    const imagePath = getStopGateImage(code, stopGateImage, stopGateVeteran);

    return (
      <ImageSection image={imagePath}>
        {isLoading ? <Loader /> : null}
        <Typography variant='h6' gutterBottom={true} classes={{ h6: 'contentTitle' }}>
          {t('stopGate.referToLoanOfficer.title', { defaultValue: 'Let us assist you' })}
        </Typography>
        <Typography variant='body2' gutterBottom={true} classes={{ root: 'paddingBottom' }}>
          <Trans i18nKey='stopGate.referToLoanOfficer.paragraph1'>
            We are going to refer you to a loan officer who will be able to discuss the rest of the process and go over your options.
          </Trans>
        </Typography>
        {schedulerEnabled && (
          <Button
            classes = {{ root: 'stackedButton', fullWidth: 'tabletSizeLarge' }}
            color   = 'primary'
            fullWidth
            component = {Link}
            to        = {routes.scheduleAppointment}
            size      = 'large'
            variant   = 'contained'
          >
            {t('stopGate.referToLoanOfficer.button.scheduleAppointment', {
              defaultValue: 'Schedule Appointment',
            })}
          </Button>
        )}
        <Button
          classes = {{ root: 'stackedButton', fullWidth: 'tabletSizeLarge' }}
          color   = 'primary'
          fullWidth
          href    = {`tel:+1${contactPhone}`}
          size    = 'large'
          variant = 'contained'
        >
          {t('stopGate.referToLoanOfficer.button.call', {
            defaultValue: 'Call us at {{ contactPhone, phone }}',
            contactPhone,
          })}
        </Button>
      </ImageSection>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  contactPhone    : contactPhoneSelector(state),
  code            : state.referToLo.code,
  isLoading       : state.referToLo.isLoading,
  stopGateImage   : state.config.stopGateImage,
  stopGateVeteran : state.config.stopGateVeteranImage,
  schedulerEnabled: isSchedulerEnabled(state),
  ...ownProps,
});

const mapDispatchToProps = FormActions;

export const ReferToLoanOfficer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ReferToLOComponent));
