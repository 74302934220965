import React from 'react';
import { FieldArray } from 'redux-form';
import { RadioField } from 'app/components/FormFields/RadioField';
import { FieldNames } from 'app/models/fields/names';
import { colors, createStyles, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeOptions, localizeLabel } from 'app/i18n/helpers';
import { LiabilityUsage } from 'app/models/options/enums';
import { isPrimaryHome } from 'app/models/fields/conditionals';

const styles = (theme) =>
  createStyles({
    accountWrapper: {
      marginBottom: 0,
      paddingTop: '16px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '26px',
        borderBottom: `1px solid ${colors.grey[500]}`,
        paddingTop: 0,
      },
    },
    fieldWrapper: {
      marginTop: '15px',
    },
  });

const renderRealEstateAccounts = ({ ...props }) => {
  const { classes, fields, name, values, options, t } = props;
  const liabilityAccounts = values[fields.name];

  // for secondary and investment homes, we display the option to choose "collateral home"
  const liabilityOptions = options
    .filter(option => !isPrimaryHome(values) || option.value !== LiabilityUsage.COLLATERAL_HOME);

  const renderFields = () =>
    fields.map((field, index) => {
      const account = liabilityAccounts[index];

      return (
        <div key={`${field}-${index}`} className={classes.accountWrapper}>
          <Typography variant='body1' classes={{ body1: 'arrayTitle' }}>
            {t('realEstateAccounts.liabilityName', {
              defaultValue : '{{ liabilityName }}',
              liabilityName: account.liabilityName,
            })}
          </Typography>
          <Typography variant='body2' classes={{ body2: 'arrayItem' }}>
            {localizeLabel(`${name}.${FieldNames.liabilityBalance}`, t, `Current Balance:`)}&nbsp;
            {t('realEstateAccounts.liabilityBalance', {
              defaultValue    : '{{ liabilityBalance, currency }}',
              liabilityBalance: account.liabilityBalance,
            })}
          </Typography>
          <Typography variant='body2' classes={{ body2: 'arrayItem' }}>
            {localizeLabel(`${name}.${FieldNames.liabilityAmount}`, t, `Monthly Payment:`)}&nbsp;
            {t('realEstateAccounts.liabilityAmount', {
              defaultValue   : '{{ liabilityAmount, currency }}',
              liabilityAmount: account.liabilityAmount,
            })}
          </Typography>
          <div className={classes.fieldWrapper}>
            <RadioField
              name     = {`${field}.${FieldNames.liabilityUsage}`}
              options  = {localizeOptions(name, t, liabilityOptions)}
            />
          </div>
        </div>
      );
    });

  const renderLiabilityParagraph = () => (
    <Typography variant='body2' gutterBottom={true}>
      {
        t(
          'realEstateAccounts.liability',
          { defaultValue: `We've identified these liabilities associated with properties that you and/or your co-borrower may own.` },
        )
      }
    </Typography>
  );

  return (
    <React.Fragment>
      { liabilityAccounts && liabilityAccounts.length > 0 ? renderLiabilityParagraph() : null }
      { renderFields() }
    </React.Fragment>
  );
};

export const HomeEquityReoLiabilities = withStyles(styles)(({ ...props }: any) => {
  const { classes, change, name, values, options } = props;
  const { t }                             = useTranslation();
  return (
    <FieldArray
      component = {renderRealEstateAccounts}
      name      = {name}
      props     = {{
        change,
        classes,
        name,
        values,
        options,
        t,
      }}
    />
  );
});
