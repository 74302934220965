/**
 * FieldNames used as the name prop in Redux-Form Fields
 */
export enum FieldNames {
  /* Verified and tagged */
  /* Unverified and untagged */
  accountBalance                           = 'accountBalance',
  accountFinancialInstitution              = 'accountFinancialInstitution',
  accountSummary                           = 'accountSummary',
  accountType                              = 'accountType',
  acquiredYear                             = 'acquiredYear',
  additionalIncome                         = 'additionalIncome',
  addlProperties                           = 'additionalProperties',
  age                                      = 'age',
  ageLimit                                 = 'ageLimit',
  agreementYN                              = 'purchaseAgreement',
  answeredQuestions                        = 'answeredQuestions',
  apr                                      = 'apr',
  assets                                   = 'assets',
  assetSummary                             = 'assetSummary',
  assetTotalValue                          = 'assetTotalValue',
  assistedYN                               = 'assistedYN',
  autoPurchasePlan                         = 'autoPurchasePlan',
  autoHighLimitPercent                     = 'autoHighLimitPercent',
  autoLoanMinLtvAction                     = 'autoLoanMinLtvAction',
  avmHomeValue                             = 'avmHomeValue',
  avmHomeValueHighLimit                    = 'avmHomeValueHighLimit',
  avmHomeValueLowLimit                     = 'avmHomeValueLowLimit',
  avmReport                                = 'avmReport',
  bankruptAge                              = 'bankruptAge',
  bankruptType                             = 'bankruptType',
  borrowedDownAmount                       = 'borrowedDownAmount',
  borrowerJobDurationAlgorithm             = 'borrowerJobDurationAlgorithm',
  borrowerJobDurationMonths                = 'borrowerJobDurationMonths',
  branchEmployee                           = 'branchEmployee',
  branchNumber                             = 'branchNumber',
  buildingContactName                      = 'buildingContactName',
  buildingContactPhone                     = 'buildingContactPhone',
  buildingSquareFootage                    = 'buildingSquareFootage',
  builtYear                                = 'builtYear',
  buyingProcess                            = 'buyingProcess',
  buildingUnits                            = 'buildingUnits',
  captcha                                  = 'captcha',
  cashOut                                  = 'cashOut',
  canVerifyOfIncomeEmployment              = 'canVerifyOfIncomeEmployment',
  canReceiveInsuranceQuote                 = 'canReceiveInsuranceQuote',
  changedIncomeYN                          = 'changedIncomeYN',
  citizenshipType                          = 'citizenshipType',
  coBorrowerAdditionalIncome               = 'coBorrowerAdditionalIncome',
  coBorrowerAge                            = 'coBorrowerAge',
  coBorrowerAssetTotalValue                = 'coBorrowerAssetTotalValue',
  coBorrowerChangedIncomeYN                = 'coBorrowerChangedIncomeYN',
  coBorrowerCitizenshipType                = 'coBorrowerCitizenshipType',
  coBorrowerDOB                            = 'coBorrowerDateOfBirth',
  coBorrowerEmail                          = 'coBorrowerEmail',
  coBorrowerEmployBase                     = 'coBorrowerEmployBase',
  coBorrowerEmployBonus                    = 'coBorrowerEmployBonus',
  coBorrowerEmployCity                     = 'coBorrowerEmployCity',
  coBorrowerEmployCommission               = 'coBorrowerEmployCommission',
  coBorrowerEmployCompanyName              = 'coBorrowerEmployCompanyName',
  coBorrowerEmploymentType                 = 'coBorrowerEmploymentType',
  coBorrowerEmployOvertime                 = 'coBorrowerEmployOvertime',
  coBorrowerEmployOwnershipShare           = 'coBorrowerEmployOwnershipShare',
  coBorrowerEmployPartyTransaction         = 'coBorrowerEmployPartyTransaction',
  coBorrowerEmployPhone                    = 'coBorrowerEmployPhone',
  coBorrowerEmployPrevCity                 = 'coBorrowerEmployPrevCity',
  coBorrowerEmployPrevEnd                  = 'coBorrowerEmployPrevEnd',
  coBorrowerEmployPrevName                 = 'coBorrowerEmployPrevName',
  coBorrowerEmployPrevStart                = 'coBorrowerEmployPrevStart',
  coBorrowerEmployPrevState                = 'coBorrowerEmployPrevState',
  coBorrowerEmployPrevStreet               = 'coBorrowerEmployPrevStreet',
  coBorrowerEmployPrevStreet2              = 'coBorrowerEmployPrevStreet2',
  coBorrowerEmployPrevTitle                = 'coBorrowerEmployPrevTitle',
  coBorrowerEmployPrevZip                  = 'coBorrowerEmployPrevZip',
  coBorrowerEmployStart                    = 'coBorrowerEmployStart',
  coBorrowerEmployState                    = 'coBorrowerEmployState',
  coBorrowerEmployStreet                   = 'coBorrowerEmployStreet',
  coBorrowerEmployStreet2                  = 'coBorrowerEmployStreet2',
  coBorrowerEmployTitle                    = 'coBorrowerEmployTitle',
  coBorrowerEmployZip                      = 'coBorrowerEmployZip',
  coBorrowerFirstName                      = 'coBorrowerFirstName',
  coBorrowerIncomeMonthly                  = 'coBorrowerIncomeMonthly',
  coBorrowerIncomeOnDeclineYN              = 'coBorrowerIncomeOnDecline',
  coBorrowerIncomeVerifiable               = 'coBorrowerIncomeVerifiable',
  coBorrowerLastName                       = 'coBorrowerLastName',
  coBorrowerLineOfWorkYears                = 'coBorrowerLineOfWorkYears',
  coBorrowerLineOfWorkMonths               = 'coBorrowerLineOfWorkMonths',
  coBorrowerLivingMonthlyRentAmount        = 'coBorrowerLivingMonthlyRentAmount',
  coBorrowerLivingPropertyCity             = 'coBorrowerLivingPropertyCity',
  coBorrowerLivingPropertyState            = 'coBorrowerLivingPropertyState',
  coBorrowerLivingPropertyStreet           = 'coBorrowerLivingPropertyStreet',
  coBorrowerLivingPropertyStreet2          = 'coBorrowerLivingPropertyStreet2',
  coBorrowerLivingPropertyZip              = 'coBorrowerLivingPropertyZip',
  coBorrowerLivingRentOrOwn                = 'coBorrowerLivingRentOrOwn',
  coBorrowerMaritalStatus                  = 'coBorrowerMarried',
  coBorrowerMiddleName                     = 'coBorrowerMiddleName',
  coBorrowerMilitaryExperience             = 'coBorrowerMilitaryExperience',
  coBorrowerOnTitle                        = 'hasCoBorrowerOnTitle',
  coBorrowerOtherIncome                    = 'coBorrowerOtherIncome',
  coBorrowerPhone                          = 'coBorrowerPhone',
  coBorrowerRealEstateTotalValue           = 'coBorrowerRealEstateTotalValue',
  coBorrowerRetirementIncome               = 'coBorrowerRetirementIncome',
  coBorrowerSameAddress                    = 'coBorrowerSameAddress',
  coBorrowerSsn                            = 'coBorrowerSsn',
  coBorrowerSuffixName                     = 'coBorrowerSuffixName',
  coBorrowerYN                             = 'hasCoBorrower',
  commercialBridgeOrCredit                 = 'commercialBridgeOrCredit',
  commercialBusinessAnnualInterestExpense  = 'commercialBusinessAnnualInterestExpense',
  commercialBusinessCashAndEquivalents     = 'commercialBusinessCashAndEquivalents',
  commercialBusinessCheckingAccountBalance = 'commercialBusinessCheckingAccountBalance',
  commercialBusinessEBITA                  = 'commercialBusinessEBITA',
  commercialBusinessName                   = 'commercialBusinessName',
  commercialBusinessNeed                   = 'commercialBusinessNeed',
  commercialBusinessNetWorth               = 'commercialBusinessNetWorth',
  commercialBusinessOwnershipType          = 'commercialBusinessOwnershipType',
  commercialBusinessTotalAssets            = 'commercialBusinessTotalAssets',
  commercialBusinessTotalLiabilities       = 'commercialBusinessTotalLiabilities',
  commercialBusinessTotalSales             = 'commercialBusinessTotalSales',
  commercialBusinessType                   = 'commercialBusinessType',
  commercialCensusTract                    = 'commercialCensusTract',
  commercialCity                           = 'commercialCity',
  commercialCollateralDescription          = 'commercialCollateralDescription',
  commercialCollateralList                 = 'commercialCollateralList',
  commercialCollateralValue                = 'commercialCollateralValue',
  commercialDBA                            = 'commercialDBA',
  commercialDescription                    = 'commercialDescription',
  commercialEmployCoBorrowerSameBusiness   = 'commercialEmployCoBorrowerSameBusiness',
  commercialExpectedCost                   = 'commercialExpectedCost',
  commercialLoanPurpose                    = 'commercialLoanPurpose',
  commercialEmploySameBusiness             = 'commercialEmploySameBusiness',
  commercialEmployeeCount                  = 'commercialEmployeeCount',
  commercialEntityType                     = 'commercialEntityType',
  commercialEquipmentOrInventory           = 'commercialEquipmentOrInventory',
  commercialLifeExpectancy                 = 'commercialLifeExpectancy',
  commercialLienBalance                    = 'commercialLienBalance',
  commercialLoanHolder                     = 'commercialLoanHolder',
  commercialLoanTiming                     = 'commercialLoanTiming',
  commercialMailingCity                    = 'commercialMailingCity',
  commercialMailingState                   = 'commercialMailingState',
  commercialMailingStreet                  = 'commercialMailingStreet',
  commercialMailingStreet2                 = 'commercialMailingStreet2',
  commercialMailingZip                     = 'commercialMailingZip',
  commercialManagementList                 = 'commercialManagementList',
  commercialNAICSCode                      = 'commercialNAICSCode',
  commercialOwnerList                      = 'commercialOwnerList',
  commercialPhone                          = 'commercialPhone',
  commercialProfitExplanation              = 'commercialProfitExplanation',
  commercialPropertyType                   = 'commercialPropertyType',
  commercialProfitTrend3                   = 'commercialProfitTrend3',
  commercialRevenueExplanation             = 'commercialRevenueExplanation',
  commercialRevenueLastYear                = 'commercialRevenueLastYear',
  commercialRevenueTrend3                  = 'commercialRevenueTrend3',
  commercialSameBusiness                   = 'commercialSameBusiness',
  commercialSameMailingAddress             = 'commercialSameMailingAddress',
  commercialState                          = 'commercialState',
  commercialStateOfIncorporation           = 'commercialStateOfIncorporation',
  commercialStateOfOrganization            = 'commercialStateOfOrganization',
  commercialStreet                         = 'commercialStreet',
  commercialStreet2                        = 'commercialStreet2',
  commercialTIN                            = 'commercialTIN',
  commercialVehicleList                    = 'commercialVehicleList',
  commercialVehicleTotalPurchasePrice      = 'commercialVehicleTotalPurchasePrice',
  commercialVehicleTotalDownPayment        = 'commercialVehicleTotalDownPayment',
  commercialVehicleTotalTradeInValue       = 'commercialVehicleTotalTradeInValue',
  commercialWebsite                        = 'commercialWebsite',
  commercialYearStarted                    = 'commercialYearStarted',
  commercialZip                            = 'commercialZip',
  constructionCost                         = 'constructionCost',
  constructionHomeType                     = 'constructionHomeType',
  constructionLoanType                     = 'constructionLoanType',
  constructionLandAcquired                 = 'constructionLandAcquired',
  constructionLandCost                     = 'constructionLandCost',
  constructionLandOwner                    = 'constructionLandOwner',
  constructionRoofingType                  = 'constructionRoofingType',
  creditAuthorize                          = 'creditAuthorize',
  creditBoosterLoanOption                  = 'creditBoosterLoanOption',
  creditCardType                           = 'creditCardType',
  creditCardLimit                          = 'creditCardLimit',
  creditReportId                           = 'creditReportId',
  creditScore                              = 'fico',
  currentZoningClassification              = 'currentZoningClassification',
  dateOfBirth                              = 'dateOfBirth',
  decBorrower                              = 'decBorrower',
  decCoBorrower                            = 'decCoBorrower',
  decRelative                              = 'decRelative',
  declarations                             = 'declarations',
  downDollar                               = 'downPaymentDollar',
  downPercent                              = 'downPaymentPercent',
  email                                    = 'email',
  employBase                               = 'employBase',
  employBonus                              = 'employBonus',
  employCity                               = 'employCity',
  employCommission                         = 'employCommission',
  employCompanyName                        = 'employCompanyName',
  employmentType                           = 'employmentType',
  employOvertime                           = 'employOvertime',
  employOwnershipShare                     = 'employOwnershipShare',
  employPartyTransaction                   = 'employPartyTransaction',
  employPhone                              = 'employPhone',
  employPrevCity                           = 'employPrevCity',
  employPrevEnd                            = 'employPrevEnd',
  employPrevName                           = 'employPrevName',
  employPrevStart                          = 'employPrevStart',
  employPrevState                          = 'employPrevState',
  employPrevStreet                         = 'employPrevStreet',
  employPrevStreet2                        = 'employPrevStreet2',
  employPrevTitle                          = 'employPrevTitle',
  employPrevZip                            = 'employPrevZip',
  employStart                              = 'employStart',
  employState                              = 'employState',
  employStreet                             = 'employStreet',
  employStreet2                            = 'employStreet2',
  employTitle                              = 'employTitle',
  employZip                                = 'employZip',
  escrowUsage                              = 'escrowUsage',
  ethnicity                                = 'ethnicity',
  ethnicityHispanic                        = 'ethnicityHispanic',
  ethnicityOther                           = 'ethnicityOther',
  excludedQuestions                        = 'excludedQuestions',
  existingLoanNumber                       = 'existingLoanNumber',
  expectedClosingDate                      = 'expectedClosingDate',
  expectedLumpSum                          = 'expectedLumpSum',
  expectedOwnerOccupancy                   = 'expectedOwnerOccupancy',
  fee                                      = 'fee',
  financialStatementAmountOwed             = 'financialStatementAmountOwed',
  financialStatementBalanceOwed            = 'financialStatementBalanceOwed',
  financialStatementCreditCards            = 'financialStatementCreditCards',
  financialStatementCreditor               = 'financialStatementCreditor',
  financialStatementCurrentBalance         = 'financialStatementCurrentBalance',
  financialStatementDescription            = 'financialStatementDescription',
  financialStatementFinancialInstitution   = 'financialStatementFinancialInstitution',
  financialStatementHasCreditCard          = 'financialStatementHasCreditCard',
  financialStatementHasLoan                = 'financialStatementHasLoan',
  financialStatementHasMortgage            = 'financialStatementHasMortgage',
  financialStatementHasNote                = 'financialStatementHasNote',
  financialStatementHasPersonalProperty    = 'financialStatementHasPersonalProperty',
  financialStatementHasRealEstate          = 'financialStatementHasRealEstate',
  financialStatementHasRetirementAccount   = 'financialStatementHasRetirementAccount',
  financialStatementHasStock               = 'financialStatementHasStock',
  financialStatementHasTaxes               = 'financialStatementHasTaxes',
  financialStatementLocation               = 'financialStatementLocation',
  financialStatementLoans                  = 'financialStatementLoans',
  financialStatementMarketValue            = 'financialStatementMarketValue',
  financialStatementMaturityDate           = 'financialStatementMaturityDate',
  financialStatementMonthlyPayment         = 'financialStatementMonthlyPayment',
  financialStatementMortgageBalance        = 'financialStatementMortgageBalance',
  financialStatementMortgages              = 'financialStatementMortgages',
  financialStatementNotePurpose            = 'financialStatementNotePurpose',
  financialStatementNotes                  = 'financialStatementNotes',
  financialStatementNumOfShares            = 'financialStatementNumOfShares',
  financialStatementOwingParty             = 'financialStatementOwingParty',
  financialStatementPersonalProperties     = 'financialStatementPersonalProperties',
  financialStatementPersonalType           = 'financialStatementPersonalType',
  financialStatementPurchaseDate           = 'financialStatementPurchaseDate',
  financialStatementRealEstate             = 'financialStatementRealEstate',
  financialStatementSecurityName           = 'financialStatementSecurityName',
  financialStatementStocks                 = 'financialStatementStocks',
  financialStatementTaxes                  = 'financialStatementTaxes',
  financialStatementTaxType                = 'financialStatementTaxType',
  financialStatementRetirementAccounts     = 'financialStatementRetirementAccounts',
  financialStatementRetirementType         = 'financialStatementRetirementType',
  firstName                                = 'firstName',
  forecloseCompleted                       = 'forecloseCompleted',
  hasAddlPropertiesYN                      = 'hasAdditionalProperties',
  hasAutoLoanSpecialCalculation            = 'hasAutoLoanSpecialCalculation',
  hasReverseAgeQuestion                    = 'hasReverseAgeQuestion',
  hasAlimony                               = 'hasAlimony',
  hasAnotherMortgageInProgress             = 'hasAnotherMortgageInProgress',
  hasBankrupt                              = 'hasBankrupt',
  hasBorrowedDown                          = 'hasBorrowedDown',
  hasCommercialBankrupt                    = 'hasCommercialBankrupt',
  hasCommercialCredit                      = 'hasCommercialCredit',
  hasCommercialDebt                        = 'hasCommercialDebt',
  hasCommercialLegalAction                 = 'hasCommercialLegalAction',
  hasCommercialTaxDue                      = 'hasCommercialTaxDue',
  hasCitizenship                           = 'hasCitizenship',
  hasConveyedTitle                         = 'hasConveyedTitle',
  hasCurrentCheckingAccount                = 'hasCurrentCheckingAccount',
  hasDelinquent                            = 'hasDelinquent',
  hasEnabledCreditReportOnTurbo            = 'hasEnabledCreditReportOnTurbo',
  hasExitedFinicity                        = 'hasExitedFinicty',
  hasFamilyRelationship                    = 'hasFamilyRelationship',
  hasForeclosed                            = 'hasForeclosed',
  hasGreenCard                             = 'hasGreenCard',
  hasJudgements                            = 'hasJudgements',
  hasLawsuit                               = 'hasLawsuit',
  hasLineOfWorkQuestion                    = 'hasLineOfWorkQuestion',
  hasLoanObligation                        = 'hasLoanObligation',
  hasLoanTypeQuestion                      = 'hasLoanTypeQuestion',
  hasMadeMostRecentMortgagePayment         = 'hasMadeMostRecentMortgagePayment',
  hasNoteEndorsement                       = 'hasNoteEndorsement',
  hasOccupyPrimary                         = 'hasOccupyPrimary',
  hasOtherManagement                       = 'hasOtherManagement',
  hasOtherLienOnProperty                   = 'hasOtherLienOnProperty',
  hasWarningForLoanAmountReduction         = 'hasWarningForLoanAmountReduction',
  hasWarningForRateChange                  = 'hasWarningForRateChange',
  isCollectingMailingAddress               = 'isCollectingMailingAddress',
  isDefaultedHMDAAnswer                    = 'isDefaultedHMDAAnswer',
  isHideGovtMonitoringHelocArm             = 'isHideGovtMonitoringHelocArm',
  isHideDependentsAges                     = 'isHideDependentsAges',
  isMailingDifferent                       = 'isMailingDifferent',
  isOwningHomeOutright                     = 'isOwningHomeOutright',
  isOwningOtherHomes                       = 'isOwningOtherHomes',
  isFinancialVerificationComplete          = 'isFinancialVerificationComplete',
  isVehicleSalvageTitle                    = 'isVehicleSalvageTitle',
  hasLatePropertyCharges                   = 'hasLatePropertyCharges',
  hasOtherTaxBillsDue                      = 'hasOtherTaxBillsDue',
  hasOwnerInterest                         = 'hasOwnerInterest',
  hasSelectedNewHome                       = 'hasSelectedNewHome',
  hasShortSale                             = 'hasShortSale',
  hasUniquePersonalLoanProgram             = 'hasUniquePersonalLoanProgram',
  hasUniqueHomeEquityProgram               = 'hasUniqueHomeEquityProgram',
  hasAssetFinancialInstitution             = 'hasAssetFinancialInstitution',
  homeChosenYN                             = 'homeChosen',
  homeEquityLoanPurpose                    = 'homeEquityLoanPurpose',
  homeEquityLoanPurposeOther               = 'homeEquityLoanPurposeOther',
  homeSoldYN                               = 'homeSold',
  homeStories                              = 'homeStories',
  homeValue                                = 'propertyAppraisedValue',
  incomeMonthly                            = 'incomeMonthly',
  incomeOnDeclineYN                        = 'incomeOnDecline',
  incomeSource                             = 'incomeSource',
  incomeVerifiable                         = 'incomeVerifiable',
  initialMortgageInsurance                 = 'initialMortgageInsurance',
  isCommercialExistingCustomer             = 'isCommercialExistingCustomer',
  isFromDealer                             = 'isFromDealer',
  isBorrowerSsnValid                       = 'isBorrowerSsnValid',
  isCoBorrowerSsnValid                     = 'isCoBorrowerSsnValid',
  isBorrowerSsnEmpty                       = 'isBorrowerSsnEmpty',
  isCoBorrowerSsnEmpty                     = 'isCoBorrowerSsnEmpty',
  isBothSsnMatch                           = 'isBothSsnMatch',
  isHidePurchasePropertyAddress            = 'isHidePurchasePropertyAddress',
  landingLoanType                          = 'landingLoanType',
  landSize                                 = 'landSize',
  lastName                                 = 'lastName',
  livingArea                               = 'livingArea',
  liabilityAmount                          = 'liabilityAmount',
  liabilityBalance                         = 'liabilityBalance',
  liabilityExclusionReason                 = 'liabilityExclusionReason',
  liabilityName                            = 'liabilityName',
  liabilityStatus                          = 'liabilityStatus',
  liabilityUsage                           = 'liabilityUsage',
  liabilityYearAssociate                   = 'liabilityYearAssociate',
  liabilityYearInsure                      = 'liabilityYearInsure',
  liabilityYearTaxes                       = 'liabilityYearTaxes',
  liabilityCombinedNumberOtherHomes        = 'liabilityCombinedNumberOtherHomes',
  liabilityCombinedYearAssociate           = 'liabilityCombinedYearAssociate',
  liabilityCombinedYearInsure              = 'liabilityCombinedYearInsure',
  liabilityCombinedYearTaxes               = 'liabilityCombinedYearTaxes',
  lineOfCredit                             = 'lineOfCredit',
  lineOfWorkMonths                         = 'lineOfWorkMonths',
  lineOfWorkYears                          = 'lineOfWorkYears',
  listingPrice                             = 'listingPrice',
  livingEscrowUsage                        = 'livingEscrowUsage',
  livingMonthlyMortgage                    = 'livingMonthlyMortgage',
  livingMonthlyRentAmount                  = 'livingMonthlyRentAmount',
  livingOccupancyStart                     = 'livingOccupancyStart',
  livingPrevPropertyCity                   = 'livingPrevPropertyCity',
  livingPrevPropertyState                  = 'livingPrevPropertyState',
  livingPrevPropertyStreet                 = 'livingPrevPropertyStreet',
  livingPrevPropertyStreet2                = 'livingPrevPropertyStreet2',
  livingPrevPropertyZip                    = 'livingPrevPropertyZip',
  livingPrevRentOrOwn                      = 'livingPrevRentOrOwn',
  livingPrevOccupancyStart                 = 'livingPrevOccupancyStart',
  livingPropertyCity                       = 'livingPropertyCity',
  livingPropertyState                      = 'livingPropertyState',
  livingPropertyStreet                     = 'livingPropertyStreet',
  livingPropertyStreet2                    = 'livingPropertyStreet2',
  livingPropertyType                       = 'livingPropertyType',
  livingPropertyUsage                      = 'livingPropertyUsage',
  livingPropertyZip                        = 'livingPropertyZip',
  livingRentOrOwn                          = 'livingRentOrOwn',
  livingSituation                          = 'livingSituation',
  livingYearAssociate                      = 'livingYearAssociate',
  livingYearInsure                         = 'livingYearInsure',
  livingYearTaxes                          = 'livingYearTaxes',
  loanAmount                               = 'loanAmount',
  loanOfficer                              = 'loanOfficer',
  loanPurpose                              = 'loanPurpose',
  loanRenewalDate                          = 'loanRenewalDate',
  loanTerm                                 = 'loanTerm',
  loanTermDesired                          = 'loanTermDesired',                          //auto-loan
  loanType                                 = 'loanType',
  lowFicoLimit                             = 'lowFicoLimit',
  lowFicoSecondaryHomeLimit                = 'lowFicoSecondaryHomeLimit',
  mailingCity                              = 'mailingCity',
  mailingState                             = 'mailingState',
  mailingStreet                            = 'mailingStreet',
  mailingStreet2                           = 'mailingStreet2',
  mailingZip                               = 'mailingZip',
  managementFirstName                      = 'managementFirstName',
  managementLastName                       = 'managementLastName',
  managementMiddleName                     = 'managementMiddleName',
  managementSuffixName                     = 'managementSuffixName',
  managementTitleHeld                      = 'managementTitleHeld',
  maritalStatus                            = 'married',
  maxClaimAmount                           = 'maxClaimAmount',
  maxLoanAmount                            = 'maxLoanAmount',
  maxMonthlyPayment                        = 'maxMonthlyPayment',
  maxLtvPercent                            = 'maxLtvPercent',
  maxLumpSum                               = 'maxLumpSum',
  message                                  = 'message',
  middleName                               = 'middleName',
  militaryExperience                       = 'militaryExperience',
  minLoanAmount                            = 'minLoanAmount',
  monthlyPayment                           = 'monthlyPayment',
  monthlyRent                              = 'monthlyRent',
  mortgageBalance                          = 'mortgageBalance',
  mothersMaidenName                        = 'mothersMaidenName',
  netPrincipalLimit                        = 'netPrincipalLimit',
  numOfDependent                           = 'numberOfDependents',
  dependentsAges                           = 'dependentsAges',
  numOfStructures                          = 'numberOfStructures',
  otherIncome                              = 'otherIncome',
  ownerAnnualPersonalIncome                = 'ownerAnnualPersonalIncome',
  ownerCity                                = 'ownerCity',
  ownerEmail                               = 'ownerEmail',
  ownerFirstName                           = 'ownerFirstName',
  ownerIsApplicant                         = 'ownerIsApplicant',
  ownerLastName                            = 'ownerLastName',
  ownerMiddleName                          = 'ownerMiddleName',
  ownerSuffixName                          = 'ownerSuffixName',
  ownerGuarantor                           = 'ownerGuarantor',
  ownerPercentage                          = 'ownerPercentage',
  ownerPersonalNetWorth                    = 'ownerPersonalNetWorth',
  ownerPhone                               = 'ownerPhone',
  ownerPropertyUsage                       = 'ownerPropertyUsage',
  ownerSinceYear                           = 'ownerSinceYear',
  ownerState                               = 'ownerState',
  ownerStreet                              = 'ownerStreet',
  ownerStreet2                             = 'ownerStreet2',
  ownerTitleHeld                           = 'ownerTitleHeld',
  ownerZip                                 = 'ownerZip',
  parcelId                                 = 'parcelId',
  phone                                    = 'phone',
  prequalAmount                            = 'prequalAmount',
  prequalAmountYN                          = 'prequalAmountDesired',
  prequalExpiration                        = 'prequalExpiration',
  prequalIssued                            = 'prequalIssued',
  prequalLetterAmount                      = 'prequalLetterAmount',
  prequalRecalculate                       = 'prequalRecalculate',
  prequalSummary                           = 'prequalSummary',
  previousPropertyUsage                    = 'previousPropertyUsage',
  productType                              = 'productType',
  propertyAnnualAssociationAmount          = 'propertyAnnualAssociationAmount',
  propertyAnnualInsuranceAmount            = 'propertyAnnualInsuranceAmount',
  propertyAnnualRentAmount                 = 'propertyAnnualRentAmount',
  propertyAnnaulTaxAmount                  = 'propertyAnnualTaxAmount',
  propertyCity                             = 'propertyCity',
  propertyCounty                           = 'propertyCounty',
  propertyHasMortgage                      = 'propertyHasMortgage',
  propertyOriginalCost                     = 'propertyOriginalCost',
  propertyMonthlyRentalIncome              = 'propertyMonthlyRentalIncome',
  propertyState                            = 'propertyState',
  propertyStreet                           = 'propertyStreet',
  propertyStreet2                          = 'propertyStreet2',
  propertyTitleHeld                        = 'propertyTitleHeld',
  propertyType                             = 'propertyType',
  propertyTypeDesc                         = 'propertyTypeDesc',
  propertyUsage                            = 'propertyUsage',
  propertyValue                            = 'propertyValue',
  propertyZip                              = 'propertyZip',
  purchasePrice                            = 'purchasePrice',                            //auto-loan
  purchaseTimeline                         = 'purchaseTimeline',
  purpose                                  = 'purpose',
  race                                     = 'race',
  raceAsian                                = 'raceAsian',
  raceAsianOther                           = 'raceAsianOther',
  raceIslander                             = 'raceIslander',
  raceIslanderOther                        = 'raceIslanderOther',
  rate                                     = 'rate',
  realEstateLiabilities                    = 'realEstateLiabilities',
  realEstateTotalValue                     = 'realEstateTotalValue',
  realtorEmail                             = 'realtorEmail',
  realtorFirstName                         = 'realtorFirstName',
  realtorLastName                          = 'realtorLastName',
  realtorShare                             = 'realtorShare',
  referralId                               = 'referralId',
  relativeDOB                              = 'relativeDateOfBirth',
  relativeEmail                            = 'relativeEmail',
  relativeFirstName                        = 'relativeFirstName',
  relativeLastName                         = 'relativeLastName',
  relativeLivingPropertyCity               = 'relativeLivingPropertyCity',
  relativeLivingPropertyState              = 'relativeLivingPropertyState',
  relativeLivingPropertyStreet             = 'relativeLivingPropertyStreet',
  relativeLivingPropertyStreet2            = 'relativeLivingPropertyStreet2',
  relativeLivingPropertyZip                = 'relativeLivingPropertyZip',
  relativeMiddleName                       = 'relativeMiddleName',
  relativePhone                            = 'relativePhone',
  relativeSameAddress                      = 'relativeSameAddress',
  relativeSuffixName                       = 'relativeSuffixName',
  retirementIncome                         = 'retirementIncome',
  reverseMortgageLoanPurpose               = 'reverseMortgageLoanPurpose',
  reverseMortgageLoanType                  = 'reverseMortgageLoanType',
  scheduleDate                             = 'scheduleDate',
  schedulerEnabled                         = 'schedulerEnabled',
  scheduleTime                             = 'scheduleTime',
  selectedRate                             = 'selectedRate',
  sex                                      = 'sex',
  ssn                                      = 'ssn',
  ssnQuestionRequirement                   = 'ssnQuestionRequirement',
  startDate                                = 'startDate',
  suffixName                               = 'suffixName',
  tenurePayment                            = 'tenurePayment',
  termPayment                              = 'termPayment',
  totalUpfrontCosts                        = 'totalUpfrontCosts',
  tradeInValue                             = 'tradeInValue',                             //auto-loan
  transferReasonCode                       = 'transferReasonCode',
  verifyAddress                            = 'verifyAddress',
  veteranLoanYN                            = 'veteranLoanYN',
  veteranYN                                = 'veteranYN',
  vehicle                                  = 'vehicle',
  vehicleBody                              = 'vehicleBody',
  vehicleCount                             = 'vehicleCount',
  vehicleId                                = 'vehicleId',
  vehicleMake                              = 'vehicleMake',
  vehicleModel                             = 'vehicleModel',
  vehicleMileage                           = 'vehicleMileage',
  vehicleNewOrUsed                         = 'vehicleNewOrUsed',
  vehicleType                              = 'vehicleType',
  vehicleYear                              = 'vehicleYear',
  wantsAutoInsurance                       = 'wantsAutoInsurance',
  yearAssociate                            = 'annualAssociation',
  yearInsure                               = 'annualInsurance',
  yearTaxes                                = 'annualTaxes',
}

export type FieldName = keyof typeof FieldNames;

export enum PropertyFieldNameType {
  SUBJECT_PROPERTY     = 'SUBJECT_PROPERTY',
  BORROWER_RESIDENCE   = 'BORROWER_RESIDENCE',
  COBORROWER_RESIDENCE = 'COBORROWER_RESIDENCE',
  RELATIVE_RESIDENCE   = 'RELATIVE_RESIDENCE'
}

export type PropertyFieldNameGroup = {
  city    : string;
  state   : string;
  street  : string;
  street2 : string;
  zip     : string;
}

export const getPropertyFieldNameGroup = (type: PropertyFieldNameType): PropertyFieldNameGroup => {
  const typeToGroupMap : Map<PropertyFieldNameType, PropertyFieldNameGroup> = new Map([
    [PropertyFieldNameType.SUBJECT_PROPERTY, {
      city    : FieldNames.propertyCity,
      state   : FieldNames.propertyState,
      street  : FieldNames.propertyStreet,
      street2 : FieldNames.propertyStreet2,
      zip     : FieldNames.propertyZip,
    }],
    [PropertyFieldNameType.BORROWER_RESIDENCE, {
      city    : FieldNames.livingPropertyCity,
      state   : FieldNames.livingPropertyState,
      street  : FieldNames.livingPropertyStreet,
      street2 : FieldNames.livingPropertyStreet2,
      zip     : FieldNames.livingPropertyZip,
    }],
    [PropertyFieldNameType.COBORROWER_RESIDENCE, {
      city    : FieldNames.coBorrowerLivingPropertyCity,
      state   : FieldNames.coBorrowerLivingPropertyState,
      street  : FieldNames.coBorrowerLivingPropertyStreet,
      street2 : FieldNames.coBorrowerLivingPropertyStreet2,
      zip     : FieldNames.coBorrowerLivingPropertyZip,
    }],
    [PropertyFieldNameType.RELATIVE_RESIDENCE, {
      city    : FieldNames.relativeLivingPropertyCity,
      state   : FieldNames.relativeLivingPropertyState,
      street  : FieldNames.relativeLivingPropertyStreet,
      street2 : FieldNames.relativeLivingPropertyStreet2,
      zip     : FieldNames.relativeLivingPropertyZip,
    }],
  ]);
  return typeToGroupMap.get(type);
};
