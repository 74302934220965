import {
  Borrower,
  Employment,
  ResidenceDetailType,
  UniversalLoanApplication,
  UniversalResidence,
} from '../types';
import { FieldNames, FieldNames as Fields } from '../../../models/fields/names';
import {
  arrayContains,
  hasBorrower,
  hasCoBorrower,
  isNonProfit,
  isPrimaryHome,
  isRefinance,
  isYes,
} from '../../../models/fields/conditionals';
import {
  BorrowerType,
  FormName,
  LivingSituation,
  YesOrNo,
} from '../../../models/options/enums';
import moment from 'moment';
import {
  mapAssets,
  mapCitizenship,
  mapCoBorrowerAssets,
  mapCoBorrowerEmployment,
  mapCoBorrowerIncome,
  mapDeclarationExplanation,
  mapEthnicity,
  mapIncome,
  mapLiabilities,
  mapRace,
  mapReoProperty,
  safelyTrim,
  toBinaryFlagOrUndefined,
  toSqlDate,
} from '../mappings';
import { borrowerSsnRef, coBorrowerSsnRef } from 'app/components/FormFields/Ssn/CollectSsnBase';
import { normalizePhone, toNormalizedSsn } from '../../../util/normalizers';
import {
  Collateral,
  CommercialBridgeLoan,
  CommercialBusiness,
  CommercialEquipment,
  CommercialLoan,
  CommercialManagement,
  CommercialMortgage,
  CommercialOwner,
  CommercialPurchase,
  CommercialRefinance,
  CommercialVehicle,
  DynamicOwner,
  Ethnicity,
  EthnicityName,
  ReverseMortgageLoanType,
  Sex,
  SmallBusiness,
  SubjectProperty,
  UniversalLoanSource,
} from '@lenderful/domain';
import { ownerSsnRefs } from '../../../components/SmallBusinessCollectSsnStep';
import { getLoanTerm, getTenurePayment, getTermPayment, getLineOfCredit, getMortgageBalance } from 'app/api/loan-application/universal/reverse';
import { FicoRange } from '../../../reducers/app-config';

export const mapUniversalLoanSource = (formName: FormName): UniversalLoanSource => {
  switch(formName) {
    case FormName.AUTOLOAN:
      return UniversalLoanSource.AUTO;
    case FormName.COMMERCIALBRIDGELOAN:
      return UniversalLoanSource.COMMERCIAL_BRIDGE_LOAN;
    case FormName.COMMERCIALEQUIPMENT:
      return UniversalLoanSource.COMMERCIAL_EQUIPMENT;
    case FormName.COMMERCIALPURCHASE:
      return UniversalLoanSource.COMMERCIAL_PURCHASE;
    case FormName.COMMERCIALREFINANCE:
      return UniversalLoanSource.COMMERCIAL_REFINANCE;
    case FormName.COMMERCIALVEHICLE:
      return UniversalLoanSource.COMMERCIAL_VEHICLE;
    case FormName.CREDITBOOSTER:
      return UniversalLoanSource.BOOSTER;
    case FormName.CREDITCARD:
      return UniversalLoanSource.CREDIT;
    case FormName.OTHER_VEHICLE:
      return UniversalLoanSource.OTHER;
    case FormName.OVERDRAFTPROTECTION:
      return UniversalLoanSource.OVERDRAFT_PROTECTION;
    case FormName.PERSONALLOAN:
      return UniversalLoanSource.PERSONAL;
    case FormName.REVERSE:
      return UniversalLoanSource.REVERSE;
    case FormName.SBLINEOFCREDIT:
      return UniversalLoanSource.SB_LINE_OF_CREDIT;
    case FormName.SBTERM:
      return UniversalLoanSource.SB_TERM;
  }
};

export const isReverseMortgageType = (form, type: ReverseMortgageLoanType): boolean => {
  return form[FieldNames.reverseMortgageLoanType] === type;
};

/**
 * Maps the values in the existing form to the Universal Loan Application, which is
 * used for Consumer Loans, Credit Card, and Reverse Mortgage products.
 *
 * @param {any} form
 * @param {FormName} formName
 * @returns {UniversalLoanApplication}
 */
export const mapToUniversalLoanApplication = (form, formName: FormName): UniversalLoanApplication => {

  let application: UniversalLoanApplication = {
    assets                  : form[Fields.assets],
    borrower                : mapUniversalBorrowers(form,formName),
    birthDate               : toSqlDate(form[Fields.dateOfBirth]),
    coborrowerOnLoanFlag    : toBinaryFlagOrUndefined(form[Fields.coBorrowerYN]),
    employment              : mapUniversalEmployment(form, formName),
    fee                     : form[Fields.fee],
    livingMonthlyRentAmount : form[Fields.livingMonthlyRentAmount],
    loanType                : form[Fields.loanType],
    monthlyPayment          : form[Fields.monthlyPayment],
    propertyZip             : form[Fields.propertyZip],
    realEstateLiabilities   : form[Fields.realEstateLiabilities],
    subjectProperty         : mapUniversalSubjectProperty(form, formName),
    titleFirstName          : safelyTrim(form[Fields.firstName]),
    titleLastName           : safelyTrim(form[Fields.lastName]),
    titleMiddleName         : safelyTrim(form[Fields.middleName]),
    titleSuffix             : form[Fields.suffixName],
  };

  // Reverse Mortgage
  if (formName === FormName.REVERSE) {
    application.reverseMortgage = {
      downPayment                : form[FieldNames.downDollar],
      expectedLumpSum            : form[FieldNames.expectedLumpSum],
      hasLatePropertyCharges     : toBinaryFlagOrUndefined(form[FieldNames.hasLatePropertyCharges]),
      hasSelectedNewHome         : toBinaryFlagOrUndefined(form[FieldNames.hasSelectedNewHome]),
      lineOfCredit               : getLineOfCredit(form),
      loanTerm                   : getLoanTerm(form),
      initialMortgageInsurance   : form[FieldNames.initialMortgageInsurance],
      maxClaimAmount             : form[FieldNames.maxClaimAmount],
      maxLumpSum                 : form[FieldNames.maxLumpSum],
      mortgageBalance            : getMortgageBalance(form),
      netPrincipalLimit          : form[FieldNames.netPrincipalLimit],
      propertyAppraisedValue     : form[FieldNames.homeValue],
      propertyType               : form[FieldNames.propertyType],
      propertyStreet             : form[FieldNames.propertyStreet],
      propertyStreet2            : form[FieldNames.propertyStreet2],
      propertyCity               : form[FieldNames.propertyCity],
      propertyState              : form[FieldNames.propertyState],
      propertyZip                : form[FieldNames.propertyZip],
      purchasePrice              : form[FieldNames.purchasePrice],
      realEstateTotalValue       : isYes(form[FieldNames.hasAddlPropertiesYN]) ? form[FieldNames.realEstateTotalValue]                                                                : undefined,
      reverseMortgageLoanType    : form[FieldNames.reverseMortgageLoanType],
      reverseMortgageLoanPurpose : form[FieldNames.reverseMortgageLoanPurpose],
      tenurePayment              : getTenurePayment(form),
      termPayment                : getTermPayment(form),
      totalUpfrontCosts          : form[FieldNames.totalUpfrontCosts],
    };
  }
  // Auto Loan
  if (formName === FormName.AUTOLOAN) {
    application.autoLoan = {
      autoPurchasePlan : form[Fields.autoPurchasePlan],
      loanTermDesired  : form[Fields.loanTermDesired],
      loanTerm         : form[Fields.loanTerm],
      rate             : form[Fields.rate],
      productType      : form[Fields.productType],
      purchasePrice    : form[Fields.purchasePrice],
      downPayment      : form[Fields.downDollar],
      tradeInValue     : form[Fields.tradeInValue],
      vehicle          : form[Fields.vehicle],
      vehicleId        : form[Fields.vehicleId],
      vehicleBody      : form[Fields.vehicleBody],
      vehicleMake      : form[Fields.vehicleMake],
      vehicleModel     : form[Fields.vehicleModel],
      vehicleYear      : form[Fields.vehicleYear],
      vehicleMileage   : form[Fields.vehicleMileage],
    };
  }
  // Other Vehicle Loan
  if (formName === FormName.OTHER_VEHICLE) {
    application.otherVehicleLoan = {
      downPayment     : form[Fields.downDollar],
      loanTermDesired : form[Fields.loanTermDesired],
      purchasePrice   : form[Fields.purchasePrice],
      tradeInValue    : form[Fields.tradeInValue],
      vehicle         : form[Fields.vehicle],
      vehicleType     : form[Fields.vehicleType],
    };
  }
  // Credit Card
  if (formName === FormName.CREDITCARD) {
    application.creditCard = {
      creditCardLimit   : form[Fields.creditCardLimit],
      creditCardType    : form[Fields.creditCardType],
      mothersMaidenName : form[Fields.mothersMaidenName],
    };

    application.borrower
      .forEach(borrower => {
        if (borrower.RESIDENCES?.[0]) {
          borrower.RESIDENCES[0].current_residence_flag = undefined;
          if (borrower.borrowerType === BorrowerType.RELATIVE) {
            delete borrower.RESIDENCES[0].ownership_type;
          }
        }
      });
  }
  // Credit Booster Loan
  if (formName === FormName.CREDITBOOSTER) {
    application.creditBoosterLoan = {
      accountSummary          : form[Fields.accountSummary],
      creditBoosterLoanOption : form[Fields.creditBoosterLoanOption],
      loanAmount              : form[Fields.loanAmount],
    };
  }
  // Personal Loan
  if (formName === FormName.PERSONALLOAN) {
    application.personalLoan = {
      hasCurrentCheckingAccount : form[Fields.hasCurrentCheckingAccount],
      loanTerm                  : form[FieldNames.loanTerm],
      productType               : form[FieldNames.productType],
      rate                      : form[FieldNames.rate],
      requestedLoanAmount       : form[FieldNames.loanAmount],
    };
  }
  // Commercial Loans
  if (formName.includes(FormName.COMMERCIAL)) {
    application.commercialLoan  = handleCommercialMapping(form, formName);
  }
  // Small Business
  if(formName === FormName.SBLINEOFCREDIT || formName === FormName.SBTERM) {
    application.smallBusiness = mapSmallBusiness(form);
  }
  return application;
};

const mapUniversalBorrowerResidences = (form, formName: FormName): UniversalResidence[] => {
  if (form[Fields.livingPrevPropertyStreet]) {
    return [mapUniversalBorrowerResidence(form, formName), mapUniversalBorrowerPreviousResidence(form)];
  }
  return [mapUniversalBorrowerResidence(form, formName)];
};

const mapUniversalBorrowerResidence = (form, formName:FormName): UniversalResidence => {
  // for refinance of primary home, residence is subject property
  if (isRefinance(form) && isPrimaryHome(form)) {
    return {
      annual_insurance_amount    : form[Fields.yearInsure],
      annual_tax_amount          : form[Fields.yearTaxes],
      association_annual_amount  : form[Fields.yearAssociate],
      city                       : form[Fields.propertyCity],
      current_residence_flag     : form[Fields.propertyStreet] ? toBinaryFlagOrUndefined(YesOrNo.YES) : undefined,
      duration_months            : handleDurationInMonths(form[Fields.livingOccupancyStart]),
      escro_flag                 : toBinaryFlagOrUndefined(form[Fields.escrowUsage]),
      property_title_held        : form[Fields.propertyTitleHeld],
      property_type              : formName.includes(FormName.COMMERCIAL) ? form[Fields.livingPropertyType] : form[Fields.propertyType],
      property_use               : form[Fields.propertyUsage],
      postal_code                : form[Fields.propertyZip],
      residence_detail_type      : form[Fields.propertyStreet] ? ResidenceDetailType.CURRENT : undefined,
      ownership_type             : LivingSituation.OWN,
      state                      : form[Fields.propertyState],
      street_address_1           : form[Fields.propertyStreet],
      street_address_2           : form[Fields.propertyStreet2],
    };
  }
  return {
    annual_insurance_amount    : form[Fields.livingYearInsure],
    annual_tax_amount          : form[Fields.livingYearTaxes],
    association_annual_amount  : form[Fields.livingYearAssociate],
    city                       : form[Fields.livingPropertyCity],
    current_residence_flag     : form[Fields.livingPropertyStreet] ? toBinaryFlagOrUndefined(YesOrNo.YES) : undefined,
    duration_months            : handleDurationInMonths(form[Fields.livingOccupancyStart]),
    escro_flag                 : toBinaryFlagOrUndefined(form[Fields.livingEscrowUsage]),
    monthly_amount             : form[Fields.livingMonthlyMortgage] || form[Fields.livingMonthlyRentAmount],
    postal_code                : form[Fields.livingPropertyZip],
    property_title_held        : form[Fields.propertyTitleHeld],
    property_type              : formName.includes(FormName.COMMERCIAL) ? form[Fields.livingPropertyType] : form[Fields.propertyType],
    property_use               : form[Fields.propertyUsage],
    residence_detail_type      : form[Fields.propertyStreet] ? ResidenceDetailType.CURRENT : undefined,
    ownership_type             : form[Fields.livingRentOrOwn],
    state                      : form[Fields.livingPropertyState],
    street_address_1           : form[Fields.livingPropertyStreet],
    street_address_2           : form[Fields.livingPropertyStreet2],
  };
};

const mapUniversalBorrowerPreviousResidence = (form): UniversalResidence => {
  return {
    city                     : form[Fields.livingPrevPropertyCity],
    current_residence_flag   : form[Fields.livingPrevPropertyStreet] ? toBinaryFlagOrUndefined(YesOrNo.NO) : undefined,
    duration_months          : moment(form[Fields.livingOccupancyStart]).diff(moment(form[Fields.livingPrevOccupancyStart]), 'months'),
    postal_code              : form[Fields.livingPrevPropertyZip],
    ownership_type           : form[Fields.livingPrevRentOrOwn],
    residence_detail_type    : form[Fields.livingPrevPropertyStreet] ? ResidenceDetailType.PREVIOUS : undefined,
    state                    : form[Fields.livingPrevPropertyState],
    street_address_1         : form[Fields.livingPrevPropertyStreet],
    street_address_2         : form[Fields.livingPrevPropertyStreet2],
  };
};

const mapUniversalBorrowers = (form, formName: FormName): Partial<Borrower>[] => {
  const borrowers: Partial<Borrower>[]     = [];
  const primaryType                        = FieldNames.decBorrower;
  if (hasBorrower(form)) {
    let primaryBorrower: Partial<Borrower> = {
      birth_date                      : form[Fields.dateOfBirth] ? toSqlDate(form[Fields.dateOfBirth]) : undefined,
      credit_score                    : form[Fields.creditScore],
      CURRENT_ASSET                   : mapAssets(form, formName),
      CURRENT_INCOME                  : mapIncome(form, formName),
      CURRENT_LIABILITY               : mapLiabilities(form),
      RESIDENCES                      : mapUniversalBorrowerResidences(form, formName),
      REO_PROPERTY                    : mapReoProperty(form),
      credit_report_reference         : form[Fields.creditReportId],
      email                           : form[Fields.email],
      EMPLOYMENT                      : mapUniversalEmployment(form, formName),
      first_name                      : safelyTrim(form[Fields.firstName]),
      income_on_decline_flag          : toBinaryFlagOrUndefined(form[Fields.incomeOnDeclineYN]),
      last_name                       : safelyTrim(form[Fields.lastName]),
      marital_status                  : form[Fields.maritalStatus],
      middle_name                     : safelyTrim(form[Fields.middleName]),
      name_suffix                     : form[Fields.suffixName],
      phone                           : form[Fields.phone],
      primary_borrower_flag           : toBinaryFlagOrUndefined(true),
      DECLARATION_EXPLANATION         : mapDeclarationExplanation(form, primaryType),
      ssn                             : borrowerSsnRef ? toNormalizedSsn(borrowerSsnRef.value) : undefined,
      borrowerType                    : BorrowerType.PRIMARY,
      dec_citizenship_flag            : mapCitizenship(form, primaryType),
    };
    if(formName === FormName.REVERSE) {
      primaryBorrower = {
        ...primaryBorrower,
        age                             : form[Fields.age],
        dec_bankruptcy_flag             : toBinaryFlagOrUndefined(form[primaryType][Fields.hasBankrupt]),
        dec_comaker_endorser_flag       : toBinaryFlagOrUndefined(form[primaryType][Fields.hasNoteEndorsement]),
        dec_home_owner_occupy_flag      : toBinaryFlagOrUndefined(form[primaryType][Fields.hasOccupyPrimary]),
        dec_outstanding_judgements_flag : toBinaryFlagOrUndefined(form[primaryType][Fields.hasJudgements]),
        dec_party_to_lawsuit_flag       : toBinaryFlagOrUndefined(form[primaryType][Fields.hasLawsuit]),
        dec_presently_delinquent_flag   : toBinaryFlagOrUndefined(form[primaryType][Fields.hasDelinquent]),
        sex_female_flag: toBinaryFlagOrUndefined(arrayContains(form[primaryType][Fields.sex], Sex.FEMALE)),
        sex_male_flag: toBinaryFlagOrUndefined(arrayContains(form[primaryType][Fields.sex], Sex.MALE)),
        sex_not_provided_flag: toBinaryFlagOrUndefined(arrayContains(form[primaryType][Fields.sex], Sex.NOSHARE)),
        ...mapRace(form, primaryType),
        ...mapEthnicity(form, primaryType),
      };
    }
    if(formName.includes(FormName.COMMERCIAL)) {
      primaryBorrower = {
        ...primaryBorrower,
        dec_commercial_bankrupt_flag         : toBinaryFlagOrUndefined(form[primaryType][Fields.hasCommercialBankrupt]),
        dec_commercial_credit_flag           : toBinaryFlagOrUndefined(form[primaryType][Fields.hasCommercialCredit]),
        dec_commercial_debt_flag             : toBinaryFlagOrUndefined(form[primaryType][Fields.hasCommercialDebt]),
        dec_commercial_legal_action_flag     : toBinaryFlagOrUndefined(form[primaryType][Fields.hasCommercialLegalAction]),
        dec_commercial_tax_due_flag          : toBinaryFlagOrUndefined(form[primaryType][Fields.hasCommercialTaxDue]),
      };
    }
    borrowers.push(primaryBorrower);
  }

  if (hasCoBorrower(form)) {
    const coType = FieldNames.decCoBorrower;
    let coBorrower: Partial<Borrower> | any = {
      birth_date                      : form[Fields.coBorrowerDOB] ? toSqlDate(form[Fields.coBorrowerDOB]) : undefined,
      first_name                      : safelyTrim(form[Fields.coBorrowerFirstName]),
      last_name                       : safelyTrim(form[Fields.coBorrowerLastName]),
      middle_name                     : safelyTrim(form[Fields.coBorrowerMiddleName]),
      name_suffix                     : form[Fields.coBorrowerSuffixName],
      email                           : form[Fields.coBorrowerEmail],
      CURRENT_ASSET                   : mapCoBorrowerAssets(form, formName),
      CURRENT_INCOME                  : mapCoBorrowerIncome(form, formName),
      EMPLOYMENT                      : mapCoBorrowerEmployment(form),
      RESIDENCES                      : [mapUniversalCoBorrowerResidence(form, formName)],
      primary_borrower_flag           : toBinaryFlagOrUndefined(false),
      credit_report_reference         : form[Fields.creditReportId],
      income_on_decline_flag          : toBinaryFlagOrUndefined(form[Fields.coBorrowerIncomeOnDeclineYN]),
      phone                           : form[Fields.coBorrowerPhone],
      DECLARATION_EXPLANATION         : mapDeclarationExplanation(form, coType),
      ssn                             : coBorrowerSsnRef ? toNormalizedSsn(coBorrowerSsnRef.value) : undefined,
      borrowerType                    : BorrowerType.COBORROWER,
      marital_status                  : form[Fields.coBorrowerMaritalStatus],
      dec_citizenship_flag            : mapCitizenship(form, coType),
      coborrower_on_title_flag        : toBinaryFlagOrUndefined(form[Fields.coBorrowerOnTitle]),
    };
    if(formName === FormName.REVERSE) {
      coBorrower = {
        ...coBorrower,
        age                             : form[Fields.coBorrowerAge],
        dec_bankruptcy_flag             : toBinaryFlagOrUndefined(form[primaryType][Fields.hasBankrupt]),
        dec_comaker_endorser_flag       : toBinaryFlagOrUndefined(form[primaryType][Fields.hasNoteEndorsement]),
        dec_home_owner_occupy_flag      : toBinaryFlagOrUndefined(form[primaryType][Fields.hasOccupyPrimary]),
        dec_outstanding_judgements_flag : toBinaryFlagOrUndefined(form[primaryType][Fields.hasJudgements]),
        dec_party_to_lawsuit_flag       : toBinaryFlagOrUndefined(form[primaryType][Fields.hasLawsuit]),
        dec_presently_delinquent_flag   : toBinaryFlagOrUndefined(form[primaryType][Fields.hasDelinquent]),
        sex_female_flag: toBinaryFlagOrUndefined(arrayContains(form[coType][Fields.sex], Sex.FEMALE)),
        sex_male_flag: toBinaryFlagOrUndefined(arrayContains(form[coType][Fields.sex], Sex.MALE)),
        sex_not_provided_flag: toBinaryFlagOrUndefined(arrayContains(form[coType][Fields.sex], Sex.NOSHARE)),
        ...mapRace(form, coType),
        ...mapEthnicity(form, coType),
      };
    }
    borrowers.push(coBorrower);
  }
  if (formName === FormName.CREDITCARD) {
    const coType = FieldNames.decRelative;
    const relative: Partial<Borrower> | any = {
      birth_date                      : toSqlDate(form[Fields.relativeDOB]),
      first_name                      : safelyTrim(form[Fields.relativeFirstName]),
      last_name                       : safelyTrim(form[Fields.relativeLastName]),
      middle_name                     : safelyTrim(form[Fields.relativeMiddleName]),
      name_suffix                     : form[Fields.relativeSuffixName],
      email                           : form[Fields.relativeEmail],
      phone                           : form[Fields.relativePhone],
      primary_borrower_flag           : toBinaryFlagOrUndefined(false),
      DECLARATION_EXPLANATION         : mapDeclarationExplanation(form, coType),
      RESIDENCES                      : [mapRelativeResidence(form, formName)],
      borrowerType                   : BorrowerType.RELATIVE,
    };
    borrowers.push(relative);
  }
  return borrowers;
};

const mapRelativeResidence = (form, formName: FormName): UniversalResidence => {
  const borrowerResidence: UniversalResidence = mapUniversalBorrowerResidence(form, formName);

  // these fields don't apply for a relative living at same address as borrower
  const residenceFieldsThatDoNotApply = [
    'annual_insurance_amount',
    'annual_tax_amount',
    'association_annual_amount',
    'monthly_amount',
  ];
  residenceFieldsThatDoNotApply.forEach(field => {
    delete borrowerResidence[field];
  });

  const relativeResidence: UniversalResidence = {
    city                     : form[Fields.relativeLivingPropertyCity],
    current_residence_flag   : form[Fields.relativeLivingPropertyStreet] ? toBinaryFlagOrUndefined(YesOrNo.YES) : undefined,
    postal_code              : form[Fields.relativeLivingPropertyZip],
    ownership_type           : LivingSituation.NORENT, // Added this so Residence wouldn't be partial,
    residence_detail_type     : form[Fields.relativeLivingPropertyStreet] ? ResidenceDetailType.CURRENT : undefined,
    state                    : form[Fields.relativeLivingPropertyState],
    street_address_1         : form[Fields.relativeLivingPropertyStreet],
    street_address_2         : form[Fields.relativeLivingPropertyStreet2],
  };
  return form[Fields.relativeSameAddress] === YesOrNo.YES ? borrowerResidence : relativeResidence;
};

const mapUniversalEmployment = (form, formName: FormName): Employment[] => {
  const employment: Employment[] = [];

  if (form[FieldNames.employCompanyName] || (form[FieldNames.commercialBusinessName] && form[FieldNames.employmentType])) {
    employment.push({
      city                   : form[Fields.employCity],
      country                : 'US',
      current_employeer_flag : toBinaryFlagOrUndefined(true),
      employer_name          : form[Fields.employCompanyName],
      employment_type        : form[Fields.employmentType],
      job_start_date         : toSqlDate(form[Fields.employStart]),
      ownership_share        : form[Fields.employOwnershipShare],
      party_to_transaction   : toBinaryFlagOrUndefined(form[Fields.employPartyTransaction]),
      phone                  : form[Fields.employPhone],
      postal_code            : form[Fields.employZip],
      job_title              : form[Fields.employTitle],
      state                  : form[Fields.employState],
      street_address_1       : form[Fields.employStreet],
      street_address_2       : form[Fields.employStreet2],
    });
  }

  if(formName.includes(FormName.COMMERCIAL)) {
    if(form[Fields.commercialEmploySameBusiness] === YesOrNo.YES) {
      // If the applicant says they work for the business requesting the loan, then we never ask the above employ___ questions
      let primaryEmployment = employment.pop();
      primaryEmployment.city             = form[Fields.propertyCity];
      primaryEmployment.employer_name    = form[Fields.commercialBusinessName];
      primaryEmployment.phone            = form[Fields.commercialPhone];
      primaryEmployment.postal_code      = form[Fields.commercialZip];
      primaryEmployment.state            = form[Fields.commercialState];
      primaryEmployment.street_address_1 = form[Fields.commercialStreet];
      primaryEmployment.street_address_2 = form[Fields.commercialStreet2];
      employment.push(primaryEmployment);
    }
  }

  if (form[FieldNames.employPrevName]) {
    employment.push({
      city                   : form[Fields.employPrevCity],
      country                : 'US',
      current_employeer_flag : toBinaryFlagOrUndefined(false),
      employer_name          : form[Fields.employPrevName],
      employment_type        : form[Fields.employmentType],
      job_end_date           : toSqlDate(form[Fields.employPrevEnd]),
      job_start_date         : toSqlDate(form[Fields.employPrevStart]),
      job_title              : form[Fields.employPrevTitle],
      postal_code            : form[Fields.employPrevZip],
      state                  : form[Fields.employPrevState],
      street_address_1       : form[Fields.employPrevStreet],
      street_address_2       : form[Fields.employPrevStreet2],
    });
  }
  return employment;
};

export const mapUniversalCoBorrowerResidence = (form, formName: FormName): UniversalResidence => {
  const borrowerResidence: UniversalResidence = mapUniversalBorrowerResidence(form, formName);

  // these fields don't apply for a co-borrower living at same address as borrower
  const residenceFieldsThatDoNotApply = [
    'annual_insurance_amount',
    'annual_tax_amount',
    'association_annual_amount',
    'monthly_amount',
  ];
  residenceFieldsThatDoNotApply.forEach(field => {
    delete borrowerResidence[field];
  });

  const coBorrowerResidence: UniversalResidence = {
    city                     : form[Fields.coBorrowerLivingPropertyCity],
    current_residence_flag   : form[Fields.coBorrowerLivingPropertyStreet] ? toBinaryFlagOrUndefined(YesOrNo.YES) : undefined,
    monthly_amount           : form[Fields.coBorrowerLivingMonthlyRentAmount],
    postal_code              : form[Fields.coBorrowerLivingPropertyZip],
    ownership_type           : form[Fields.coBorrowerLivingRentOrOwn],
    residence_detail_type    : form[Fields.coBorrowerLivingPropertyStreet] ? ResidenceDetailType.CURRENT : undefined,
    state                    : form[Fields.coBorrowerLivingPropertyState],
    street_address_1         : form[Fields.coBorrowerLivingPropertyStreet],
    street_address_2         : form[Fields.coBorrowerLivingPropertyStreet2],
  };
  return form[Fields.coBorrowerSameAddress] === YesOrNo.YES ? borrowerResidence : coBorrowerResidence;
};

export const mapUniversalSubjectProperty = (form, formName: FormName): SubjectProperty => {
  const result = {
    annual_insurance_amount      : form[Fields.yearInsure],
    annual_tax_amount            : form[Fields.yearTaxes],
    association_annual_amount    : form[Fields.yearAssociate],
    built_year                   : form[Fields.builtYear],
    city                         : form[Fields.propertyCity],
    construction_cost            : form[Fields.constructionCost],
    construction_land_acquired   : form[Fields.constructionLandAcquired],
    construction_land_owner_flag : toBinaryFlagOrUndefined(form[Fields.constructionLandOwner]),
    escro_flag                   : toBinaryFlagOrUndefined(form[Fields.escrowUsage]),
    estimated_amount             : form[Fields.homeValue],
    monthly_gross_rental_income  : form[Fields.propertyMonthlyRentalIncome],
    original_cost                : form[Fields.propertyOriginalCost],
    postal_code                  : form[Fields.propertyZip],
    property_acquired_year       : form[Fields.acquiredYear],
    property_type                : form[Fields.propertyType],
    property_type_desc           : form[Fields.propertyTypeDesc],
    property_use                 : form[Fields.propertyUsage],
    purchase_amount              : form[Fields.homeValue],
    state                        : form[Fields.propertyState],
    street_address_1             : form[Fields.propertyStreet],
    street_address_2             : form[Fields.propertyStreet2],
    unpaid_balance_amount        : form[Fields.mortgageBalance],
  };
  if(formName === FormName.COMMERCIALPURCHASE || formName === FormName.COMMERCIALREFINANCE) {
    result.property_type    = form[Fields.commercialPropertyType];
    result.estimated_amount = formName === FormName.COMMERCIALPURCHASE ? form[Fields.homeValue] : form[Fields.propertyValue];
  }
  return result;
};

const handleDurationInMonths = (value: number): number | undefined => {
  if(value) {
    return moment().diff(moment(value), 'months');
  }
  return undefined;
};

export const handleCommercialMapping = (form: any, formName: FormName): CommercialLoan => {
  const isExistingLoan = form[FieldNames.existingLoanNumber]?.length > 0 || isYes(form[FieldNames.isCommercialExistingCustomer]);
  return {
    bridgeLoan         : mapCommercialBridgeLoan(form, formName),
    business           : mapCommercialBusiness(form),
    employSameBusiness : form[Fields.commercialEmploySameBusiness],
    equipment          : mapCommercialEquipment(form, formName),
    existingLoanId     : form[FieldNames.existingLoanNumber],
    hasOtherManagement : form[Fields.hasOtherManagement],
    loanHolder         : form[Fields.commercialLoanHolder],
    loanRenewalDate    : moment(form[Fields.loanRenewalDate]).format('YYYY-MM-DD'),
    managers           : isExistingLoan ? undefined : mapCommercialManagement(form),
    owners             : isExistingLoan ? undefined : mapCommercialOwners(form),
    purchase           : mapCommercialPurchase(form, formName),
    refinance          : mapCommercialRefinance(form, formName),
    vehicles           : mapCommercialVehicles(form, formName),
  };
};

const mapCommercialBusiness = (form: any): CommercialBusiness => {
  return {
    businessName         : form[Fields.commercialBusinessName],
    dBA                  : form[Fields.commercialDBA],
    censusTract          : form[Fields.commercialCensusTract],
    naicsCode            : form[Fields.commercialNAICSCode],
    city                 : form[Fields.commercialSameBusiness] ? form[Fields.propertyCity]    : form[Fields.commercialCity],
    street               : form[Fields.commercialSameBusiness] ? form[Fields.propertyStreet]  : form[Fields.commercialStreet],
    street2              : form[Fields.commercialSameBusiness] ? form[Fields.propertyStreet2] : form[Fields.commercialStreet2],
    state                : form[Fields.commercialSameBusiness] ? form[Fields.propertyState]   : form[Fields.commercialState],
    zip                  : form[Fields.commercialSameBusiness] ? form[Fields.propertyZip]     : form[Fields.commercialZip],
    phone                : form[Fields.commercialPhone],
    employeeCount        : form[Fields.commercialEmployeeCount],
    website              : form[Fields.commercialWebsite],
    entityType           : form[Fields.commercialEntityType],
    businessType         : form[Fields.commercialBusinessType],
    stateOfIncorporation : form[Fields.commercialStateOfIncorporation],
    yearStarted          : form[Fields.commercialYearStarted],
    revenueTrend3        : form[Fields.commercialRevenueTrend3],
    revenueLastYear      : form[Fields.commercialRevenueLastYear],
    revenueExplanation   : form[Fields.commercialRevenueExplanation],
    profitTrend3         : form[Fields.commercialProfitTrend3],
    profitExplanation    : form[Fields.commercialProfitExplanation],
    collateralList       : form[Fields.commercialCollateralList],
    ownershipType        : form[Fields.commercialBusinessOwnershipType],
  };
};

const mapCommercialManagement = (form: any): CommercialManagement[] => {
  const managementList: CommercialManagement[] = [];
  form[Fields.commercialManagementList].forEach(element => {
    if(element[Fields.managementFirstName]) {
      managementList.push({
        firstName: element[Fields.managementFirstName],
        middleName: element[Fields.managementMiddleName],
        lastName: element[Fields.managementLastName],
        suffixName: element[Fields.managementSuffixName],
        titleHeld: element[Fields.managementTitleHeld],
      });
    }
  });
  return managementList;
};

const mapCommercialOwners = (form: any): CommercialOwner[] => {
  const owners: CommercialOwner[] = [];
  form[Fields.commercialOwnerList].forEach(element => {
    owners.push({
      firstName         : element[Fields.ownerFirstName],
      middleName        : element[Fields.ownerMiddleName],
      lastName          : element[Fields.ownerLastName],
      suffixName        : element[Fields.ownerSuffixName],
      titleHeld         : element[Fields.ownerTitleHeld],
      percentage        : element[Fields.ownerPercentage],
      guarantor         : element[Fields.ownerGuarantor],
      ethnicity         : element[FieldNames.ethnicity].map(value => {
        if (value === Ethnicity.HISPANIC) return EthnicityName.HISPANIC;
        else if(value === Ethnicity.NOHISPANIC) return EthnicityName.NOHISPANIC;
        return EthnicityName.NOSHARE;
      }),
      ethnicityHispanic : element[FieldNames.ethnicityHispanic],
      ethnicityOther    : element[FieldNames.ethnicityOther],
      race              : element[FieldNames.race],
      raceAsian         : element[FieldNames.raceAsian],
      raceAsianOther    : element[FieldNames.raceAsianOther],
      raceIslander      : element[FieldNames.raceIslander],
      raceIslanderOther : element[FieldNames.raceIslanderOther],
      sex        : element[Fields.sex],

    });
  });
  return owners;
};

const mapCommercialBridgeLoan = (form: any, formName: FormName): CommercialBridgeLoan => {
  if(formName === FormName.COMMERCIALBRIDGELOAN) {
    return {
      bridgeOrCredit : form[Fields.commercialBridgeOrCredit],
      loanAmount     : form[Fields.loanAmount],
      loanPurpose    : form[Fields.commercialLoanPurpose],
      loanTiming     : form[Fields.commercialLoanTiming],
    };
  }
  return undefined;
};

const mapCommercialEquipment = (form: any, formName: FormName): CommercialEquipment => {
  if(formName === FormName.COMMERCIALEQUIPMENT) {
    return {
      equipmentOrInventory : form[Fields.commercialEquipmentOrInventory],
      expectedCost         : form[Fields.commercialExpectedCost],
      downDollar           : form[Fields.downDollar],
      description          : form[Fields.commercialDescription],
      businessNeed         : form[Fields.commercialBusinessNeed],
      lifeExpectancy       : form[Fields.commercialLifeExpectancy],
      loanTiming           : form[Fields.commercialLoanTiming],
    };
  }
  return undefined;
};

const mapCommercialPurchase = (form: any, formName: FormName): CommercialPurchase => {
  if(formName === FormName.COMMERCIALPURCHASE) {
    return {
      hasChosenProperty: form[Fields.homeChosenYN],
      hasSignedAgreement: form[Fields.agreementYN],
      downDollar: form[Fields.downDollar],
      expectedClosingDate: form[Fields.expectedClosingDate],
      ...mapCommercialMortgage(form),
    };
  }
  return undefined;
};

const mapCommercialRefinance = (form: any, formName: FormName): CommercialRefinance => {
  if(formName === FormName.COMMERCIALREFINANCE) {
    return {
      lienBalance      : form[Fields.commercialLienBalance],
      loanAmount       : form[Fields.loanAmount],
      refinancePurpose : form[Fields.loanPurpose],
      ...mapCommercialMortgage(form),
    };
  }
  return undefined;
};

const mapCommercialVehicles = (form: any, formName: FormName): CommercialVehicle[] => {
  const result: CommercialVehicle[] = [];
  if(formName === FormName.COMMERCIALVEHICLE) {
    form[Fields.commercialVehicleList].forEach(element =>
      result.push({
        description        : element[Fields.vehicleType],
        year               : element[Fields.vehicleYear],
        make               : element[Fields.vehicleMake],
        model              : element[Fields.vehicleModel],
        count              : element[Fields.vehicleCount],
        newOrUsed          : element[Fields.vehicleNewOrUsed],
        totalPurchasePrice : element[Fields.commercialVehicleTotalPurchasePrice],
        totalDownPayment   : element[Fields.commercialVehicleTotalDownPayment],
        totalTradeInValue  : element[Fields.commercialVehicleTotalTradeInValue],
        loanTermDesired    : element[Fields.loanTermDesired],
      }),
    );
    return result;
  }
  return undefined;
};

const mapCommercialMortgage = (form: any): CommercialMortgage => {
  return {
    landSize                    : form[Fields.landSize],
    numOfStructures             : form[Fields.numOfStructures],
    buildingSquareFootage       : form[Fields.buildingSquareFootage],
    builtYear                   : form[Fields.builtYear],
    expectedOwnerOccupancy      : form[Fields.expectedOwnerOccupancy],
    currentZoningClassification : form[Fields.currentZoningClassification],
    buildingContactName         : form[Fields.buildingContactName],
    buildingContactPhone        : form[Fields.buildingContactPhone],
  };
};

const mapDynamicOwners = (form: any): DynamicOwner[] => {
  const result: DynamicOwner[] = [];
  if(!isNonProfit(form[FieldNames.commercialEntityType])) {
    form[Fields.commercialOwnerList].forEach((element, index) => {
      result.push({
        annualPersonalIncome: element[FieldNames.ownerAnnualPersonalIncome],
        city: element[FieldNames.ownerCity],
        isOwnerTheApplicant: toBinaryFlagOrUndefined(element[FieldNames.ownerIsApplicant]),
        firstName: element[FieldNames.ownerFirstName],
        email: element[FieldNames.ownerEmail],
        lastName: element[FieldNames.ownerLastName],
        middleName: element[FieldNames.ownerMiddleName],
        ownershipPercentage: element[FieldNames.ownerPercentage],
        ownerSince: element[FieldNames.ownerSinceYear],
        personalNetWorth: element[FieldNames.ownerPersonalNetWorth],
        phone: element[FieldNames.ownerPhone],
        ssn: ownerSsnRefs?.[index]?.value ? toNormalizedSsn(ownerSsnRefs[index].value) : undefined,
        state: element[FieldNames.ownerState],
        street: element[FieldNames.ownerStreet],
        street2: element[FieldNames.ownerStreet2],
        suffixName: element[FieldNames.ownerSuffixName],
        zip: element[FieldNames.ownerZip],
      });
    });
  }
  return result;
};

const mapCollateral = (form: any): Collateral[] => {
  const result: Collateral[] = [];
  form[Fields.commercialCollateralList].forEach(element => {
    result.push({
      description : element[FieldNames.commercialCollateralDescription],
      value       : element[FieldNames.commercialCollateralValue],
    });
  });
  return result;
};

const mapSmallBusiness = (form: any): SmallBusiness => {
  return {
    annualInterestExpense       : form[FieldNames.commercialBusinessAnnualInterestExpense],
    businessCity                : form[FieldNames.commercialCity],
    businessDBA                 : form[FieldNames.commercialDBA],
    businessEntityType          : form[FieldNames.commercialEntityType],
    businessIsSameAsMailing     : toBinaryFlagOrUndefined(form[FieldNames.commercialSameMailingAddress]),
    businessMailingCity         : form[FieldNames.commercialMailingCity],
    businessMailingState        : form[FieldNames.commercialMailingState],
    businessMailingStreet       : form[FieldNames.commercialMailingStreet],
    businessMailingStreet2      : form[FieldNames.commercialMailingStreet2],
    businessMailingZip          : form[FieldNames.commercialMailingZip],
    businessName                : form[FieldNames.commercialBusinessName],
    businessPhone               : form[FieldNames.commercialPhone],
    businessState               : form[FieldNames.commercialState],
    businessStateOfOrganization : form[FieldNames.commercialStateOfOrganization],
    businessStreet              : form[FieldNames.commercialStreet],
    businessStreet2             : form[FieldNames.commercialStreet2],
    businessTIN                 : form[FieldNames.commercialTIN],
    businessWebsite             : form[FieldNames.commercialWebsite],
    businessZip                 : form[FieldNames.commercialZip],
    cashAndEquivalents          : form[FieldNames.commercialBusinessCashAndEquivalents],
    checkingAccountBalance      : form[FieldNames.commercialBusinessCheckingAccountBalance],
    collaterals                 : mapCollateral(form),
    ebita                       : form[FieldNames.commercialBusinessEBITA],
    loanAmount                  : form[FieldNames.loanAmount],
    loanTiming                  : form[FieldNames.commercialLoanTiming],
    loanUse                     : form[FieldNames.commercialLoanPurpose],
    netWorth                    : form[FieldNames.commercialBusinessNetWorth],
    owners                      : mapDynamicOwners(form),
    requestedLoanTerm           : form[FieldNames.loanTermDesired],
    totalAssets                 : form[FieldNames.commercialBusinessTotalAssets],
    totalLiabilities            : form[FieldNames.commercialBusinessTotalLiabilities],
    totalSales                  : form[FieldNames.commercialBusinessTotalSales],
  };
};

/**
 * This method maps from the UniversalLoanApplication model back into the backing values that
 * are used on the forms (stored in redux).
 *
 * @param {UniversalLoanApplication} application
 * @param {number} maxMonthlyPayment
 * @returns {Record<string, object>}
 */
export const mapFromUniversalLoanApplication = (application: UniversalLoanApplication, ficoRanges: FicoRange[], maxMonthlyPayment: number): Record<string, object> => {
  const formValues = {};
  const borrower = application.borrower[0] || {};
  const subjectProperty = application.subjectProperty || {};

  const orderedRanges = ficoRanges.sort((rangeA, rangeB) =>
    parseInt(rangeA.value) - parseInt(rangeB.value));
  const range = orderedRanges.find(range => parseInt(range.value) > parseInt(borrower.credit_score));
  formValues[Fields.creditScore] = range ? range.value : orderedRanges[orderedRanges.length-1].value;

  formValues[Fields.firstName] = borrower.first_name;
  formValues[Fields.middleName] = borrower.middle_name;
  formValues[Fields.lastName] = borrower.last_name;
  formValues[Fields.suffixName] = borrower.name_suffix;
  formValues[Fields.email] = borrower.email;
  formValues[Fields.phone] = normalizePhone(borrower.phone);
  formValues[Fields.dateOfBirth] = borrower.birth_date;

  formValues[Fields.propertyStreet] = subjectProperty.street_address_1;
  formValues[Fields.propertyStreet2] = subjectProperty.street_address_2;
  formValues[Fields.propertyCity] = subjectProperty.city;
  formValues[Fields.propertyState] = subjectProperty.state;
  formValues[Fields.propertyZip] = subjectProperty.postal_code;
  formValues[Fields.propertyUsage] = subjectProperty.property_use;
  formValues[Fields.propertyType] = subjectProperty.property_type;
  formValues[Fields.homeValue] = subjectProperty.estimated_amount;
  formValues[Fields.mortgageBalance] = subjectProperty.unpaid_balance_amount;

  formValues[Fields.maxMonthlyPayment] = maxMonthlyPayment;

  return formValues;
};
