import { change, arrayRemoveAll, clearFields, FormAction, unregisterField } from 'redux-form';
import { Dispatch } from 'redux';
import { BorrowerType } from 'app/models/options/enums';
import { nestedValueByString } from 'app/util/misc';
import { FieldNames } from 'app/models/fields/names';
import { DeclarationFieldName } from 'app/models/fields/conditionals';
import { getLongFormDeclarationQuestions } from 'app/models/questions/declarations';
import { logger } from 'app/util/logger';

/**
 * Takes in a question and form values and returns a boolean
 * if the question fails all conditionals.
 *
 * @param {*} question
 * @param {*} values
 * @returns {boolean}
 */
export const failsAllConditions = (question: any, values: any): boolean => {
  return question.showIf.every((condition) => {
    return condition(values) === false;
  });
};

/**
 * Determines if the form value entered is equal to the initialValue
 * set in the form
 *
 * @param {*} initialValue
 * @param {*} formValues
 * @param {string} fieldName
 * @returns {boolean}
 */
export const isEqualToInitialValue = (initialValue: any, formValues: any, fieldName: string): boolean => {
  return initialValue === nestedValueByString(formValues, fieldName);
};

/**
 * Takes in all of the form values determines which values should be reset and how each
 * should be reset depending on the value type.
 *
 * @param {*} initialValue
 * @param {*} formValues
 * @param {*} formName
 * @param {string} fieldName
 */
export const handleFieldReset = (dispatch: Dispatch) => (initialValue, formValues, formName, fieldName: string) => {
  /* Change back to initial value if one is set */
  if (typeof initialValue !== 'undefined') {
    logger.debug(`Field: ${fieldName} is no longer applicable, setting value to ${initialValue}`);
    return dispatch(change(formName, fieldName, initialValue));
  } else {
    /* Handle any field arrays without an initial value */
    if (Array.isArray(nestedValueByString(formValues, fieldName))) {
      logger.debug(`FieldArray: ${fieldName} is no longer applicable, resetting value`);
      return dispatch(arrayRemoveAll(formName, fieldName));
    }
    /* Handle regular fields with no intial values */
    logger.debug(`Field: ${fieldName} is no longer applicable, resetting value`);
    return dispatch(clearFields(formName, false, false, fieldName));
  }
};

/**
 * Unregisters a form field by name
 *
 * @param {string} formName
 * @param {string} fieldName
 * @returns {FormAction}
 */
export const handleManualUnregister = (dispatch: Dispatch) => (formName: string, fieldName: string): FormAction => {
  return dispatch(unregisterField(formName, fieldName));
};

/**
 * Updates fields that may no longer be applicable based on a user
 * navigating backward and then changing their answer.
 *
 * @param {*} values
 * @param {*} dispatch
 * @param {*} props
 * @returns {FormAction | void}
 */
export const updateHiddenFields = (lookupFunction, formName: string) => (values, dispatch: Dispatch, props): void => {
  Object.keys(values).forEach((name) => {
    let question: any;
    if (name === FieldNames.decBorrower || name === FieldNames.decCoBorrower) {
      /* Look up the question by the declaration question handler */
      return updateHiddenDeclarationFields(formName, name)(values, dispatch, props);
    } else {
      /* Look up the question by the passed in lookupFunction (ie: purchaseLFQuestionsByName) */
      question = lookupFunction(name);
    }
    /* If this question has no conditions, take no action */
    if (!question || !question.showIf) {
      return;
    }

    /* If all conditions for this question are failed, and value is not equal to initial value */
    if (failsAllConditions(question, values) && !isEqualToInitialValue(props.initialValues[name], values, name)) {
      return handleFieldReset(dispatch)(props.initialValues[name], values, formName, name);
    }

    return;
  });
};

/**
 * Updates fields that no longer apply due to conditionals for the declarations
 * on both borrower and co-borrower.
 *
 * @param {*} values
 * @param {Dispatch} dispatch
 * @param {*} props
 * @returns {FormAction | void}
 */
export const updateHiddenDeclarationFields = (formName: string, borrower: DeclarationFieldName) => (values, dispatch: Dispatch, props): FormAction | void => {
  const borrowerType = borrower === FieldNames.decBorrower ? BorrowerType.PRIMARY : BorrowerType.COBORROWER;
  /* Return if declaration questions have not been registered */
  if (!hasDeclarationFieldRegistered(props.registeredFields)) {
    return;
  }
  getLongFormDeclarationQuestions(borrowerType).forEach((question) => {
    if (question.showIf && failsAllConditions(question, values)) {
      // @TODO: Refactor once the fieldNames property is taken out of the declaration questions
      /* Handle questions with multiple fieldNames inside one component */
      if (question.fieldNames) {
        return question.fieldNames.forEach((path) => {
          const nestedPath = `${borrower}.${path}`;
          const initialValue = props.initialValues[borrower][path];
          /* Reset the value */
          handleFieldReset(dispatch)(initialValue, values, formName, nestedPath);
          /* Unregister the formField */
          return handleManualUnregister(dispatch)(formName, nestedPath);
        });
      /* If the user has input a value for this field that no longer passes conditionals */
      } else if (typeof nestedValueByString(values, question.name) !== 'undefined') {
        const nestedInitialValue = nestedValueByString(props.initialValues, question.name);
        /* Reset any fields that fail the conditions but have a new value set */
        return handleFieldReset(dispatch)(nestedInitialValue, values, formName, question.name);
      }
    }
  });
};

/**
 * Takes in an object containing registered fields and iterates
 * over the keys returning true if any fields beginning with decBorrower
 * or decCoborrower exist
 *
 * @param {*} [registeredFields={}]
 * @returns {boolean}
 */
export const hasDeclarationFieldRegistered = (registeredFields: any = {}): boolean => {
  return Object.keys(registeredFields).some(
    (name) =>
      name.indexOf(FieldNames.decBorrower) === 0 ||
      name.indexOf(FieldNames.decCoBorrower) === 0,
  );
};
