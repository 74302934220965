import { LiabilityAccount } from 'app/api/credit-report';
import { FieldNames } from 'app/models/fields/names';
import { isPrimaryHome } from 'app/models/fields/conditionals';
import { change, initialize } from 'redux-form';
import { FormName, LiabilityUsage } from 'app/models/options/enums';
import {
  ajaxFetchHomeEquityRates,
  ajaxPostHomeEquityExpressFail,
  ajaxPostHomeEquityExpressSuccess,
  handleDeclarationFlagsWhitelist,
  getNextSection,
  goToSection,
  setSubmittedData,
  referToLoanOfficer,
  ajaxPostShortForm,
  ajaxGetHomeEquityPrefillSuccess,
  ajaxGetHomeEquityPrefillFail,
} from 'app/actions/form/actions';
import {
  FINAL_SUMMARY_SECTION,
  HOME_EQUITY_MINIMUM_LOAN_STOP_GATE,
  HOME_EQUITY_EXPRESS_AUTOMATIC_VOIE,
  SUMMARY_SECTION,
  HOME_EQUITY_EXPRESS_CITIZENSHIP,
  HOME_EQUITY_EXPRESS_EMPLOYMENT_TYPE,
  HOME_EQUITY_EXPRESS_PERSONAL_INFO,
} from '../../../models/sections/constants';
import { Type, Type as FormTypes } from 'app/actions/form/types';
import { RootState } from '../../../store/types';
import { getLoanOfficer } from '../../../reducers/loan-officer';
import { logger } from '../../../util/logger';
import { getVerificationBorrowerCustomerId, getVerificationCoBorrowerCustomerId } from 'app/reducers/verification';
import { getHomeEquityTurboPrefill, mapExpressFormRequest, postHomeEquityExpress } from 'app/api/loan-application/express-form';
import { fetchHomeEquityAcceptedLocation, fetchHomeEquityLoanAmountLimits, mapHomeEquityLoanAmountLimitsRequest } from 'app/api/home-equity';
import { getFormName } from 'app/routes/helpers';
import { HomeEquityProgram, HomeEquityRatesResponse } from 'app/api/home-equity/types';
import { GeneralReferralCode, Product } from '@lenderful/domain';
import { FicoRange, productsSelector } from 'app/reducers/app-config';
import { selectAVMReportId } from 'app/reducers/report';
import { hasEitherSsn } from '../../../components/FormFields/Ssn/CollectSsnBase';
import { getAuditMetadata } from '../../../util/actions';
import { mapFromUniversalLoanApplication } from 'app/api/loan-application/universal/mappings';
import { homeEquityExpressInitialValues } from 'app/util/initial-values';
import { push } from 'connected-react-router';
import { routes } from 'app/routes/route-list';

/**
 * This sets the values for the given Home Equity program into the form.
 *
 * @param {HomeEquityProgram} program
 * @returns
 */
export function setSelectedHomeEquityTurboRate(program: HomeEquityProgram) {
  return (dispatch, getState) => {
    dispatch({ type: Type.SET_SELECTED_RATE, program });
    const formName = getFormName(getState().router.location.pathname);
    const { apr, rate, termInYears, productFamily, productType, principalAndInterestPayment } = program;

    /* Set rate values to home equity turbo form */
    dispatch(change(formName, FieldNames.rate, rate));
    dispatch(change(formName, FieldNames.apr, apr));
    dispatch(change(formName, FieldNames.loanTerm, termInYears));
    dispatch(change(formName, FieldNames.loanType, productFamily));
    dispatch(change(formName, FieldNames.productType, productType));
    dispatch(change(formName, FieldNames.monthlyPayment, principalAndInterestPayment));

    /* Set entire rate row to the selectedRate field */
    dispatch(change(formName, FieldNames.selectedRate, program));
  };
}

/**
 * This method handles getting the rates for the home equity turbo
 * form.  It is responsible for setting the submitted data (Which is used
 * to hold the initial values of the form - important for resetting it).
 *
 * This method also determines whether you get the summary or a referral
 * based on whether there are rates returned or not.
 *
 * @param payload
 * @returns
 */
export function handleExpressHomeEquityGetRates(payload) {
  return async (dispatch) => {
    dispatch({ type: Type.HANDLE_HOMEEQUITY_SUBMIT_SECOND, payload });

    const locationResponse = await fetchHomeEquityAcceptedLocation({ propertyZip: payload[FieldNames.propertyZip] });
    if (locationResponse && !locationResponse.isValid) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.STATE_NOT_SUPPORTED));
    }

    const response: HomeEquityRatesResponse = await Promise.resolve(dispatch(ajaxFetchHomeEquityRates(payload)));
    dispatch(setSubmittedData(FormName.HOME_EQUITY_EXPRESS, payload));

    // Check if the rates returned
    if (response.fixedRatePrograms.length > 0 || response.lineOfCredit) {
      dispatch(ajaxPostShortForm());
      return dispatch(goToSection(SUMMARY_SECTION)); // If true, go to the rate summary
    }
    logger.warn('handleExpressHomeEquityGetRates: No qualified rates returned', response);
    return dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
  };
}

/**
 * This method handles making the final call to get rates for the Home Equity
 * Express form.  This call will have the credit report id to get the real FICO
 * instead of the self-described one.  It also performs some calculations to
 * determine if the user is over the LTV ratio and either caps them to a
 * maximum loan amount or refers them if the loan is too small.
 *
 * @param payload
 * @returns
 */
export function handleExpressHomeEquityGetFinalRates(formData) {
  return async (dispatch) => {

    dispatch({ type: FormTypes.HANDLE_HOMEEQUITY_SUBMIT_SECOND, formData });

    const realEstateLiabilities: LiabilityAccount[] = formData[FieldNames.realEstateLiabilities] || {};

    let collateralHome;
    if (isPrimaryHome(formData)) {
      collateralHome = realEstateLiabilities.find(liability => liability.liabilityUsage === LiabilityUsage.PRIMARY_HOME);
    } else {
      collateralHome = realEstateLiabilities.find(liability => liability.liabilityUsage === LiabilityUsage.COLLATERAL_HOME);
    }

    const liabilityBalance = collateralHome ? collateralHome.liabilityBalance : 0;
    const totalDebt = liabilityBalance + formData[FieldNames.loanAmount];

    const loanAmountLimitsRequest = mapHomeEquityLoanAmountLimitsRequest(formData);
    const { maxLTV, minLoanAmount } = await fetchHomeEquityLoanAmountLimits(loanAmountLimitsRequest);

    if ((totalDebt / formData[FieldNames.homeValue]) > (maxLTV / 100)) {
      // calculate new loan amount which represents the maximum possible with given LTV limit
      const newLoanAmount = (formData[FieldNames.homeValue] * (maxLTV / 100)) - liabilityBalance;

      // update both loan amount and maximum loan amount on form and payload
      dispatch(change(FormName.HOME_EQUITY_EXPRESS, FieldNames.loanAmount, newLoanAmount));
      dispatch(change(FormName.HOME_EQUITY_EXPRESS, FieldNames.maxLoanAmount, newLoanAmount));
      dispatch(change(FormName.HOME_EQUITY_EXPRESS, FieldNames.hasWarningForLoanAmountReduction, 'Yes'));

      formData[FieldNames.loanAmount] = newLoanAmount;
      formData[FieldNames.maxLoanAmount] = newLoanAmount;
      formData[FieldNames.hasWarningForLoanAmountReduction] = 'Yes';
    }

    if (formData[FieldNames.loanAmount] < minLoanAmount) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.HOME_EQUITY_BORROW_AMOUNT_LOW));
    }

    const response: HomeEquityRatesResponse = await Promise.resolve(dispatch(ajaxFetchHomeEquityRates(formData)));
    dispatch(setSubmittedData(FormName.HOME_EQUITY_EXPRESS, formData));

    if (response.fixedRatePrograms.length > 0 || response.lineOfCredit) {
      const rates = [...response.fixedRatePrograms];
      if (response.lineOfCredit?.program) {
        rates.push(response.lineOfCredit.program);
      }
      const newSelectedRate = rates.find(rate => rate.loanType === formData[FieldNames.selectedRate].loanType);
      if (newSelectedRate.apr !== formData[FieldNames.selectedRate].apr) {
        dispatch(change(FormName.HOME_EQUITY_EXPRESS, FieldNames.hasWarningForRateChange, 'Yes'));
        formData[FieldNames.hasWarningForRateChange] = 'Yes';
      }
      return dispatch(goToSection(FINAL_SUMMARY_SECTION));
    }
    logger.warn('handleExpressHomeEquityGetFinalRates: No qualified rates returned', response);
    return dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
  };
}

export function handleValidateLoanLimits(formData) {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);

    const loanAmountLimitsRequest = mapHomeEquityLoanAmountLimitsRequest(formData);
    const { maxLoanAmount, maxLTV, minLoanAmount } = await fetchHomeEquityLoanAmountLimits(loanAmountLimitsRequest);

    // Set the max loan amount in payload because we set submitted data below
    formData[FieldNames.maxLoanAmount] = maxLoanAmount;
    formData[FieldNames.minLoanAmount] = minLoanAmount;
    formData[FieldNames.maxLtvPercent] = maxLTV;
    dispatch(change(formName, FieldNames.maxLoanAmount, maxLoanAmount));
    dispatch(change(formName, FieldNames.minLoanAmount, minLoanAmount));
    dispatch(change(formName, FieldNames.maxLtvPercent, maxLTV));

    if (maxLoanAmount < minLoanAmount) {
      return dispatch(goToSection(HOME_EQUITY_MINIMUM_LOAN_STOP_GATE));
    }
    return dispatch(getNextSection());
  };
}

export function handleHomeEquityExpressFormSubmit(formData) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const loanOfficer = getLoanOfficer(state);
    const borrowerCustomerId = getVerificationBorrowerCustomerId(state);
    const coBorrowerCustomerId = getVerificationCoBorrowerCustomerId(state);
    const avmReportId = selectAVMReportId(state);

    let request = mapExpressFormRequest(formData, loanOfficer, borrowerCustomerId, coBorrowerCustomerId, avmReportId);
    dispatch(handleDeclarationFlagsWhitelist(request, FormName.HOME_EQUITY_EXPRESS));
    dispatch({ type: Type.HANDLE_EXPRESS_SUBMIT, request });
    dispatch({ type: Type.AJAX_POST_HOME_EQUITY_EXPRESS, request });

    if (hasEitherSsn()) {
      const auditMetadata = getAuditMetadata('Submit button');
      request = { ...request,
        element: auditMetadata.element,
        page: auditMetadata.page,
        timestamp: auditMetadata.timestamp,
      };
    }

    return postHomeEquityExpress(request).then(
      (response) => {
        dispatch(ajaxPostHomeEquityExpressSuccess(response));
        dispatch(getNextSection());
      },
      (error) => {
        dispatch(ajaxPostHomeEquityExpressFail(error));
        logger.error('Posting Express form error', error);
      },
    );
  };
}

/**
 * This method navigates to the next section when the user clicks the
 * "Apply Now" button to select a specific rate/program.
 *
 * @returns
 */
export function handleExpressHomeEquityRateSelect() {
  return (dispatch) => {
    dispatch(goToSection(HOME_EQUITY_EXPRESS_CITIZENSHIP));
  };
}

/**
 * This method navigates to either the Verification of Income and Employee workflow (Finicity) or
 * the pages for manual entry of employement information.
 *
 * @returns
 */
export function handleGoToEmployment() {
  return (dispatch, getState) => {
    const state = getState();
    const hasVerificationOfIncomeAndEmployment = productsSelector(state).some(product => product.name === Product.VOIE);

    if (hasVerificationOfIncomeAndEmployment) {
      return dispatch(goToSection(HOME_EQUITY_EXPRESS_AUTOMATIC_VOIE));
    }
    return dispatch(goToSection(HOME_EQUITY_EXPRESS_EMPLOYMENT_TYPE));
  };
}

/**
 * This action retrieves the data from a prefilled home equity turbo application,
 * populates the form with this data, and navigates to the first page of
 * the application for the user to complete it.
 *
 * @param {string} hash
 * @param {FicoRange[]} ficoRanges
 * @returns
 */
export async function ajaxGetHomeEquityTurboPrefill(hash: string, ficoRanges: FicoRange[]) {
  return (dispatch) => {
    dispatch({ type: Type.AJAX_GET_HOME_EQUITY_PREFILL });
    return getHomeEquityTurboPrefill(hash).then(
      (data) => {
        logger.info('ajaxGetHomeEquityTurboPrefill: data', data);
        dispatch(ajaxGetHomeEquityPrefillSuccess(data));
        const prefilledForm = mapFromUniversalLoanApplication(data.application, ficoRanges, data.safeMonthlyPayment);
        const formValues = { ...homeEquityExpressInitialValues, ...prefilledForm };
        dispatch(initialize(FormName.HOME_EQUITY_EXPRESS, formValues, false));
        dispatch(push(`${routes.homeEquityTurbo}#${HOME_EQUITY_EXPRESS_PERSONAL_INFO}`));
      },
      (error) => {
        logger.warn('ajaxGetHomeEquityTurboPrefill: error', error);
        dispatch(ajaxGetHomeEquityPrefillFail(error));
      },
    );
  };
}
