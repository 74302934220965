import React from 'react';
import {
  colors,
  createStyles,
  Typography,
  withStyles,
} from '@material-ui/core';
import { RateInfo } from 'app/models/types';

const styles = (theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    },
    wrapper: {
      background: colors.grey[800],
      position: 'relative',
      padding: '4px 0',
    },
    rate: {
      textAlign: 'center',
      color: colors.common.white,
    },
    iconContainer: {
      position: 'absolute',
      right: '10px',
      top: '0',
    },
    iconButton: {
      width: '31px',
      height: '31px',
      [theme.breakpoints.down('xs')]: {
        width: '28px',
        height: '28px',
      },
    },
    text: {
      padding: '0 35px',
    },
  });

export const RateHeader = withStyles(styles)(({ classes, ...props }: any) => {
  const { selectedRate } = props;

  const mapSelectedRateText = (rateInfo: RateInfo) => {
    return `${rateInfo.productName} ${rateInfo.rate}% Rate`;
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.text}>
          <Typography variant="body2" className={classes.rate}>
            { mapSelectedRateText(selectedRate) }
          </Typography>
        </div>
      </div>
    </div>
  );
});
